import _ from "lodash";

export const getQueryString = (input) => {
    const keys = _.keys(input);
    const filters = _.join(_.map(keys, (key) => {
        const value = input[key];
        return `${key}=${value}`
    }), '&')
    const result = keys.length > 0? `?${filters}`: '';
    return result;
};