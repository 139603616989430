import { lazy } from 'react';
import React from "react";

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Request from 'views/request';


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const ComingSoon = Loadable(lazy(() => import('views/coming-soon')));
const Overview = Loadable(lazy(() => import('views/overview')));
const User = Loadable(lazy(() => import('views/user')));
const Project = Loadable(lazy(() => import('views/project')));
const Event = Loadable(lazy(() => import('views/event')));
const Department = Loadable(lazy(() => import('views/department')));
const Account = Loadable(lazy(() => import('views/account')));
const Problem = Loadable(lazy(() => import('views/problem')));
const Floor = Loadable(lazy(() => import('views/floor')));
const Area = Loadable(lazy(() => import('views/area')));
const Card = Loadable(lazy(() => import('views/card')));
const RequestOnBoard = Loadable(lazy(() => import('views/request-on-board')));
const Answer = Loadable(lazy(() => import('views/answer')));
const Form = Loadable(lazy(() => import('views/form')));
const BmDashboard = Loadable(lazy(() => import('views/bm-dashboard')));
const PmDashboard = Loadable(lazy(() => import('views/pm-dashboard')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Overview />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        // ===================================
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/ananda-data',
            element: <Answer title={'Ananda Data'} formType={'ANANDA'} />
        },
        {
            path: '/answer',
            element: <Answer />
        },
        {
            path: '/area',
            element: <Area />
        },
        {
            path: '/bm-dashboard',
            element: <BmDashboard />
        },
        {
            path: '/bm-data',
            element: <Answer title={'BM Data'} formType={'BM'} />
        },
        {
            path: '/card',
            element: <Card />
        },
        {
            path: '/coming-soon',
            element: <ComingSoon />
        },
        {
            path: '/department',
            element: <Department />
        },
        {
            path: '/event',
            element: <Event />
        },
        {
            path: '/floor',
            element: <Floor />
        },
        {
            path: '/form',
            element: <Form />
        },
        {
            path: '/overview',
            element: <Overview />
        },
          {
            path: '/pm-dashboard',
            element: <PmDashboard />
        },
        {
            path: '/pm-data',
            element: <Answer title={'PM Data'} formType={'PM'} />
        },
        
        {
            path: '/problem',
            element: <Problem />
        },
        {
            path: '/project',
            element: <Project />
        },
        {
            path: '/request',
            element: <Request />
        },
        {
            path: '/request-on-board',
            element: <RequestOnBoard />
        },
        {
            path: '/user',
            element: <User />
        },

    ]
};

export default MainRoutes;
