import { createStore, applyMiddleware } from 'redux';
import reducer from './reducer';
import logger from 'redux-logger';
import thunk from "redux-thunk";
import config from '../config';
// ==============================|| REDUX - MAIN STORE ||============================== //

let middle = applyMiddleware(thunk, logger);
if (!config[config.enviroment].enabledLogs) {
    console.log = function () { };
    middle = applyMiddleware(thunk);
}

const store = createStore(reducer, middle);
const persister = 'Free';

export { store, persister };
