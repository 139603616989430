// action - app reducer
export const SET_ERROR = 'app/error';
export const SET_LOADING = 'app/loading';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_SUCCESS = 'app/success';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - account reducer
export const GET_ACCOUNTS = '/account';
export const GET_LAST_ACCOUNT = '/account/last';
export const GET_ACCOUNT = '/account/:accountId';
export const GET_ACCOUNT_BY_ACCOUNT_CODE = '/account/code/:accountCode';
export const GET_ACCOUNT_BY_USERNAME = '/account/username/:username';
export const GET_ACCOUNT_BY_KEYWORD = '/account/keyword/:keyword';
export const CREATE_ACCOUNT = '/account/create';
export const UPDATE_ACCOUNT = '/account/update';
export const DELETE_ACCOUNT = '/account/delete';
export const DELETE_ACCOUNTS = '/account/deletes';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';

// action - answer reducer
export const GET_ANSWERS = '/answer';
export const GET_LAST_ANSWER = '/answer/last';
export const GET_PROJECT_NAMES = '/answer/project-names';
export const GET_TOPICS = '/answer/topics';
export const GET_RESULTS = '/answer/results';
export const GET_FORM_NAMES = '/answer/form-names';
export const GET_ANSWER = '/answer/:answerId';
export const GET_ANSWER_BY_ANSWER_CODE = '/answer/code/:answerCode';
export const GET_ANSWER_BY_KEYWORD = '/answer/keyword/:keyword';
export const CREATE_ANSWER = '/answer/create';
export const UPLOAD_ANSWERS = '/answer/uploads';
export const CREATE_ANSWERS = '/answer/creates';
export const UPDATE_ANSWER = '/answer/update';
export const DELETE_ANSWER = '/answer/delete';
export const DELETE_ANSWERS = '/answer/deletes';
export const GET_ANSWER_ANALYTICS = '/answer/analytics';
export const GET_BM_ANALYTICS = '/answer/bm-analytics';
export const GET_PM_ANALYTICS = '/answer/pm-analytics';
export const SET_ANSWERS = 'SET_ANSWERS';

// action - area reducer
export const GET_AREAS = '/area';
export const GET_LAST_AREA = '/area/last';
export const GET_AREA = '/area/:areaId';
export const GET_AREA_BY_AREA_CODE = '/area/code/:areaCode';
export const GET_AREA_BY_KEYWORD = '/area/keyword/:keyword';
export const CREATE_AREA = '/area/create';
export const UPDATE_AREA = '/area/update';
export const DELETE_AREA = '/area/delete';
export const DELETE_AREAS = '/area/deletes';
export const SET_AREAS = 'SET_AREAS';

// action - card reducer
export const GET_CARDS = '/card';
export const GET_LAST_CARD = '/card/last';
export const GET_CARD = '/card/:cardId';
export const GET_CARD_BY_CARD_CODE = '/card/code/:cardCode';
export const GET_CARD_BY_KEYWORD = '/card/keyword/:keyword';
export const CREATE_CARD = '/card/create';
export const CREATE_CARDS = '/card/create-items';
export const UPDATE_CARD = '/card/update';
export const DELETE_CARD = '/card/delete';
export const DELETE_CARDS = '/card/deletes';
export const SET_CARDS = 'SET_CARDS';
export const SET_CARD_STATUS = 'SET_CARD_STATUS';
export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASKS = 'CREATE_TASKS';
export const UPDATE_TASK = 'UPDATE_TASK';
export const DELETE_TASK = 'DELETE_TASK';

// action - department reducer
export const GET_DEPARTMENTS = '/dept';
export const GET_LAST_DEPARTMENT = '/dept/last';
export const GET_DEPARTMENT = '/dept/:deptId';
export const GET_DEPARTMENT_BY_DEPARTMENT_CODE = '/dept/code/:deptCode';
export const GET_DEPARTMENT_BY_KEYWORD = '/dept/keyword/:keyword';
export const CREATE_DEPARTMENT = '/dept/create';
export const UPDATE_DEPARTMENT = '/dept/update';
export const DELETE_DEPARTMENT = '/dept/delete';
export const DELETE_DEPARTMENTS = '/dept/deletes';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';

// action - event reducer
export const GET_EVENTS = '/event';
export const GET_LAST_EVENT = '/event/last';
export const GET_EVENT = '/event/:eventId';
export const GET_EVENT_BY_EVENT_CODE = '/event/code/:eventCode';
export const GET_EVENT_BY_KEYWORD = '/event/keyword/:keyword';
export const CREATE_EVENT = '/event/create';
export const UPDATE_EVENT = '/event/update';
export const DELETE_EVENT = '/event/delete';
export const DELETE_EVENTS = '/event/deletes';
export const DELETE_BY_DATE_RANGE_EVENT = '/event/delete-by-date-range';
export const SET_EVENTS = 'SET_EVENTS';

// action - floor reducer
export const GET_FLOORS = '/floor';
export const GET_LAST_FLOOR = '/floor/last';
export const GET_FLOOR = '/floor/:floorId';
export const GET_FLOOR_BY_FLOOR_CODE = '/floor/code/:floorCode';
export const GET_FLOOR_BY_KEYWORD = '/floor/keyword/:keyword';
export const CREATE_FLOOR = '/floor/create';
export const UPDATE_FLOOR = '/floor/update';
export const DELETE_FLOOR = '/floor/delete';
export const DELETE_FLOORS = '/floor/deletes';
export const SET_FLOORS = 'SET_FLOORS';

// action - form reducer
export const GET_FORMS = '/form';
export const GET_LAST_FORM = '/form/last';
export const GET_FORM = '/form/:formId';
export const GET_FORM_BY_FORM_CODE = '/form/code/:formCode';
export const GET_FORM_BY_KEYWORD = '/form/keyword/:keyword';
export const CREATE_FORM = '/form/create';
export const UPDATE_FORM = '/form/update';
export const DELETE_FORM = '/form/delete';
export const DELETE_FORMS = '/form/deletes';
export const SET_FORMS = 'SET_FORMS';

// action - request reducer
export const GET_REQUESTS = '/request';
export const GET_LAST_REQUEST = '/request/last';
export const GET_REQUEST = '/request/:requestId';
export const GET_REQUEST_BY_REQUEST_CODE = '/request/code/:requestCode';
export const GET_REQUEST_BY_KEYWORD = '/request/keyword/:keyword';
export const CREATE_REQUEST = '/request/create';
export const UPDATE_REQUEST = '/request/update';
export const REORDER_REQUEST = '/request/reorder';
export const APPROVE_REQUEST = '/request/approve/:requestId';
export const DELETE_REQUEST = '/request/delete';
export const DELETE_REQUESTS = '/request/deletes';
export const SET_REQUESTS = 'SET_REQUESTS';
export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';
export const CREATE_REQ = 'CREATE_REQUEST';
export const UPDATE_REQ = 'UPDATE_REQUEST';
export const UPDATE_REQUEST_NULL = 'UPDATE_REQUEST_NULL';
export const UPDATE_REQ_ORDER = 'UPDATE_REQUEST_ORDER';
export const UPDATE_REQ_ORDER_END = 'UPDATE_REQUEST_ORDER_END';
export const DELETE_REQ = 'DELETE_REQUEST';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SET_ORDERED = 'SET_ORDERED';

// action - problem reducer
export const GET_PROBLEMS = '/problem';
export const GET_LAST_PROBLEM = '/problem/last';
export const GET_PROBLEM = '/problem/:problemId';
export const GET_PROBLEM_BY_PROBLEM_CODE = '/problem/code/:problemCode';
export const GET_PROBLEM_BY_KEYWORD = '/problem/keyword/:keyword';
export const CREATE_PROBLEM = '/problem/create';
export const UPDATE_PROBLEM = '/problem/update';
export const DELETE_PROBLEM = '/problem/delete';
export const DELETE_PROBLEMS = '/problem/deletes';
export const SET_PROBLEMS = 'SET_PROBLEMS';

// action - project reducer
export const GET_PROJECTS = '/project';
export const GET_LAST_PROJECT = '/project/last';
export const GET_PROJECT = '/project/:projectId';
export const GET_PROJECT_BY_PROJECT_CODE = '/project/code/:projectCode';
export const GET_PROJECT_BY_KEYWORD = '/project/keyword/:keyword';
export const CREATE_PROJECT = '/project/create';
export const UPDATE_PROJECT = '/project/update';
export const DELETE_PROJECT = '/project/delete';
export const DELETE_PROJECTS = '/project/deletes';
export const SET_PROJECTS = 'SET_PROJECTS';

// action - user reducer
export const GET_USERS = '/user';
export const GET_LAST_USER = '/user/last';
export const GET_USER = '/user/:userId';
export const GET_USER_BY_USERNAME = '/user/username/:username';
export const GET_USER_BY_KEYWORD = '/user/keyword/:keyword';
export const CREATE_USER = '/user/create';
export const FORGOT_PASSWORD = '/user/forgot-password';
export const RESET_PASSWORD = '/user/reset-password';
export const CHANGE_PASSWORD = '/user/change-password';
export const UPDATE_USER = '/user/update';
export const DELETE_USER = '/user/delete';
export const DELETE_USERS = '/user/deletes';
export const AUTHEN_USER = '/user/authen';
export const LOGIN_USER = '/user/login';
export const LOGOUT_USER = '/user/logout';
export const REGISTER_USER = '/user/register';
export const REFRESH_TOKEN = '/user/refresh';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_USERS = 'SET_USERS';
