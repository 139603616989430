// action - state management
import axios from 'axios';
import _ from 'lodash';
import config from '../../config';
import * as actionTypes from '../actions';
const queryString = require('query-string');
// ==============================|| STATE ||============================== //

export const initialState = {
    cards: [],
    cardTypes: [
        { label: 'Hourly', TH: "รายชั่วโมง", value: "HOURLY", defaultValue: 1, type: "hours" },
        { label: 'Daily', TH: "รายวัน", value: "DAILY", defaultValue: 1, type: "days" },
        { label: 'Weekly', TH: "รายสัปดาห์", value: "WEEKLY", defaultValue: 1, type: "weeks" },
        { label: 'Monthly', TH: "รายเดือน", value: "MONTHLY", defaultValue: 1, type: "months" },
        { label: 'Yearly', TH: "รายปี", value: "YEARLY", defaultValue: 1, type: "years" },
    ],
    cardStatusList: [
        // { label: 'Todo', TH: "จะทำ", value: "TODO", hoverColor: "rgb(158 158 158 / 68%)", lightColor: "rgb(158 158 158 / 40%)", darkColor: "#e0e0e0" },
        { label: 'Todo', TH: "กำหนดทำ", value: "TODO", hoverColor: "rgb(3 169 244 / 69%)", lightColor: "rgb(3 169 244 / 40%)", darkColor: "#03a9f4" },
        { label: 'Doing', TH: "กำลังดำเนินการอยู่", value: "DOING", disabled: true, hoverColor: "rgb(3 169 244 / 69%)", lightColor: "rgb(3 169 244 / 40%)", darkColor: "#03a9f4" },
        { label: 'Overdue', TH: "เลยกำหนด", value: "OVERDUE", disabled: true, hoverColor: "rgb(255 229 127 / 74%)", lightColor: "rgb(255 229 127 / 40%)", darkColor: "#ffe57f" },
        { label: 'Done', TH: "ตรวจสอบแล้ว", value: "DONE", isForWorker: true, hoverColor: "rgb(0 230 118 / 72%)", lightColor: "rgb(0 230 118 / 40%)", darkColor: "#00e676" },
        // { label: 'Resolved', TH: "แก้ไขแล้ว", value: "RESOLVED", disabled: true, hoverColor: "rgb(3 141 74 / 75%)", lightColor: "rgb(3 141 74 / 50%)", darkColor: "#038d4a" },
        { label: 'Defect', TH: "พบปัญหา", value: "DEFECT", isForWorker: true, hoverColor: "rgb(244 67 54 / 75%)", lightColor: "rgb(244 67 54 / 40%)", darkColor: "#f44336" },
        // { label: 'Rejected', TH: "ยกเลิก", value: "REJECTED", disabled: true, hoverColor: "rgb(121 85 72 / 76%)", lightColor: "rgb(121 85 72 / 42%)", darkColor: "#795548" },
    ],
    status: null,
};


// ==============================|| ACTIONS ||============================== //

export const getItems = (input, callback) => {
    return (dispatch) => {
        let url = queryString.stringifyUrl({ url: `${config[config.enviroment].server_endpoint}${actionTypes.GET_CARDS}`, query: input });
        return axios({
            method: "get",
            // url: `${config[config.enviroment].server_endpoint}${actionTypes.GET_CARDS}`,
            url,
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_CARDS,
                payload: result.data
            });
            if (callback) callback(result.data);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const getLastItems = (callback) => {
    return (dispatch) => {
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_LAST_CARD}`).then(response => {
            let result = response.data;
            let item = result.data?.[0];
            let primaryCode = "cardCode";
            let replaceStr = "TASK";
            let lastCode = `${replaceStr}0`;
            if (item) {
                let count = Number.isNaN(parseInt(item[primaryCode]?.replace(replaceStr, ""))) ? 0 : parseInt(item[primaryCode]?.replace(replaceStr, "")) + 1;
                lastCode = `${replaceStr}${count}`
            }
            if (callback) callback(item, lastCode);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    };
}
export const createItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                if (key === "files") {
                    let files = input[key];
                    files.forEach((file, index) => {
                        bodyFormData.append(`${'file'}_${index}`, file);
                    });
                } else {
                    bodyFormData.append(key, input[key]);
                }
            }
        });
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.CREATE_CARD}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const createItems = (input, callback) => {
    return (dispatch) => {
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.CREATE_CARDS}`,
            data: input,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const updateItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                if (key === "files") {
                    let files = input[key];
                    files.forEach((file, index) => {
                        bodyFormData.append(`${'file'}_${index}`, file);
                    });
                } else {
                    bodyFormData.append(key, input[key]);
                }
            }
        });
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.UPDATE_CARD}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "delete",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_CARD}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItems = (input, callback) => {
    return (dispatch) => {
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_CARDS}`,
            data: input,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
// ==============================|| REDUCER ||============================== //

const cardReducer = (state = initialState, action) => {
    let { payload } = action;
    let oldState, item, items, isExist, index, newItems;
    switch (action.type) {
        case actionTypes.SET_CARD_STATUS:
            return {
                ...state,
                status: payload,
            };
        case actionTypes.SET_CARDS:
            return {
                ...state,
                cards: payload,
            };
        case actionTypes.CREATE_TASK:
            oldState = _.extend({}, state);
            item = payload;
            isExist = oldState?.cards?.find((i) => { return i.cardId === item.cardId });
            if (isExist) return state
            newItems = _.clone(oldState?.cards);
            newItems.unshift(item)
            return {
                ...state,
                cards: newItems,
                status: 'UPDATE_TASK',
            };
        case actionTypes.CREATE_TASKS:
            oldState = _.extend({}, state);
            items = payload;

            newItems = _.clone(oldState?.cards);
            items?.forEach((newCard) => {
                isExist = oldState?.cards?.find((i) => { return i.cardId === newCard.cardId });
                if (!isExist) {
                    newItems.unshift(newCard);
                }
            });
            return {
                ...state,
                cards: newItems,
                status: 'UPDATE_TASK',
            };
        case actionTypes.UPDATE_TASK:
            oldState = _.extend({}, state);
            item = payload;
            isExist = oldState?.cards?.find((i) => { return i.cardId === item.cardId });
            if (!isExist) return state
            index = _.findIndex(oldState?.cards, (i) => { return i.cardId === item.cardId });
            newItems = _.clone(oldState?.cards);
            newItems.splice(index, 1);
            newItems.unshift(item)
            return {
                ...state,
                cards: newItems,
                status: 'UPDATE_TASK',
            };
        case actionTypes.DELETE_TASK:
            oldState = _.extend({}, state);
            item = payload;
            isExist = oldState?.cards?.find((i) => { return i.cardId === item.cardId });
            if (!isExist) return state
            index = _.findIndex(oldState?.cards, (i) => { return i.cardId === item.cardId });
            newItems = _.clone(oldState?.cards);
            newItems.splice(index, 1);
            return {
                ...state,
                cards: newItems,
                status: 'UPDATE_TASK',
            };
        default:
            return state;
    }
};

export default cardReducer;
