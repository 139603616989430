// material-ui
import { Link, Typography, Stack } from '@mui/material';
import CONFIG from '../../config'
// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="space-between" >
        {/* <Typography variant="subtitle2" component={Link} href={CONFIG.appLink} target="_blank" underline="hover" style={{color: '#707070'}}>
            {CONFIG.appName}
        </Typography> */}
        <div></div>
        <Typography variant="subtitle2" component={Link} href={CONFIG.appLink} target="_blank" underline="hover" style={{color: '#707070', textAlign: 'center'}}>
            Copyright &copy; {CONFIG.releasedYear} {CONFIG.companyName}
        </Typography>
    </Stack>
);

export default AuthFooter;
