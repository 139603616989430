// material-ui
import { useTheme } from '@mui/material/styles';
import DEFAULT_LOGO from '../assets/images/default/default_logo2.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = (props) => {
    const theme = useTheme();
    const { width, height, src, padding } = props;
    return <div>
        <img src={src? src: DEFAULT_LOGO} style={{ height: height? height: '70px', display: 'inline-block', verticalAlign: 'middle', padding: padding || '0px 20px' }} />
        {/* <h1 style={{ color: 'black', display: 'inline-block', verticalAlign: 'middle', fontFamily: "'Sukhumvit'" }}>WorksList</h1> */}
    </div>

};

export default Logo;
