import { lazy, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Route, Navigate, useLocation } from 'react-router';

// project imports
import Customization from '../Customization';
import { isLoggedIn } from 'store/reducers/userReducer';
import { useDispatch } from 'react-redux';
import Loadable from 'ui-component/Loadable';
const ScanQR = Loadable(lazy(() => import('views/pages/authentication/authentication3/ScanQR')));
const Email = Loadable(lazy(() => import('views/pages/authentication/authentication3/Email')));
const CheckList = Loadable(lazy(() => import('views/pages/authentication/authentication3/CheckList')));
const queryString = require('query-string');
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    let data = location?.pathname?.split('/');
    const [isAuth, setIsAuth] = useState(false);
    const [isAlready, setIsAlready] = useState(false);

    const parsed = queryString.parse(location?.search) || {};


    useEffect(() => {
        console.log('MinimalLayout', isAuth)
        if (!isAuth) {
            dispatch(isLoggedIn(false, (res) => {
                setIsAuth(res);
                setIsAlready(true);
            }));
        }
    }, []);

    const isScanQR = (data) => {
        if (data?.[1] !== "scanqr") return false
        if (!data?.[2]) return false
        return true
    }
    const isEmail = (data) => {
        if (data?.[1] !== "email") return false
        return true
    }
    const isCheckList = (data) => {
        if (data?.[1] !== "check-list") return false
        return true
    }

    return (
        <>
            {
                isScanQR(data) ? <ScanQR scanqrData={data} /> :
                    isEmail(data) ? <Email emailData={parsed} /> :
                    isCheckList(data) ? <CheckList scanqrData={parsed} /> :
                        // !dispatch(isLoggedIn()) ?
                        //     <Outlet /> :
                        //     <Navigate to="/overview" state={{ from: location }} />
                        <>
                            {isAlready && <>
                                {!isAuth && <Outlet />}
                                {isAuth && <Navigate to="/overview" state={{ from: location }} />}
                            </>}
                            {!isAlready && <Outlet />}
                        </>
            }
            <Customization />
        </>
    );
}

export default MinimalLayout;
