// action - state management
import axios from 'axios';
import config from '../../config';
import * as actionTypes from '../actions';
import { getQueryString } from 'utils/query-string';
// ==============================|| STATE ||============================== //

export const initialState = {
    project: null,
    projects: [],
};

// ==============================|| ACTIONS ||============================== //

export const getItems = (input, callback) => {
    return (dispatch) => {
        const queryString = getQueryString(input);
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_PROJECTS}${queryString}`).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_PROJECTS,
                payload: result.data
            });
            if(callback) callback(result.data)
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    };
}
export const getItemByCode = (code, callback) => {
    return (dispatch) => {
        return axios.get((`${config[config.enviroment].server_endpoint}${actionTypes.GET_PROJECT_BY_PROJECT_CODE}`).replace(':projectCode', code)).then(response => {
            let result = response.data;
            if(callback) callback(result.data)
        }).catch((error) => {
            if(callback) callback(null, error);
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    }
}
export const getLastItems = (callback) => {
    return (dispatch) => {
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_LAST_PROJECT}`).then(response => {
            let result = response.data;
            let item = result.data?.[0];
            let primaryCode = "projectCode";
            let replaceStr = "PROJECT";
            let lastCode = `${replaceStr}0`;
            if(item){
                let count = Number.isNaN(parseInt(item[primaryCode]?.replace(replaceStr,"")))? 0: parseInt(item[primaryCode]?.replace(replaceStr,""))+1;
                lastCode = `${replaceStr}${count}`
            }
            if (callback) callback(item, lastCode);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    };
}
export const createItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if(input[key] == null || input[key]==undefined || input[key]==""){

            }else{
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.CREATE_PROJECT}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const updateItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if(input[key] == null || input[key]==undefined || input[key]==""){

            }else{
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.UPDATE_PROJECT}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if(input[key] == null || input[key]==undefined || input[key]==""){

            }else{
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "delete",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_PROJECT}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItems = (input, callback) => {
    return (dispatch) => {
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_PROJECTS}`,
            data: input,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
// ==============================|| REDUCER ||============================== //

const projectReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {
        case actionTypes.SET_PROJECTS:
            return {
                ...state,
                projects: payload,
            };
        default:
            return state;
    }
};

export default projectReducer;
