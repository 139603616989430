import { combineReducers } from 'redux';
import accountReducer from './reducers/accountReducer';

// reducer import
import appReducer from './reducers/appReducer';
import areaReducer from './reducers/areaReducer';
import cardReducer from './reducers/cardReducer';
import customizationReducer from './reducers/customizationReducer';
import deptReducer from './reducers/deptReducer';
import eventReducer from './reducers/eventReducer';
import floorReducer from './reducers/floorReducer';
import formReducer from './reducers/formReducer';
import problemReducer from './reducers/problemReducer';
import projectReducer from './reducers/projectReducer';
import requestReducer from './reducers/requestReducer';
import userReducer from './reducers/userReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    app: appReducer,
    account: accountReducer,
    area: areaReducer,
    card: cardReducer,
    customization: customizationReducer,
    dept: deptReducer,
    event: eventReducer,
    floor: floorReducer,
    form: formReducer,
    project: projectReducer,
    problem: problemReducer,
    request: requestReducer,
    user: userReducer,
});

export default reducer;
