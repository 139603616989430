// action - state management
import axios from 'axios';
import config from '../../config';
import * as actionTypes from '../actions';
// ==============================|| STATE ||============================== //

export const initialState = {
    files: [],
};

// ==============================|| ACTIONS ||============================== //

export const getDownloadUrl = (path) => {
    return `${config[config.enviroment].server_endpoint.replace('/api/v2', '/download')}/${path}`
}

// ==============================|| REDUCER ||============================== //

const fileReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {
        case actionTypes.SET_PROJECTS:
            return {
                ...state,
                projects: payload,
            };
        default:
            return state;
    }
};

export default fileReducer;
