// action - state management
import { colors } from '@atlaskit/theme';
import axios from 'axios';
import _ from 'lodash';
import config from '../../config';
import * as actionTypes from '../actions';
import { getQueryString } from 'utils/query-string';

// ==============================|| STATE ||============================== //

export const mockData = {
}
export const initialState = {
    requests: [],
    updateItem: null,
    requestStatusList: [
        {
            name: 'Open',
            TH: "",
            status: "OPEN",
            value: "OPEN",
            isCol: true,
            colors: {
                soft: colors.Y50,
                hard: colors.Y200
            },
            cols: ['TODO', 'DOING', 'DONE', 'CANCEL'],
            userRole: ['ADMIN', 'MANAGER', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") {
                    // dont delete
                }
                if (action === "REJECT") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            comment,
                            updatedBy,
                            col: "CANCEL",
                            status: 'REJECTED',
                        }
                    })
                }
                if (action === "SAVE") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            col: "OPEN",
                            status: "OPEN",
                            departmentInCharge,
                            personsInCharge,
                            comment,
                            updatedBy,
                        }
                    })
                }
                if (action === "SUBMIT") {
                    // ระบุ DIC, PIC หรือไม่ และใส่ password มาหรือเปล่า?
                    if (departmentInCharge &&
                        personsInCharge && personsInCharge !== "[]" &&
                        password && password !== "") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                requestId,
                                col: "OPEN",
                                status: "WAITING",
                                departmentInCharge,
                                personsInCharge,
                                comment,
                                updatedBy,
                            }
                        })
                    }
                }
                if (action === "MOVE") {
                    // dont move
                }
                if (action === "BACK") {
                    // dont move
                }
                return res;
            },
        },
        {
            name: 'Waiting',
            TH: "",
            status: "OPEN",
            value: "WAITING",
            colors: {
                soft: colors.Y50,
                hard: colors.Y500
            },
            cols: ['TODO', 'DOING', 'DONE', 'CANCEL'],
            userRole: ['ADMIN', 'MANAGER', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") {
                    // dont delete
                }
                if (action === "REJECT") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            comment,
                            updatedBy,
                            col: "CANCEL",
                            status: 'REJECTED',
                        }
                    })
                }
                if (action === "SAVE") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            col: "OPEN",
                            status: "WAITING",
                            departmentInCharge,
                            personsInCharge,
                            comment,
                            updatedBy,
                        }
                    })
                }
                if (action === "SUBMIT") {
                    // มี PIC หรือไม่ และใส่ password หรือเปล่า?
                    if (personsInCharge && password && password !== "") {
                        let personsInChargeList = JSON.parse(personsInCharge)?.filter((person) => { return !person.isCC });
                        let personNotApproved = personsInChargeList.find((person) => { return !person.isApproved });
                        // approve ทั้งหมดหรือยัง?
                        if (!personNotApproved) {
                            res = _.extend({}, res, {
                                isValid: true,
                                input: {
                                    requestId,
                                    col: "TODO", // old: OPEN
                                    status: "TODO", // old: APPROVED
                                    departmentInCharge,
                                    personsInCharge,
                                    comment,
                                    updatedBy,
                                }
                            })
                            // ยัง approve ไม่หมด และยังคงแก้ไข PIC ได้
                        } else {
                            res = _.extend({}, res, {
                                isValid: true,
                                input: {
                                    requestId,
                                    col: "OPEN",
                                    status: "WAITING",
                                    departmentInCharge,
                                    personsInCharge,
                                    comment,
                                    updatedBy,
                                }
                            })
                        }
                    }
                }
                if (action === "MOVE") {
                    // dont move
                }
                if (action === "BACK") {
                    // dont move
                }
                return res;
            },
        },
        {
            name: 'Approved',
            TH: "",
            status: "OPEN",
            value: "APPROVED",
            colors: {
                soft: colors.Y50,
                hard: colors.Y500
            },
            cols: ['DOING', 'DONE', 'CANCEL'],
            userRole: ['ADMIN', 'MANAGER', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") {
                    // dont delete
                }
                if (action === "REJECT") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            comment,
                            updatedBy,
                            col: "CANCEL",
                            status: 'REJECTED',
                        }
                    })
                }
                if (action === "SAVE") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            col: "OPEN",
                            status: "APPROVED",
                            departmentInCharge,
                            personsInCharge,
                            comment,
                            updatedBy,
                        }
                    })
                }
                if (action === "SUBMIT") {
                    // มี PIC หรือไม่ และใส่ password หรือเปล่า?
                    if (personsInCharge && password && password !== "") {
                        let personsInChargeList = JSON.parse(personsInCharge)?.filter((person) => { return !person.isCC });
                        let personNotApproved = personsInChargeList.find((person) => { return !person.isApproved });
                        // approve ทั้งหมดหรือยัง?
                        if (!personNotApproved) {
                            res = _.extend({}, res, {
                                isValid: true,
                                input: {
                                    requestId,
                                    col: "TODO", // old: OPEN
                                    status: "TODO", // old: APPROVED
                                    departmentInCharge,
                                    personsInCharge,
                                    comment,
                                    updatedBy,
                                }
                            })
                        }
                    }
                }
                if (action === "MOVE") {
                    if (board?.destination?.droppableId?.toUpperCase() === "TODO") {
                        if (personsInCharge) {
                            let personsInChargeList = JSON.parse(personsInCharge)?.filter((person) => { return !person.isCC });
                            let personNotApproved = personsInChargeList.find((person) => { return !person.isApproved });
                            // approve ทั้งหมดหรือยัง?
                            if (!personNotApproved) {
                                res = _.extend({}, res, {
                                    isValid: true,
                                    input: {
                                        requestId,
                                        col: "TODO", // ย้ายไปไว้ที่ col:TODO
                                        status: "TODO",
                                        departmentInCharge,
                                        personsInCharge,
                                        comment,
                                        updatedBy,
                                    }
                                })
                            }
                        }
                    }
                }
                if (action === "BACK") {
                    // dont move
                }
                return res;
            },

        },
        {
            name: 'Todo',
            TH: "",
            status: "TODO",
            value: "TODO",
            isCol: true,
            colors: {
                soft: colors.B50,
                hard: colors.B200
            },
            cols: ['DOING', 'DONE', 'CANCEL'],
            userRole: ['ADMIN', 'MANAGER', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") {
                    // dont delete
                }
                if (action === "REJECT") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            comment,
                            updatedBy,
                            col: "CANCEL",
                            status: 'REJECTED',
                        }
                    })
                }
                if (action === "SAVE") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            col: "TODO",
                            status: "TODO",
                            planning,
                            comment,
                            updatedBy,
                        }
                    })
                }
                if (action === "SUBMIT") {
                    // มี plan หรือไม่ และใส่ password หรือเปล่า?
                    if (planning && planning !== "[]") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                requestId,
                                col: 'TODO',
                                status: 'PLANNING',
                                planning,
                                comment,
                                updatedBy,
                            }
                        })
                    }
                }
                if (action === "MOVE") {
                    if (board?.destination?.droppableId?.toUpperCase() === "OPEN") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                isBack: true,
                                requestId,
                                col: "OPEN",
                                status: "OPEN",
                                departmentInCharge: "",
                                personsInCharge: "[]",
                                planning: "[]",
                                comment,
                                updatedBy,
                            }
                        })
                    }
                }
                if (action === "BACK") {

                }
                return res;
            },
        },
        {
            name: 'Planning',
            TH: "",
            status: "TODO",
            value: "PLANNING",
            colors: {
                soft: colors.T50,
                hard: colors.T200
            },
            cols: ['DONE', 'CANCEL'],
            userRole: ['ADMIN', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") {
                    // dont delete
                }
                if (action === "REJECT") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            comment,
                            updatedBy,
                            col: "CANCEL",
                            status: 'REJECTED',
                        }
                    })
                }
                if (action === "SAVE") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            col: "TODO",
                            status: "PLANNING",
                            planning,
                            comment,
                            updatedBy,
                        }
                    })
                }
                if (action === "SUBMIT") {
                    // มี plan หรือไม่ และใส่ password หรือเปล่า?
                    if (planning && planning !== "[]") {
                        let planList = JSON.parse(planning)//?.filter((e) => { return e.eventId && e.eventTitle });
                        if (planList.length > 0) {
                            res = _.extend({}, res, {
                                isValid: true,
                                input: {
                                    requestId,
                                    col: "DOING", // old: TODO
                                    status: "DOING", // old: PLANNING
                                    planning,
                                    comment,
                                    updatedBy,
                                }
                            })
                        }
                    }
                }
                if (action === "MOVE") {
                    // มี plan หรือไม่ และใส่ password หรือเปล่า?
                    if (board?.destination?.droppableId?.toUpperCase() === "DOING") {
                        if (planning && planning !== "[]") {
                            let planList = JSON.parse(planning)//?.filter((e) => { return e.eventId && e.eventTitle });
                            if (planList.length > 0) {
                                res = _.extend({}, res, {
                                    isValid: true,
                                    input: {
                                        requestId,
                                        col: "DOING", // ย้ายไปไว้ที่ col:DOING
                                        status: "DOING",
                                        planning,
                                        comment,
                                        updatedBy,
                                    }
                                })
                            }
                        }
                    }
                    if (board?.destination?.droppableId?.toUpperCase() === "OPEN") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                isBack: true,
                                requestId,
                                col: "OPEN",
                                status: "OPEN",
                                departmentInCharge: "",
                                personsInCharge: "[]",
                                planning: "[]",
                                comment,
                                updatedBy,
                            }
                        })
                    }
                }
                if (action === "BACK") {
                }
                return res;
            },
        },
        {
            name: 'Doing',
            TH: "",
            status: "DOING",
            value: "DOING",
            isCol: true,
            colors: {
                soft: colors.P50,
                hard: colors.P200
            },
            cols: ['OPEN', 'DONE', 'CANCEL'],
            userRole: ['ADMIN', 'MANAGER', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result, resultImages,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") {
                    // dont delete
                }
                if (action === "REJECT") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            comment,
                            updatedBy,
                            col: "CANCEL",
                            status: 'REJECTED',
                        }
                    })
                }
                if (action === "SAVE") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            col: "DOING",
                            status: "DOING",
                            completionDate,
                            reason,
                            result,
                            resultImages,
                            comment,
                            updatedBy,
                        }
                    })
                }
                if (action === "SUBMIT") {
                    // ใส่ completionDate, reason, result หรือเปล่า และใส่ password หรือเปล่า? 
                    if (completionDate && result && password && password !== "") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                requestId,
                                col: "DONE", // old: DOING
                                status: "RESOLVED", // old: RESOLVED
                                completionDate,
                                reason,
                                result,
                                resultImages,
                                comment,
                                updatedBy,
                            }
                        })
                    } else {

                    }
                }
                if (action === "MOVE") {
                    if (board?.destination?.droppableId?.toUpperCase() === "TODO") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                isBack: true,
                                requestId,
                                col: "TODO",
                                status: "TODO",
                                // departmentInCharge: null,
                                // personsInCharge: "[]",
                                planning: "[]",
                                completionDate: "",
                                reason: "",
                                result: "",
                                resultImages: "",
                                comment,
                                updatedBy,
                            }
                        })
                    }
                }
                if (action === "BACK") {

                }
                return res;
            },
        },
        {
            name: 'Done',
            TH: "",
            status: "DONE",
            value: "DONE",
            isCol: true,
            colors: {
                soft: colors.G50,
                hard: colors.G200
            },
            cols: [],
            userRole: ['ADMIN', 'MANAGER', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") { }
                if (action === "REJECT") { }
                if (action === "SAVE") { }
                if (action === "SUBMIT") { }
                if (action === "MOVE") { }
                if (action === "BACK") { }

                return res;
            },
        },
        {
            name: 'Resolved',
            TH: "",
            status: "DOING",
            value: "RESOLVED",
            colors: {
                soft: colors.G50,
                hard: colors.G200
            },
            cols: ['OPEN', 'TODO', 'CANCEL'],
            userRole: ['ADMIN', 'MANAGER', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            updatedBy,
                        }
                    })
                }
                if (action === "REJECT") {
                    // dont reject
                }
                if (action === "SAVE") {
                    // dont save
                }
                if (action === "SUBMIT") {
                    // dont submit
                }
                if (action === "MOVE") {
                    if (board?.destination?.droppableId?.toUpperCase() === "DONE") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                requestId,
                                col: "DONE", // ย้ายไปไว้ที่ col:DONE
                                status: "RESOLVED",
                                completionDate,
                                reason,
                                result,
                                comment,
                                updatedBy,
                            }
                        })
                    }
                    if (board?.destination?.droppableId?.toUpperCase() === "DOING") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                isBack: true,
                                requestId,
                                col: "DOING",
                                status: "DOING",
                                // departmentInCharge: null,
                                // personsInCharge: "[]",
                                // planning,
                                completionDate: "",
                                reason: "",
                                result: "",
                                resultImages: "",
                                comment,
                                updatedBy,
                            }
                        })
                    }
                }
                if (action === "BACK") {
                }
                return res;
            },
        },
        {
            name: 'Rejected',
            TH: "",
            status: "DOING",
            value: "REJECTED",
            colors: {
                soft: colors.R50,
                hard: colors.R200
            },
            cols: ['TODO', 'DOING', 'DONE'],
            userRole: ['ADMIN', 'MANAGER', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            updatedBy,
                        }
                    })
                }
                if (action === "REJECT") {
                    // dont reject
                }
                if (action === "SAVE") {
                    // dont save
                }
                if (action === "SUBMIT") {
                    // dont submit
                }
                if (action === "MOVE") {
                    if (board?.destination?.droppableId?.toUpperCase() === "CANCEL") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                requestId,
                                col: "CANCEL", // ย้ายไปไว้ที่ col:CANCEL
                                status: "REJECTED",
                                comment,
                                updatedBy,
                            }
                        })
                    }
                    if (board?.destination?.droppableId?.toUpperCase() === "OPEN") {
                        res = _.extend({}, res, {
                            isValid: true,
                            input: {
                                isBack: true,
                                requestId,
                                col: "OPEN",
                                status: "OPEN",
                                departmentInCharge: "",
                                personsInCharge: "[]",
                                planning: "[]",
                                completionDate: "",
                                reason: "",
                                result: "",
                                resultImages: "",
                                comment,
                                updatedBy,
                            }
                        })
                    }
                }
                if (action === "BACK") {
                }
                return res;
            },
        },
        {
            name: 'Cancel',
            TH: "",
            status: "CANCEL",
            value: "CANCEL",
            isCol: true,
            statusList: ['REJECTED'],
            colors: {
                soft: colors.R50,
                hard: colors.R200
            },
            cols: [],
            userRole: ['ADMIN', 'MANAGER', 'GENERAL_MANAGER'],
            validateForNextStep: (values, action, board) => {
                let res = { isValid: false }
                let { status, requestId, priorityNo,
                    departmentInCharge, personsInCharge,
                    planning,
                    completionDate, reason, result,
                    comment, updatedBy, password
                } = values;
                if (action === "REORDER") {
                    res = _.extend({}, res, {
                        isValid: true,
                        input: {
                            requestId,
                            priorityNo,
                            updatedBy,
                        }
                    })
                }
                if (action === "DELETE") { }
                if (action === "REJECT") { }
                if (action === "SAVE") { }
                if (action === "SUBMIT") { }
                if (action === "MOVE") { }
                if (action === "BACK") { }
                res = _.extend({}, res, {
                    isValid: true,
                })
                return res;
            },
        },
    ],
    columns: {},
    ordered: [],
    status: null,
};


// ==============================|| ACTIONS ||============================== //

export const getItems = (input, callback) => {
    return (dispatch) => {
        const queryString = getQueryString(input);
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_REQUESTS}${queryString}`).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_REQUESTS,
                payload: result.data
            });
            if (callback) callback(result.data);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    };
}
export const getLastItems = (callback) => {
    return (dispatch) => {
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_LAST_REQUEST}`).then(response => {
            let result = response.data;
            let item = result.data?.[0];
            let primaryCode = "requestNo";
            let replaceStr = "REQUEST_NO";
            let lastCode = `${replaceStr}0`;
            if (item) {
                let count = Number.isNaN(parseInt(item[primaryCode]?.replace(replaceStr, ""))) ? 0 : parseInt(item[primaryCode]?.replace(replaceStr, "")) + 1;
                lastCode = `${replaceStr}${count}`
            }
            if (callback) callback(item, lastCode);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    };
}
export const getItem = (requestId, callback) => {
    return (dispatch) => {
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_REQUEST.replace(':requestId', requestId)}`).then(response => {
            let result = response.data;
            // dispatch({
            //     type: actionTypes.SET_REQUESTS,
            //     payload: result.data
            // });
            if (callback) callback(result.data);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null);
        });
    };
}
export const createItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                if (key === "files") {
                    let files = input[key];
                    files.forEach((file, index) => {
                        bodyFormData.append(`${'file'}_${index}`, file);
                    });
                } else {
                    bodyFormData.append(key, input[key]);
                }
            }
        });
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.CREATE_REQUEST}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const updateItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        let mapInput = _.omit(input, ['isBack']);
        Object.keys(mapInput).forEach(key => {
            if (input?.['isBack']) {
                bodyFormData.append(key, input[key]);
            } else {
                if (input[key] == null || input[key] == undefined || input[key] == "") {

                } else {
                    if (key === "resultImages") {
                        let files = input[key];
                        files.forEach((file, index) => {
                            bodyFormData.append(`${'file'}_${index}`, file);
                        });
                    } else {
                        bodyFormData.append(key, input[key]);
                    }
                }
            }
        });
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.UPDATE_REQUEST}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const reorderItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {
            } else {
                if (key === "resultImages") {
                } else {
                    bodyFormData.append(key, input[key]);
                }
            }
        });
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.REORDER_REQUEST}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const approveItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            // if (input[key] == null || input[key] == undefined || input[key] == "") {

            // } else {
            // if (key === "resultImages") {
            //     let files = input[key];
            //     files.forEach((file, index) => {
            //         bodyFormData.append(`${'file'}_${index}`, file);
            //     });
            // } else {
            bodyFormData.append(key, input[key]);
            // }
            // }
        });
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.APPROVE_REQUEST.replace(':requestId', input?.requestId)}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "delete",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_REQUEST}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItems = (input, callback) => {
    return (dispatch) => {
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_REQUESTS}`,
            data: input,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
// ==============================|| REDUCER ||============================== //

const requestReducer = (state = initialState, action) => {
    let { payload } = action;
    let oldState, item, isExist, index, newItems, oldItems;
    switch (action.type) {
        case actionTypes.SET_REQUEST_STATUS:
            return {
                ...state,
                status: payload,
            };
        case actionTypes.SET_REQUESTS:
            return {
                ...state,
                requests: payload,
            };
        case actionTypes.CREATE_REQ:
            oldState = _.extend({}, state);
            item = payload;
            isExist = oldState?.requests?.find((i) => { return i.requestId === item.requestId });
            if (isExist) return state
            newItems = _.clone(oldState?.requests);
            newItems.unshift(item);
            return {
                ...state,
                requests: newItems,
                status: 'UPDATE_REQUEST',
            };
        case actionTypes.UPDATE_REQUEST_NULL:
            return {
                ...state,
                updateItem: null,
            };
        case actionTypes.UPDATE_REQ:
            oldState = _.extend({}, state);
            item = payload;
            isExist = oldState?.requests?.find((i) => { return i.requestId === item.requestId });
            if (!isExist) return state
            index = _.findIndex(oldState?.requests, (i) => { return i.requestId === item.requestId });
            newItems = _.clone(oldState?.requests);
            newItems.splice(index, 1, item);
            return {
                ...state,
                requests: newItems,
                updateItem: item,
                status: 'UPDATE_REQUEST',
            };
        case actionTypes.UPDATE_REQ_ORDER:
            oldState = _.extend({}, state);
            item = payload;
            isExist = oldState?.requests?.find((i) => { return i.requestId === item.requestId });
            if (!isExist) return state
            index = _.findIndex(oldState?.requests, (i) => { return i.requestId === item.requestId });
            newItems = _.clone(oldState?.requests);
            newItems.splice(index, 1, item);
            return {
                ...state,
                requests: newItems,
                // status: 'UPDATE_REQUEST',
            };
        case actionTypes.UPDATE_REQ_ORDER_END:
            return {
                ...state,
                // requests: newItems,
                status: 'UPDATE_REQUEST',
            };
        case actionTypes.DELETE_REQ:
            oldState = _.extend({}, state);
            item = payload;
            isExist = oldState?.requests?.find((i) => { return i.requestId === item.requestId });
            if (!isExist) return state
            index = _.findIndex(oldState?.requests, (i) => { return i.requestId === item.requestId });
            newItems = _.clone(oldState?.requests);
            newItems.splice(index, 1);
            return {
                ...state,
                requests: newItems,
                status: 'UPDATE_REQUEST',
            };
        case actionTypes.SET_COLUMNS:
            oldState = _.extend({}, state);
            item = payload;
            return {
                ...state,
                columns: item,
            };
        case actionTypes.SET_ORDERED:
            oldState = _.extend({}, state);
            item = payload;
            return {
                ...state,
                ordered: item,
            };
        default:
            return state;
    }
};

export default requestReducer;
