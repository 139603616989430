import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Route, Navigate, useLocation } from 'react-router';
import socketIOClient from 'socket.io-client'

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Link, Toolbar, Typography, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { CREATE_REQ, CREATE_TASK, DELETE_REQ, DELETE_TASK, SET_MENU, SET_REQUEST_STATUS, UPDATE_REQ, UPDATE_REQ_ORDER, UPDATE_REQ_ORDER_END, UPDATE_TASK } from 'store/actions';
import config from '../../config';

// assets
import { IconChevronRight } from '@tabler/icons';
import { isLoggedIn } from 'store/reducers/userReducer';
import AuthFooter from 'ui-component/cards/AuthFooter';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    let location = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    const dispatch = useDispatch();
    const [endpoint, setEndpoint] = useState(config[config.enviroment].server_endpoint.replace("/api/v2", ""));
    const [auth, setAuth] = useState(null);
    const [isAuth, setIsAuth] = useState(false);
    const [isAlready, setIsAlready] = useState(false);
    let socket;


    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        response();
    }, [matchDownMd]);

    useEffect(() => {
        console.log('MainLayout', isAuth)
        if (!isAuth) {
            dispatch(isLoggedIn(false, (res) => {
                setIsAuth(res);
                setIsAlready(true);
            }));
        }

        return () => {
            console.log("cleaned up");
            if (socket) socket.disconnect();
        };
    }, []);


    // เมื่อมีการส่งข้อมูลไปยัง server
    const send = (message) => {
        const socket = socketIOClient(endpoint, {
            // rejectUnauthorized: false
        })
        socket.emit('sent-message', 'input')
    }

    // รอรับข้อมูลเมื่อ server มีการ update
    const response = () => {
        socket = socketIOClient(endpoint);
        socket.on("connect", () => {
            console.log('socket connect', socket.id)
        });
        socket.on("connect_error", (err) => {
            console.log(`event: connect_error | reason: ${err.message}`);
        });

        socket.on("disconnect", (reason) => {
            console.log(`event: disconnect | reason: ${reason}`);
        });
        socket.on('chat message', (message) => {
            console.log(message)
        });
        socket.on(CREATE_TASK, (message) => {
            console.log(CREATE_TASK, message)
            dispatch({ type: CREATE_TASK, payload: message })
        });
        socket.on(UPDATE_TASK, (message) => {
            console.log(UPDATE_TASK, message)
            dispatch({ type: UPDATE_TASK, payload: message })
        });
        socket.on(DELETE_TASK, (message) => {
            console.log(DELETE_TASK, message)
            dispatch({ type: DELETE_TASK, payload: message })
        });
        socket.on(CREATE_REQ, (message) => {
            console.log(CREATE_REQ, message)
            dispatch({ type: CREATE_REQ, payload: message })
        });
        socket.on(UPDATE_REQ, (message) => {
            console.log(UPDATE_REQ, message)
            dispatch({ type: UPDATE_REQ, payload: message })
        });
        socket.on(UPDATE_REQ_ORDER, (message) => {
            console.log(UPDATE_REQ_ORDER, message)
            dispatch({ type: UPDATE_REQ_ORDER, payload: message })
        });
        socket.on(UPDATE_REQ_ORDER_END, (message) => {
            console.log(UPDATE_REQ_ORDER_END, message)
            dispatch({ type: UPDATE_REQ_ORDER_END, payload: message })
        });
        socket.on(DELETE_REQ, (message) => {
            console.log(DELETE_REQ, message)
            dispatch({ type: DELETE_REQ, payload: message })
        });
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar style={{ padding: '10px' }}>
                    {<Header handleLeftDrawerToggle={handleLeftDrawerToggle} />}
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened} style={{ padding: '15px', display: 'flex', flexDirection: 'column' }}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                {/* {dispatch(isLoggedIn()) ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />} */}
                {isAlready && <>
                    {isAuth && <Outlet />}
                    {!isAuth && <Navigate to="/login" state={{ from: location }} />}
                </>}
                {!isAlready && <Outlet />}
                <div style={{flex: 1}}></div>
                <div style={{paddingTop: '10px'}}><AuthFooter /></div>
            </Main>
            <Customization />
        </Box>
    );
};

export default MainLayout;
