import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from '@mui/material';
import * as Yup from 'yup';
import { gridSpacing } from 'store/constant';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import DEFAULT_USER_IMAGE from 'assets/images/default/default_user.png';

// assets
import CloseIcon from '@mui/icons-material/Close';
import { IconLogout, IconSettings, IconLockOpen, IconEdit } from '@tabler/icons';
import { logout } from 'store/reducers/userReducer';
import { getDownloadUrl } from 'store/reducers/fileReducer';
import { Formik } from 'formik';
import { createObjectURL } from 'assets/js/file';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import * as accountReducers from 'store/reducers/accountReducer';

// ==============================|| PROFILE MENU ||============================== //
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const Input = styled('input')({
    display: 'none',
});

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const selectors = useSelector((state) => {
        return state
    });
    const authUser = useSelector((state) => state.user.authUser);
    const account = useSelector((state) => state.account.account);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [openChangePasswordForm, setOpenChangePasswordForm] = useState(false);
    const [formType, setFormType] = useState("UPDATE");
    const [formData, setFormData] = useState(null);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        dispatch(logout(() => {
            navigate('/login');
        }));
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleClickCloseForm = () => {
        setOpenForm(false);
    };
    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const cancel = () => {
        handleClickCloseForm();
    }
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    const updateItem = (item, image, callback) => {
        let input = _.pick(item, ['accountId', 'accountImage', 'updatedBy'])
        input['accountImage'] = image;
        console.log("updateItem", input)
        dispatch(accountReducers.updateItem(input, (res, err) => {
            if (res) {
                dispatch(accountReducers.setAccount(res));
                callback(res);
            }
        }))
    }
    const changePassword = (item, callback) => {
        let input = item;
        console.log("changePassword", input)
        dispatch(accountReducers.changePassword(input, (res, err) => {
            if (res) {
                callback(res);
            }
        }))
    }

    const renderProfileForm = () => {
        return <BootstrapDialog
            onClose={handleClickCloseForm}
            aria-labelledby="customized-dialog-title"
            open={openForm}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickCloseForm}>
                <span style={{ fontSize: '15px' }}>{`แก้ไข Profile`}</span>
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Formik
                    initialValues={_.extend({}, formData, {
                        isChangeFile: false,
                        oldAccountImage: formData?.accountImage,
                        updatedBy: selectors?.user?.authUser?.username
                    })}
                    validationSchema={Yup.object().shape({
                        // accountCode: Yup.string().max(255).required('Account Code is required'),
                        // accountName: Yup.string().max(255).required('Account Name is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                        // submit(_.omit(values, ['submit']));
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} >
                            <Grid container spacing={gridSpacing}>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <Avatar
                                        src={values && values?.accountImage ?
                                            getDownloadUrl(values?.accountImage) : DEFAULT_USER_IMAGE}
                                        sx={{ width: 150, height: 150, marginLeft: 'auto', marginRight: 'auto' }}
                                        alt={values.accountName}
                                        color="inherit"
                                    />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px', textAlign: 'center' }}>
                                    <FormControl fullWidth >
                                        <label htmlFor="outlined-accountImage-form">
                                            <Input accept="image/*" id="outlined-accountImage-form"
                                                type="file"
                                                disabled={formType == "VIEW_DETAILS"}
                                                onChange={(e) => {
                                                    updateItem(values, e.currentTarget.files[0], (newItem) => {
                                                        setFieldValue("isChangeFile", true);
                                                        setFieldValue("accountImage", newItem['accountImage']);
                                                    });
                                                    // upload 
                                                    // update accountImage in 
                                                    // setFieldValue("accountImage", e.currentTarget.files[0]);
                                                    // if (formType === "UPDATE") setFieldValue("isChangeFile", true);
                                                }} />
                                            <Button variant="outlined"
                                                component="span">
                                                Upload Image
                                            </Button>
                                        </label>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="outlined-accountName-form">Account Name</InputLabel>
                                        <OutlinedInput
                                            id="outlined-accountName-form"
                                            type="text"
                                            name="accountName"
                                            value={values.accountName}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Account Name"
                                            inputProps={{}}
                                            readOnly={true}
                                        />
                                        {touched.accountName && errors.accountName && (
                                            <FormHelperText error id="standard-weight-helper-text-accountName-login">
                                                {errors.accountName}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="outlined-username-form">Username</InputLabel>
                                        <OutlinedInput
                                            id="outlined-username-form"
                                            type="text"
                                            name="username"
                                            value={values.username}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Username"
                                            inputProps={{}}
                                            readOnly={true}
                                        />
                                        {touched.username && errors.username && (
                                            <FormHelperText error id="standard-weight-helper-text-username-login">
                                                {errors.username}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="outlined-createdAt-form">Register at</InputLabel>
                                        <OutlinedInput
                                            id="outlined-createdAt-form"
                                            type="text"
                                            name="createdAt"
                                            value={moment(values.createdAt).format('DD/MM/YYYY')}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Register at"
                                            inputProps={{}}
                                            readOnly={true}
                                        />
                                        {touched.createdAt && errors.createdAt && (
                                            <FormHelperText error id="standard-weight-helper-text-createdAt-login">
                                                {errors.createdAt}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="outlined-projectCode-form">Project Code</InputLabel>
                                        <OutlinedInput
                                            id="outlined-projectCode-form"
                                            type="text"
                                            name="projectCode"
                                            value={values.projectCode}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Project Code"
                                            inputProps={{}}
                                            readOnly={true}
                                        />
                                        {touched.projectCode && errors.projectCode && (
                                            <FormHelperText error id="standard-weight-helper-text-projectCode-login">
                                                {errors.projectCode}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="outlined-deptCode-form">Department Code</InputLabel>
                                        <OutlinedInput
                                            id="outlined-deptCode-form"
                                            type="text"
                                            name="deptCode"
                                            value={values.deptCode}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Department Code"
                                            inputProps={{}}
                                            readOnly={true}
                                        />
                                        {touched.deptCode && errors.deptCode && (
                                            <FormHelperText error id="standard-weight-helper-text-deptCode-login">
                                                {errors.deptCode}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="outlined-position-form">Position</InputLabel>
                                        <OutlinedInput
                                            id="outlined-position-form"
                                            type="text"
                                            name="position"
                                            value={values.position}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Position"
                                            inputProps={{}}
                                            readOnly={true}
                                        />
                                        {touched.position && errors.position && (
                                            <FormHelperText error id="standard-weight-helper-text-position-login">
                                                {errors.position}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <Divider />
                                </Grid>



                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                {/* <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px', textAlign: 'right' }} >
                                    <Box sx={{ mt: 2 }} style={{ textAlign: 'right' }}>
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '10px', marginLeft: '10px' }}
                                            onClick={cancel}
                                            disabled={formType == "VIEW_DETAILS"}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            disabled={isSubmitting || (formType == "VIEW_DETAILS")}
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Grid> */}
                            </Grid>
                        </form>
                    )}
                </Formik>
            </DialogContent>

        </BootstrapDialog>
    }
    const renderChangePasswordForm = () => {
        return <BootstrapDialog
            onClose={() => { setOpenChangePasswordForm(false); }}
            aria-labelledby="customized-dialog-title"
            open={openChangePasswordForm}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { setOpenChangePasswordForm(false); }}>
                <span style={{ fontSize: '15px' }}>{`เปลี่ยนรหัสผ่าน`}</span>
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Formik
                    initialValues={_.extend({}, formData, {
                        userId: selectors?.user?.authUser?.userId,
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                        updatedBy: selectors?.user?.authUser?.username
                    })}
                    validationSchema={Yup.object().shape({
                        oldPassword: Yup.string().required('Old password is required'),
                        newPassword: Yup.string().required('New password is required'),
                        confirmPassword: Yup.string().required('Confirm password is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                        console.log(values);
                        // submit(_.omit(values, ['submit']));
                        changePassword(values, () => {
                            resetForm();
                            setOpenChangePasswordForm(false);
                        });
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, resetForm, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} >
                            <Grid container spacing={gridSpacing}>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="outlined-oldPassword-form">Old Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-oldPassword-form"
                                            type="password"
                                            name="oldPassword"
                                            value={values.oldPassword}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Old Password"
                                            inputProps={{}}
                                        />
                                        {touched.oldPassword && errors.oldPassword && (
                                            <FormHelperText error id="standard-weight-helper-text-oldPassword-login">
                                                {errors.oldPassword}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="outlined-newPassword-form">New Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-newPassword-form"
                                            type="password"
                                            name="newPassword"
                                            value={values.newPassword}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="New Password"
                                            inputProps={{}}
                                        />
                                        {touched.newPassword && errors.newPassword && (
                                            <FormHelperText error id="standard-weight-helper-text-newPassword-login">
                                                {errors.newPassword}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="outlined-confirmPassword-form">Confirm New Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-confirmPassword-form"
                                            type="password"
                                            name="confirmPassword"
                                            value={values.confirmPassword}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Confirm New Password"
                                            inputProps={{}}
                                        />
                                        {touched.confirmPassword && errors.confirmPassword && (
                                            <FormHelperText error id="standard-weight-helper-text-confirmPassword-login">
                                                {errors.confirmPassword}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <Divider />
                                </Grid>



                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px', textAlign: 'right' }} >
                                    <Box sx={{ mt: 2 }} style={{ textAlign: 'right' }}>
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '10px', marginLeft: '10px' }}
                                            onClick={() => { resetForm(); setOpenChangePasswordForm(false); }}
                                        >
                                            ยกเลิก
                                        </Button>
                                        <Button
                                            disabled={isSubmitting || (formType == "VIEW_DETAILS")}
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            บันทึก
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </DialogContent>

        </BootstrapDialog>
    }

    return (
        <>
            {<Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={account && account?.accountImage ? getDownloadUrl(account?.accountImage) : DEFAULT_USER_IMAGE}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />}
            {<Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">สวัสดี</Typography>
                                            </Stack>
                                            <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                {account?.accountName || authUser?.username}
                                            </Typography>
                                            <Typography variant="subtitle2">{account?.accountCode || ''} [{account?.position || authUser?.level}]</Typography>
                                        </Stack>
                                        {/* <OutlinedInput
                                            sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                                            id="input-search-profile"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            placeholder="Search profile options"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                                </InputAdornment>
                                            }
                                            aria-describedby="search-helper-text"
                                            inputProps={{
                                                'aria-label': 'weight'
                                            }}
                                        /> */}
                                    </Box>
                                    <Divider />
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 2 }}>
                                            {account?.license && (<Chip label={account?.license} color={account?.license != "FREE" ? "info" : "default"} variant="outlined" size="small" />)}
                                            <UpgradePlanCard />
                                            <Divider />
                                            {/* <Card
                                                sx={{
                                                    bgcolor: theme.palette.primary.light,
                                                    my: 2
                                                }}
                                            >
                                                <CardContent>
                                                    <Grid container spacing={3} direction="column">
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Start DND Mode</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={sdm}
                                                                        onChange={(e) => setSdm(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Allow Notifications</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        checked={notification}
                                                                        onChange={(e) => setNotification(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card> */}
                                            {/* <Divider /> */}
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {/* <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, '/user/account-profile/profile1')}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton> */}
                                                {/* <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 1}
                                                    onClick={(event) => handleListItemClick(event, 1, '/user/social-profile/posts')}
                                                >
                                                    <ListItemIcon>
                                                        <IconUser stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Grid container spacing={1} justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="body2">Social Profile</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Chip
                                                                        label="02"
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor: theme.palette.warning.dark,
                                                                            color: theme.palette.background.default
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItemButton> */}
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 2}
                                                    onClick={() => {
                                                        setFormData(account);
                                                        setOpenForm(true);
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <IconEdit stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">แก้ไข Profile</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 3}
                                                    onClick={() => {
                                                        setOpenChangePasswordForm(true);
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <IconLockOpen stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">เปลี่ยนรหัสผ่าน</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">ออกจากระบบ</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>}
            {renderProfileForm()}
            {renderChangePasswordForm()}
        </>
    );
};

export default ProfileSection;
