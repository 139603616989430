import menus from './menus';
import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        menus,
        // dashboard, 
        // pages, 
        // utilities, 
        // other,
    ]
};

export default menuItems;
