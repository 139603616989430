// action - state management
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'assets/js/hash';
import axios from 'axios';
import config from '../../config';
import * as actionTypes from '../actions';
import { getAccountByUsername } from './accountReducer';
import { getQueryString } from 'utils/query-string';
// ==============================|| STATE ||============================== //

export const initialState = {
    authUser: null,
    users: [],
};

// ==============================|| ACTIONS ||============================== //

export const autoLogin = (history, callback) => {
    console.log('autoLogin')
    return (dispatch) => {
        if (getLocalStorage("IS_AUTHEN") == "YES") {
            let authen = getLocalStorage("AUTHEN");
            dispatch({ type: actionTypes.SET_AUTH_USER, payload: authen });
            if (history) setTimeout(() => history.push('/overview'), 100);
            if (callback) callback(authen);
        } else {
            dispatch({ type: actionTypes.SET_AUTH_USER, payload: null });
            dispatch({ type: actionTypes.SET_ACCOUNT, payload: null });
        }
    }
}
export const isLoggedIn = (disable, callback) => {
    console.log('isLoggedIn')
    return (dispatch) => {
        let isAuth = getLocalStorage("IS_AUTHEN") === "YES";
        if (isAuth) {
            let authen = getLocalStorage("AUTHEN");
            let { username } = authen
            dispatch({ type: actionTypes.SET_AUTH_USER, payload: authen });
            if (!disable) dispatch(getAccountByUsername(username, () => { }))

        } else {
            dispatch({ type: actionTypes.SET_AUTH_USER, payload: null });
            dispatch({ type: actionTypes.SET_ACCOUNT, payload: null });
        }
        if (callback) callback(isAuth)
        return isAuth
    }
};
export const authen = (input, callback) => {
    return (dispatch) => {
        let { username, email, password } = input;
        let bodyFormData = new FormData();
        bodyFormData.append('username', username ? username : email);
        bodyFormData.append('password', password);
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.AUTHEN_USER}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            // setLocalStorage('IS_AUTHEN', "YES");
            // setLocalStorage('AUTHEN', result.data);
            // let path = (authen.level !== 'unauthen')? "/dashboard": "/scanqr";
            // history.push(path);
            // dispatch(setLoginStateToSuccess(result));
            // dispatch({ type: actionTypes.SET_AUTH_USER, payload: result.data });
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const login = (input, callback) => {
    console.log('login')
    return (dispatch) => {
        let { email, password } = input;
        let bodyFormData = new FormData();
        bodyFormData.append('username', email);
        bodyFormData.append('password', password);
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.LOGIN_USER}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            setLocalStorage('IS_AUTHEN', "YES");
            setLocalStorage('AUTHEN', result.data);
            // let path = (authen.level !== 'unauthen')? "/dashboard": "/scanqr";
            // history.push(path);
            // dispatch(setLoginStateToSuccess(result));
            dispatch({ type: actionTypes.SET_AUTH_USER, payload: result.data });
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    // message: `${result.message}`,
                    message: `${'เข้าสู่ระบบสำเร็จ'}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const logout = (callback) => {
    console.log('logout')
    return (dispatch) => {
        removeLocalStorage('IS_AUTHEN');
        removeLocalStorage('AUTHEN');
        dispatch({ type: actionTypes.SET_AUTH_USER, payload: null });
        dispatch({ type: actionTypes.SET_ACCOUNT, payload: null });
        if (callback) callback();
    };
}
export const register = (input, callback) => {
    return (dispatch) => {
        let { email, password } = input;
        let bodyFormData = new FormData();
        bodyFormData.append('username', email);
        bodyFormData.append('email', email);
        bodyFormData.append('password', password);
        bodyFormData.append('createdBy', email);
        bodyFormData.append('updatedBy', email);
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.REGISTER_USER}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    // message: `${result.message}`,
                    message: `${'ลงทะเบียนสำเร็จ! คุณจะเข้าระบบได้หลังจากได้รับอีเมลล์ยืนยัน ไม่เกิน 1 วันทำการ'}`,
                }
            });
            if (callback) callback(result, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
const getError = (error) => {
    let errorMsg = `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`;
    switch (error?.response?.data?.status) {
        case 'E0002':
            errorMsg = 'ไม่พบ Email นี้บนระบบ';
            break;
        case 'E00021':
            errorMsg = 'ไม่พบอีเมลล์ของคุณ กรุณาติดต่อแอดมิน เพื่อยืนยันการลงทะเบียนให้สำเร็จก่อน';
            break;
        default:
            break;
    }
    return errorMsg;
}
export const forgotPassword = (input, callback) => {
    return (dispatch) => {
        let { email } = input;
        let bodyFormData = new FormData();
        bodyFormData.append('email', email);
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.FORGOT_PASSWORD}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    // message: `${result.message}`,
                    message: `${'ส่ง Email สำหรับ Reset password สำเร็จ! กรุณาตรวจสอบ Email'}`,
                }
            });
            if (callback) callback(result, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: getError(error),
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const resetPassword = (input, callback) => {
    return (dispatch) => {
        let { token, password, confirmPassword } = input;
        let bodyFormData = new FormData();
        bodyFormData.append('token', token);
        bodyFormData.append('password', password);
        bodyFormData.append('confirmPassword', confirmPassword);
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.RESET_PASSWORD}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${'เปลี่ยนรหัสผ่านสำเร็จ กรุณาเข้าใช้งานใหม่'}`,
                }
            });
            if (callback) callback(result, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const getItems = (input, callback) => {
    return (dispatch) => {
        const queryString = getQueryString(input);
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_USERS}${queryString}`).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_USERS,
                payload: result.data
            });
            if (callback) callback(result.data)
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    };
}
export const getLastItems = (callback) => {
    return (dispatch) => {
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_LAST_USER}`).then(response => {
            let result = response.data;
            let item = result.data?.[0];
            let primaryCode = "userCode";
            let replaceStr = "USER";
            let lastCode = `${replaceStr}0`;
            if (item) {
                let count = Number.isNaN(parseInt(item[primaryCode]?.replace(replaceStr, ""))) ? 0 : parseInt(item[primaryCode]?.replace(replaceStr, "")) + 1;
                lastCode = `${replaceStr}${count}`
            }
            if (callback) callback(item, lastCode);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    };
}
export const createItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.CREATE_USER}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const updateItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.UPDATE_USER}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "delete",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_USER}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItems = (input, callback) => {
    return (dispatch) => {
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_USERS}`,
            data: input,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
// ==============================|| REDUCER ||============================== //

const userReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {
        case actionTypes.SET_USERS:
            return {
                ...state,
                users: payload,
            };
        case actionTypes.SET_AUTH_USER:
            return {
                ...state,
                authUser: payload,
            }
        default:
            return state;
    }
};

export default userReducer;
