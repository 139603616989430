import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthForgotPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthResetPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword3')));
const ScanQR = Loadable(lazy(() => import('views/pages/authentication/authentication3/ScanQR')));
const Email = Loadable(lazy(() => import('views/pages/authentication/authentication3/Email')));
const CheckList = Loadable(lazy(() => import('views/pages/authentication/authentication3/CheckList')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/forgot-password',
            element: <AuthForgotPassword3 />
        },
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        },
        {
            path: '/scanqr/:areaCode',
            element: <ScanQR />
        },
        {
            path: '/email',
            element: <Email />
        },
        {
            path: '/check-list/:areaCode',
            element: <CheckList />
        },
        {
            path: '/reset-password',
            element: <AuthResetPassword3 />
        }
    ]
};

export default AuthenticationRoutes;
