const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/material-react/react/default'
    appName: "WorksList",
    appLink: "https://www.worklist-ppm.com/",
    companyName: "The Works Community Management Co.,Ltd.",
    releasedYear: 2022,
    basename: '/',
    defaultPath: '/overview',
    fontFamily: `'Sukhumvit', 'THSarabunNew', 'Sarabun', 'Poppins', 'Roboto', sans-serif`,
    borderRadius: 12,
    // ==============================================
    enviroment: "production",
    development: {
        server_endpoint: "http://localhost:8085/api/v2",
        enabledLogs: true,
    },
    production: {
        server_endpoint: "https://worklist-ppm.com/api/v2",
        enabledLogs: true,
    },
};

export default config;
