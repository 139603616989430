import React from "react";
import styled from "@emotion/styled";
import { borderRadius, grid } from "../constants";


const StatusOverided = styled.small`
flex-grow: 0;
margin: 0;
background-color: ${props => props.colors.soft};
border-radius: ${borderRadius}px;
font-weight: normal;
padding: ${grid / 2}px;
font-size: ${props => props.size}px;
`;


export default class Status extends React.PureComponent {

    render() {
        let { colors, name, size } = this.props;
        return <StatusOverided colors={colors} size={size} >{name}</StatusOverided>
    }
}