// project imports
import config from 'config';

// action - state management
import * as actionTypes from '../actions';
// ==============================|| STATE ||============================== //

export const initialState = {
    isLoading: false,
    error: null,
    success: null,
    snackbar: {
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
        severity: 'info',
        message: "",
    },
};

// ==============================|| ACTIONS ||============================== //

export const setLoading = (status) => {
    return { type: actionTypes.SET_LOADING, payload: status };
}

// ==============================|| REDUCER ||============================== //

const appReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case actionTypes.SET_SNACKBAR:
            return {
                ...state,
                snackbar: payload,
            };
        default:
            return state;
    }
};

export default appReducer;
