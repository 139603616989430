// action - state management
import axios from 'axios';
import config from '../../config';
import * as actionTypes from '../actions';
import _ from 'lodash';
import { getQueryString } from 'utils/query-string';
// ==============================|| STATE ||============================== //

export const initialState = {
    account: null,
    accounts: [],
};

// ==============================|| ACTIONS ||============================== //

export const getAccountByUsername = (username, callback) => {
    return (dispatch) => {
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_ACCOUNT_BY_USERNAME.replace(':username', username)}`).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_ACCOUNT,
                payload: result.data
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const getItems = (input, callback) => {
    return (dispatch) => {
        const queryString = getQueryString(input);
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_ACCOUNTS}${queryString}`).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_ACCOUNTS,
                payload: result.data
            });
            if (callback) callback(result.data)
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    };
}
export const getLastItems = (callback) => {
    return (dispatch) => {
        return axios.get(`${config[config.enviroment].server_endpoint}${actionTypes.GET_LAST_ACCOUNT}`).then(response => {
            let result = response.data;
            let item = result.data?.[0];
            let primaryCode = "accountCode";
            let replaceStr = "ACCOUNT";
            let lastCode = `${replaceStr}0`;
            if (item) {
                let count = Number.isNaN(parseInt(item[primaryCode]?.replace(replaceStr, ""))) ? 0 : parseInt(item[primaryCode]?.replace(replaceStr, "")) + 1;
                lastCode = `${replaceStr}${count}`
            }
            if (callback) callback(item, lastCode);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
        });
    };
}
export const createItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.CREATE_ACCOUNT}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const updateItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {
                if(key === 'projectCode'){
                    bodyFormData.append(key, input[key]);
                }
            } else {
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.UPDATE_ACCOUNT}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const changePassword = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "put",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.CHANGE_PASSWORD}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${'เปลี่ยนรหัสผ่านสำเร็จ'}`,
                }
            });
            if (callback) callback(result, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItem = (input, callback) => {
    return (dispatch) => {
        let bodyFormData = new FormData();
        Object.keys(input).forEach(key => {
            if (input[key] == null || input[key] == undefined || input[key] == "") {

            } else {
                bodyFormData.append(key, input[key]);
            }
        });
        return axios({
            method: "delete",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_ACCOUNT}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const deleteItems = (input, callback) => {
    return (dispatch) => {
        return axios({
            method: "post",
            url: `${config[config.enviroment].server_endpoint}${actionTypes.DELETE_ACCOUNTS}`,
            data: input,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            let result = response.data;
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'success',
                    message: `${result.message}`,
                }
            });
            if (callback) callback(result.data, null);
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_SNACKBAR,
                payload: {
                    open: true,
                    vertical: 'bottom',
                    horizontal: 'center',
                    severity: 'error',
                    message: `${error?.response?.data?.error?.message || error?.response?.data?.message || error?.message}`,
                }
            });
            if (callback) callback(null, error);
        });
    };
}
export const setAccount = (item) => {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.SET_ACCOUNT,
            payload: item,
        });
    };
}
// ==============================|| REDUCER ||============================== //

const accountReducer = (state = initialState, action) => {
    let { payload } = action;
    switch (action.type) {
        case actionTypes.SET_ACCOUNT:
            return {
                ...state,
                account: payload,
            };
        case actionTypes.SET_ACCOUNTS:
            return {
                ...state,
                accounts: payload,
            };
        default:
            return state;
    }
};

export default accountReducer;
