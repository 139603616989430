import * as heic2any from "heic2any";
import heicConvert from 'heic-convert'

import DEFAULT_IMAGE from '../images/default/default-placeholder-image.png';


const convertFile = (file) => {
    return new Promise(async function (resolve) {
        if (!isHEIC(file)) return resolve(file);
        if (typeof (file) === "string") {
            let filebase = await convertToHeic(file)
            // console.log(filebase)
            resolve(filebase);
        } else {
            heic2any({
                blob: file,
                toType: "image/jpeg",
                quality: 0.3,

            }).then(function (convertedFile) {
                // console.log(convertedFile)
                resolve(convertedFile);
            });
        }

    });
}
const convertToHeic = async (url) => {
    const output = await fetch(url)
        .then(async (data) => {
            const buffer = Buffer.from(await data.arrayBuffer())
            return heicConvert({ buffer, format: 'PNG' })
        })

    const imgBase64 = btoa(
        output.reduce((data, byte) => `${data}${String.fromCharCode(byte)}`, '')
    )

    let src = `data:image/png;base64,${imgBase64}`
    return src
}
const createObjectURL = (file, fileId) => {
    convertFile(file).then((res) => {
        let result = res
        if (typeof (file) === "string") {
            result = res
        } else {
            result = URL.createObjectURL(res)
        }
        if(document.getElementById(fileId)) {
            document.getElementById(fileId).src = result
        }
    });
    return DEFAULT_IMAGE
}
const createObjectURL2 = (file, fileId) => {
    // convertFile(file).then((res) => {
        let result = DEFAULT_IMAGE
        if (typeof (file) === "string") {
            result = file
        } else {
            result = URL.createObjectURL(file)
        }
        if(document.getElementById(fileId)) {
            document.getElementById(fileId).src = result
        }
    // });
    return result
}

const isHEIC = (file) => { // check file extension since windows returns blank mime for heic
    if (typeof (file) === "string") {
        return file.indexOf('heic') > -1
    }
    let x = file?.type ? file.type.split('image/').pop() :
        file?.name ? file.name.split('.').pop().toLowerCase() : null;
    return x == 'heic' || x == 'heif';
}

export {
    convertFile,
    createObjectURL,
    createObjectURL2,
    isHEIC,
}