// assets
import { IconBellRinging, IconDashboard, IconDatabase, IconHome, IconDetails, IconUser, IconSettings, IconDeviceDesktopAnalytics, IconAlertTriangle, IconTool  } from '@tabler/icons';

// constant
const icons = {
    IconBellRinging,
    IconDashboard,
    IconDatabase,
    IconHome,
    IconDetails,
    IconUser,
    IconSettings,
    IconDeviceDesktopAnalytics,
    IconAlertTriangle,
    IconTool,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const menus = {
    id: 'menus',
    title: 'Menu',
    type: 'group',
    children: [
        // {
        //     id: 'home',
        //     title: 'Home',
        //     type: 'item',
        //     url: '/coming-soon',
        //     icon: icons.IconHome,
        //     breadcrumbs: false
        // },
        {
            id: 'overview',
            title: 'Vendor Monitoring',
            type: 'item',
            url: '/overview',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'request-on-board',
            title: 'Request on board',
            type: 'item',
            url: '/request-on-board',
            icon: icons.IconAlertTriangle,
            breadcrumbs: false
        },
        {
            id: 'bm-dashboard',
            title: 'BM Dashboard',
            type: 'item',
            url: '/bm-dashboard',
            icon: icons.IconDeviceDesktopAnalytics,
            breadcrumbs: false
        },
        {
            id: 'pm-dashboard',
            title: 'PM Dashboard',
            type: 'item',
            url: '/pm-dashboard',
            icon: icons.IconTool,
            breadcrumbs: false
        },
        {
            id: 'data-management',
            title: 'Data Management',
            type: 'collapse',
            icon: icons.IconDatabase,
            level: ["ADMIN"],
            children: [
                {
                    id: 'user',
                    title: 'User',
                    type: 'item',
                    url: '/user',
                    breadcrumbs: false
                },
                {
                    id: 'account',
                    title: 'Account',
                    type: 'item',
                    url: '/account',
                    breadcrumbs: false
                },
                {
                    id: 'project',
                    title: 'Project',
                    type: 'item',
                    url: '/project',
                    breadcrumbs: false
                },
                {
                    id: 'floor',
                    title: 'Floor',
                    type: 'item',
                    url: '/floor',
                    breadcrumbs: false
                },
                {
                    id: 'area',
                    title: 'Area',
                    type: 'item',
                    url: '/area',
                    breadcrumbs: false
                },
                {
                    id: 'dept',
                    title: 'Department',
                    type: 'item',
                    url: '/department',
                    breadcrumbs: false
                },
                {
                    id: 'problem',
                    title: 'Problem',
                    type: 'item',
                    url: '/problem',
                    breadcrumbs: false
                },
                {
                    id: 'card',
                    title: 'Task',
                    type: 'item',
                    url: '/card',
                    breadcrumbs: false
                },
                {
                    id: 'request',
                    title: 'Request',
                    type: 'item',
                    url: '/request',
                    breadcrumbs: false
                },
                {
                    id: 'event',
                    title: 'History',
                    type: 'item',
                    url: '/event',
                    breadcrumbs: false
                },
                {
                    id: 'form',
                    title: 'Form',
                    type: 'item',
                    url: '/form',
                    breadcrumbs: false
                },
                {
                    id: 'answer',
                    title: 'All Data',
                    type: 'item',
                    url: '/answer',
                    breadcrumbs: false
                },
                {
                    id: 'pm-data',
                    title: 'PM Data',
                    type: 'item',
                    url: '/pm-data',
                    breadcrumbs: false
                },
                {
                    id: 'bm-data',
                    title: 'BM Data',
                    type: 'item',
                    url: '/bm-data',
                    breadcrumbs: false
                },
                {
                    id: 'ananda-data',
                    title: 'Ananda Data',
                    type: 'item',
                    url: '/ananda-data',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/coming-soon',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
    ]
};

export default menus;
