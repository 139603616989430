import {
    Autocomplete, Box, Button, Checkbox, Chip, createFilterOptions, Divider, FormControl, FormControlLabel,
    FormHelperText, FormLabel, Grid, IconButton, InputLabel, OutlinedInput, RadioGroup, TextField, Typography
} from "@mui/material";
import Radio from '@mui/material/Radio';
import { Formik, useFormik } from "formik";
import * as Yup from 'yup';
import { gridSpacing } from 'store/constant';
import { styled } from '@mui/material/styles';

import { getDownloadUrl } from "store/reducers/fileReducer";
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { MobileDatePicker } from "@mui/x-date-pickers";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from "react";
import { DeleteForever } from "@mui/icons-material";
import { SET_SNACKBAR } from "store/actions";
import Status from "ui-component/board/primatives/status";
import { createObjectURL, createObjectURL2 } from "assets/js/file";
import heic2any from "heic2any";

const Input = styled('input')({
    display: 'none',
});

const filter = createFilterOptions();


const RequestForm = (props) => {
    const { fromScreen, formType, formData } = props;
    const dispatch = useDispatch();
    const selectors = useSelector((state) => { return state });

    const formik = useFormik({
        initialValues: {
            requestId: "",
            updatedBy: selectors?.user?.authUser?.username || "",
            password: "",
            status: selectors?.request?.requestStatusList.find((i) => { return i.value === 'OPEN' }),
            col: selectors?.request?.requestStatusList.find((i) => { return i.value === 'OPEN' }),
            departmentInCharge: null,
            personsInCharge: [],
            comment: [],
            planning: [],
            completionDate: null,
            reason: "",
            result: "",
            resultImages: [],
            submit: null,
        },
        onSubmit: values => {
            console.log(JSON.stringify(values, null, 2));
        },
    });

    useEffect(() => {
        if (props.fromScreen === "REQUEST_ON_BOARD" && formType === "UPDATE" && formData) {
            console.log('last formData', formData);
            formik.setValues({
                requestId: formData?.requestId,
                updatedBy: selectors?.user?.authUser?.username || "",
                // updatedBy: fromScreen == "SCAN_QR" ? formData?.updatedBy :
                // fromScreen == "REQUEST" ? (selectors?.user?.authUser?.username) :
                //     fromScreen == "REQUEST_ON_BOARD" && formData?.status?.value != "DONE" ? (selectors?.user?.authUser?.username) : formData?.updatedBy,

                password: "",
                status: formData?.status?.value,
                col: formData?.col?.value,
                departmentInCharge: formData?.departmentInCharge && formData?.departmentInCharge !== "" ? formData?.departmentInCharge : null,
                personsInCharge: JSON.parse(formData?.personsInCharge),
                comment: JSON.parse(formData?.comment),
                planning: JSON.parse(formData?.planning)?.map((event) => {
                    return _.extend({}, event, {
                        startEventDate: event?.startEventDate ? moment(event?.startEventDate) : null,
                        endEventDate: event?.endEventDate ? moment(event?.endEventDate) : null,
                    })
                }),
                completionDate: formData?.completionDate && formData?.completionDate !== "" ? moment(formData?.completionDate) : null,
                reason: formData?.reason || "",
                result: formData?.result || "",
                resultImages: (formData?.resultImages == "" || formData?.resultImages == null) ? [] : formData?.resultImages?.split(","),
                isChangeResultImages: false,
                oldResultImages: (formData?.resultImages == "" || formData?.resultImages == null) ? [] : formData?.resultImages?.split(","),
                submit: null,
            });
        }
    }, [formData]);

    // useEffect(() => {
    //     if (props.fromScreen === "REQUEST_ON_BOARD" && formType === "UPDATE" && formData) {
    //         console.log('last formData', formData);
    //         formik.setValues({
    //             requestId: formData?.requestId,
    //             updatedBy: selectors?.user?.authUser?.username || "",
    //             // updatedBy: fromScreen == "SCAN_QR" ? formData?.updatedBy :
    //             // fromScreen == "REQUEST" ? (selectors?.user?.authUser?.username) :
    //             //     fromScreen == "REQUEST_ON_BOARD" && formData?.status?.value != "DONE" ? (selectors?.user?.authUser?.username) : formData?.updatedBy,

    //             password: "",
    //             status: formData?.status?.value,
    //             col: formData?.col?.value,
    //             departmentInCharge: formData?.departmentInCharge && formData?.departmentInCharge !== "" ? formData?.departmentInCharge : null,
    //             personsInCharge: JSON.parse(formData?.personsInCharge),
    //             comment: JSON.parse(formData?.comment),
    //             planning: JSON.parse(formData?.planning)?.map((event) => {
    //                 return _.extend({}, event, {
    //                     startEventDate: event?.startEventDate ? moment(event?.startEventDate) : null,
    //                     endEventDate: event?.endEventDate ? moment(event?.endEventDate) : null,
    //                 })
    //             }),
    //             completionDate: formData?.completionDate && formData?.completionDate !== "" ? moment(formData?.completionDate) : null,
    //             reason: formData?.reason || "",
    //             result: formData?.result || "",
    //             resultImages: (formData?.resultImages == "" || formData?.resultImages == null) ? [] : formData?.resultImages?.split(","),
    //             isChangeResultImages: false,
    //             oldResultImages: (formData?.resultImages == "" || formData?.resultImages == null) ? [] : formData?.resultImages?.split(","),
    //             submit: null,
    //         });
    //     }
    // }, [selectors?.request?.updateItem]);

    const getDiffTime = (start, end) => {
        if (!start) return ''
        if (!end) return ''
        let isSame = end.isSame(start, 'day'); // on plan
        let isBefore = end.isBefore(start, 'day'); // advance
        let diff = end.from(start, true); // diff days

        if (isSame) return `On plan`
        if (isBefore) return `Advance ${diff}`
        return `Delay ${diff}`
    }
    const getPlans = (plans, field) => {
        let items = _.clone(plans);
        items = items.sort((p1, p2) => p2[field] > p1[field] ? -1 : 1)
        return items;
    }
    const getFieldsToRender = () => {
        const { fromScreen, formType, formData } = props;
        let isRenders = {
            requestNo: true,
            priorityNo: true,
            status: true,
            col: true,
            informerTypeLabel: true,
            informerType: true,
            createdBy: true,
            areaLabel: true,
            projectCode: true,
            areaType: true,
            areaDescription: true,
            problemLabel: true,
            problemCode: true,
            description: true,
            files: true,
            departmentInCharge: true,
            personsInCharge: true,
            comment: true,
            planning: true,
            completionDate: true,
            reason: true,
            result: true,
            resultImages: true,
            updatedBy: true,
            password: true,
            cancel: true,
            save: true,
            submit: true,
            reject: true,
            delete: true,
            buttons: true,
        }

        if (fromScreen === "REQUEST") {
            isRenders['updatedBy'] = false
            isRenders["password"] = false

            if (formType === "CREATE") {
                isRenders['requestNo'] = false
                isRenders['priorityNo'] = false
                isRenders['status'] = false
                isRenders['col'] = false

                isRenders['departmentInCharge'] = false
                isRenders['personsInCharge'] = false
                isRenders['comment'] = false
                isRenders['planning'] = false
                isRenders['completionDate'] = false
                isRenders['reason'] = false
                isRenders['result'] = false
                isRenders['resultImages'] = false
            }

        }

        if (fromScreen === "REQUEST_ON_BOARD") {
            if (formData?.status?.value === "OPEN") {
                isRenders['planning'] = false
                isRenders['completionDate'] = false
                isRenders['reason'] = false
                isRenders['result'] = false
                isRenders['resultImages'] = false

                if (selectors?.account?.account?.position !== "ADMIN" && selectors?.account?.account?.position !== "MANAGER" && selectors?.account?.account?.position !== "GENERAL_MANAGER") {
                    isRenders['password'] = false
                    isRenders['buttons'] = false
                }
            }
            if (formData?.status?.value === "WAITING") {
                isRenders['planning'] = false
                isRenders['completionDate'] = false
                isRenders['reason'] = false
                isRenders['result'] = false
                isRenders['resultImages'] = false

                if (selectors?.account?.account?.position !== "ADMIN" && selectors?.account?.account?.position !== "MANAGER" && selectors?.account?.account?.position !== "GENERAL_MANAGER") {
                    isRenders['password'] = false
                    isRenders['buttons'] = false
                }
            }
            if (formData?.status?.value === "APPROVED") {
                isRenders['planning'] = false
                isRenders['completionDate'] = false
                isRenders['reason'] = false
                isRenders['result'] = false
                isRenders['resultImages'] = false
                isRenders['password'] = false

                if (selectors?.account?.account?.position !== "ADMIN" && selectors?.account?.account?.position !== "MANAGER" && selectors?.account?.account?.position !== "GENERAL_MANAGER") {
                    isRenders['password'] = false
                    isRenders['buttons'] = false
                } else {
                    isRenders['submit'] = false
                }

            }
            if (formData?.status?.value === "TODO") {
                isRenders['completionDate'] = false
                isRenders['reason'] = false
                isRenders['result'] = false
                isRenders['resultImages'] = false

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    isRenders['password'] = false
                    isRenders['buttons'] = false
                }
            }
            if (formData?.status?.value === "PLANNING") {
                isRenders['completionDate'] = false
                isRenders['reason'] = false
                isRenders['result'] = false
                isRenders['resultImages'] = false

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                    isRenders['password'] = false
                    isRenders['submit'] = true
                } else if (selectors?.account?.account?.position === "MANAGER") {
                    isRenders['buttons'] = true
                    isRenders['password'] = false
                    isRenders['submit'] = false
                } else {
                    isRenders['password'] = false
                    isRenders['buttons'] = false
                }
            }
            if (formData?.status?.value === "DOING") {
                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    isRenders['password'] = false
                    isRenders['buttons'] = false
                }
            }
            if (formData?.status?.value === "RESOLVED") {
                isRenders['buttons'] = false
                isRenders['password'] = false

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    isRenders['password'] = false
                    isRenders['buttons'] = false
                }
            }
            if (formData?.status?.value === "REJECTED") {
                isRenders['buttons'] = false
                isRenders['password'] = false

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    isRenders['password'] = false
                    isRenders['buttons'] = false
                }
            }
            if (formData?.status?.value === "DONE") {
                isRenders['buttons'] = false
                isRenders['password'] = false
            }
            if (formData?.col?.value === "DONE" || formData?.col?.value === "CANCEL") {
                isRenders['buttons'] = true
                isRenders['delete'] = true
                isRenders['reject'] = false
                isRenders['submit'] = false
                isRenders['save'] = false

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    isRenders['password'] = false
                    isRenders['buttons'] = false
                }
            } else {
                isRenders['delete'] = false
            }
            if (formType === "VIEW_DETAILS") {
                isRenders['password'] = false
                isRenders['buttons'] = false
            }
        }

        if (fromScreen === "SCAN_QR") {
            isRenders['requestNo'] = false
            isRenders['priorityNo'] = false
            isRenders['status'] = false
            isRenders['col'] = false

            isRenders['departmentInCharge'] = false
            isRenders['personsInCharge'] = false
            isRenders['comment'] = false

            isRenders['planning'] = false

            isRenders['completionDate'] = false
            isRenders['reason'] = false
            isRenders['result'] = false
            isRenders['resultImages'] = false

            isRenders['updatedBy'] = false
            isRenders["password"] = false
            isRenders['cancel'] = false

            // if (values?.informerType?.deptCode === "OTHERS") {
            //     isRenders['createdBy'] = true
            // } else {
            //     isRenders['createdBy'] = false
            // }

        }

        // console.log('[isRenders]', isRenders);

        return isRenders;
    }
    const getDisabledToRender = (values) => {
        const { fromScreen, formType, formData } = props;
        let isDisabled = {
            requestNo: false,
            priorityNo: false,
            status: false,
            col: false,
            informerTypeLabel: false,
            informerType: false,
            createdBy: false,
            areaLabel: false,
            projectCode: false,
            areaType: false,
            areaDescription: false,
            problemLabel: false,
            problemCode: false,
            description: false,
            files: false,
            departmentInCharge: false,
            personsInCharge: false,
            comment: false,
            planning: false,
            completionDate: false,
            reason: false,
            result: false,
            resultImages: false,
            updatedBy: false,
            password: false,
            cancel: false,
            save: false,
            submit: false,
            reject: false,
            delete: false,
        }

        if (fromScreen === "REQUEST") {
            if (formType == "CREATE") {

            }
            if (formType == "UPDATE") {
                isDisabled['requestNo'] = true

                isDisabled['informerType'] = true
                isDisabled['createdBy'] = true

                isDisabled['projectCode'] = true
                isDisabled['areaType'] = true
                isDisabled['areaDescription'] = true

                isDisabled['problemCode'] = true
                isDisabled['description'] = true
                isDisabled['files'] = true

            }
        }

        if (fromScreen === "REQUEST_ON_BOARD") {
            if (formData?.status?.value === "OPEN") {
                if (selectors?.account?.account?.position !== "ADMIN" && selectors?.account?.account?.position !== "MANAGER" && selectors?.account?.account?.position !== "GENERAL_MANAGER") {
                    Object.keys(isDisabled).forEach((key) => {
                        isDisabled[key] = true
                    })
                }
                // if (selectors?.account?.account?.position !== "MANAGER") { }
            }
            if (formData?.status?.value === "WAITING") {
                if (selectors?.account?.account?.position !== "ADMIN" && selectors?.account?.account?.position !== "MANAGER" && selectors?.account?.account?.position !== "GENERAL_MANAGER") {
                    Object.keys(isDisabled).forEach((key) => {
                        isDisabled[key] = true
                    })
                }
            }
            if (formData?.status?.value === "APPROVED") {
                isDisabled['departmentInCharge'] = true
                isDisabled['personsInCharge'] = true

                if (selectors?.account?.account?.position !== "ADMIN" && selectors?.account?.account?.position !== "MANAGER" && selectors?.account?.account?.position !== "GENERAL_MANAGER") {
                    Object.keys(isDisabled).forEach((key) => {
                        isDisabled[key] = true
                    })
                }
            }
            if (formData?.status?.value === "TODO") {
                isDisabled['departmentInCharge'] = true
                isDisabled['personsInCharge'] = true

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    Object.keys(isDisabled).forEach((key) => {
                        isDisabled[key] = true
                    })
                }
            }
            if (formData?.status?.value === "PLANNING") {
                isDisabled['departmentInCharge'] = true
                isDisabled['personsInCharge'] = true

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    Object.keys(isDisabled).forEach((key) => {
                        isDisabled[key] = true
                    })
                }
            }
            if (formData?.status?.value === "DOING") {
                isDisabled['departmentInCharge'] = true
                isDisabled['personsInCharge'] = true
                isDisabled['planning'] = true

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    Object.keys(isDisabled).forEach((key) => {
                        isDisabled[key] = true
                    })
                }
            }
            if (formData?.status?.value === "RESOLVED") {
                isDisabled['departmentInCharge'] = true
                isDisabled['personsInCharge'] = true
                isDisabled['planning'] = true
                isDisabled['completionDate'] = true
                isDisabled['reason'] = true
                isDisabled['result'] = true
                isDisabled['resultImages'] = true
                isDisabled['comment'] = true
                isDisabled['buttons'] = true

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    Object.keys(isDisabled).forEach((key) => {
                        isDisabled[key] = true
                    })
                }
            }
            if (formData?.status?.value === "REJECTED") {
                isDisabled['departmentInCharge'] = true
                isDisabled['personsInCharge'] = true
                isDisabled['planning'] = true
                isDisabled['completionDate'] = true
                isDisabled['reason'] = true
                isDisabled['result'] = true
                isDisabled['resultImages'] = true
                isDisabled['comment'] = true
                isDisabled['buttons'] = true

                if (selectors?.account?.account?.position === "ADMIN" || selectors?.account?.account?.position === "MANAGER" || selectors?.account?.account?.position === "GENERAL_MANAGER") {
                } else {
                    Object.keys(isDisabled).forEach((key) => {
                        isDisabled[key] = true
                    })
                }
            }
            if (formData?.status?.value === "DONE") {
                isDisabled['departmentInCharge'] = true
                isDisabled['personsInCharge'] = true
                isDisabled['planning'] = true
                isDisabled['completionDate'] = true
                isDisabled['reason'] = true
                isDisabled['result'] = true
                isDisabled['resultImages'] = true
                isDisabled['buttons'] = true
            }
        }

        if (fromScreen === "SCAN_QR") {
            if (formType == "CREATE") {
                const areaStatus = props?.area?.status || 'ACTIVE';
                isDisabled['projectCode'] = areaStatus === 'ALL' ? false : true;
                isDisabled['problemCode'] = values?.informerType === null || values?.informerType === '' ? true : false;
            }
        }

        if (formType == "VIEW_DETAILS") {
            Object.keys(isDisabled).forEach((key) => {
                isDisabled[key] = true
            })
        }

        // console.log('[isDisabled]', isDisabled);

        return isDisabled;
    }
    const getLanguageToRender = (screen) => {
        let language = {
            EN: {
                requestNo: "Request No.",
                priorityNo: "Priority No.",
                status: "Status",
                col: "In Process",
                informerTypeLabel: "Informer",
                informerType: "Informer Type",
                createdBy: "Created by",
                areaLabel: "Area",
                project: "Project",
                areaType: "Area Type",
                areaDescription: "Area Description",
                problemLabel: "Problem",
                problem: "Problem",
                problemDesc: "Problem Description",
                files: "Upload Problem Image",
                departmentInCharge: "Department in Charge",
                personsInCharge: "Persons in Charge",
                email: "Email",
                comment: "Comment",
                planning: "Planning",
                eventTitle: "Event Title",
                eventColor: "Event Color",
                startEventDate: "Start Date",
                endEventDate: "End Date",
                completionDate: "Completion Date",
                reason: "Reason",
                result: "Result",
                resultImages: "Upload Result Image",
                updatedBy: "Updated by",
                password: "Password",
                cancel: "Cancel",
                save: "Save",
                submit: "Submit",
                reject: "Reject",
                delete: "Delete",
            },
            TH: {
                requestNo: "Request No.",
                priorityNo: "Priority No.",
                status: "สถานะ",
                col: "อยู่ในขั้นตอน",
                informerTypeLabel: "รายละเอียดผู้แจ้ง",
                informerType: "แผนก/ทีม",
                createdBy: "ชื่อผู้แจ้ง",
                areaLabel: "กรุณาระบุพื้นที่",
                project: "โครงการ",
                areaType: "ประเภทพื้นที่",
                areaDescription: "รายละเอียดพื้นที่/บริเวณ",
                problemLabel: "กรุณาระบุปัญหา (Category)",
                problem: "ปัญหา",
                problemDesc: "อธิบายเพิ่มเติม",
                files: "แนบรูปปัญหา",
                departmentInCharge: "หน่วยงานรับผิดชอบ",
                personsInCharge: "ผู้รับผิดชอบ",
                email: "Email",
                comment: "Comment",
                planning: "การวางแผน",
                eventTitle: "ชื่องาน",
                eventColor: "สี",
                startEventDate: "วันที่เริ่ม",
                endEventDate: "วันที่สิ้นสุด",
                completionDate: "วันที่เสร็จสิ้น",
                reason: "เหตุผล",
                result: "ผลลัพธ์",
                resultImages: "แนบรูปผลการดำเนินการหลังเสร็จสิ้น",
                updatedBy: "อัพเดตโดย",
                password: "รหัสผ่าน",
                cancel: "ยกเลิก",
                save: "บันทึก",
                submit: "ส่งข้อมูล",
                reject: "ยกเลิกงาน",
                delete: "ลบออก",
            }
        }
        if (screen === "SCAN_QR") return language["TH"]
        if (screen === "REQUEST_ON_BOARD") return language["TH"]
        return language["EN"];
    }
    const getInput = (formData, values) => {
        let mapInput = _.extend({}, _.omit(values, []), {
            // requestId,
            status: formData?.status?.value,
            col: formData?.col?.value,
            // departmentInCharge,
            personsInCharge: JSON.stringify(values?.personsInCharge.filter((i) => { return i.email && i.email !== '' })),
            comment: JSON.stringify(values?.comment.filter((i) => { return i.message && i.message !== '' })),
            planning: JSON.stringify(values?.planning.filter((i) => { return i.eventTitle && i.eventTitle !== '' })),
            completionDate: values?.completionDate ? moment(values?.completionDate).format() : null,
            // reason,
            // result,
            resultImages: values?.isChangeResultImages ? values?.resultImages : null,
            password: values?.password,
        })
        return mapInput
    }
    const getLastApprover = (approvers) => {
        let items = approvers.filter((i) => { return !i.isCC && i.approvedAt }).sort((event1, event2) => event1['approvedAt'] > event2['approvedAt'] ? -1 : 1)
        return items?.[0] || null
    }
    const handle = (mapInput, action) => {
        let { validate, submit } = props;
        if (validate) {
            let { isValid, input } = validate(mapInput, action);
            // console.log(validate, isValid, input)
            if (isValid) {
                if (submit) submit(input, { email: selectors?.user?.authUser?.username, password: mapInput?.password }, action);
            } else {
                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        open: true,
                        vertical: 'bottom',
                        horizontal: 'center',
                        severity: 'error',
                        message: `กรุณาระบุข้อมูลให้ครบถ้วน`,
                    }
                });
            }
        } else {
            if (submit) submit(mapInput, { email: selectors?.user?.authUser?.username, password: mapInput?.password }, action);
        }
    }
    const resizeImage = (file) => {
        return new Promise((resolve, reject) => {
            console.log('[DEBUG] 1 file', file)
            try {
                if (file.type === 'image/heic') {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        // resolve(event.target.result)
                        const blob = new Blob([new Uint8Array(e.target.result)], { type: file.type });
                        console.log(1, blob)
                        heic2any({
                            blob,
                            toType: "image/jpeg",
                            quality: 0.3, // cuts the quality and size by half
                        }).then((newBlob) => {
                            console.log(2, newBlob)
                            const newfile = new File([newBlob], file.name.replace('.heic', '.jpeg'), { type: newBlob.type })
                            resolve(newfile)
                        }).catch((err) => {
                            console.log(err);
                            resolve(null)
                        })
                    };
                    reader.readAsArrayBuffer(file);
                } else if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                    resolve(file)
                } else {
                    resolve(null);
                }
            } catch (err) {
                console.log(err);
                resolve(null)
            }
        })

    }

    const renderForm = () => {
        let { area, project, fromScreen, validate, formType, formData, cancel, submit } = props;
        const areaStatus = area?.status || 'ACTIVE';
        return <Formik
            initialValues={formType === "CREATE" ? {
                requestNo: "",
                requestId: "",
                prioritcyNo: "",
                status: selectors?.request?.requestStatusList.find((i) => { return i.value === 'OPEN' }),
                col: selectors?.request?.requestStatusList.find((i) => { return i.value === 'OPEN' }),

                informerType: null,
                createdBy: selectors?.user?.authUser?.username || "",
                updatedBy: selectors?.user?.authUser?.username || "",
                password: "",

                projectCode: project ? project : null,
                areaType: null,
                areaDescription: "",

                problemCode: null,
                description: "",
                files: [],

                departmentInCharge: null,
                personsInCharge: [],
                comment: [],
                planning: [],

                completionDate: null,
                reason: "",
                result: "",
                resultImages: [],

                submit: null
            } : _.extend({}, formData, {
                // requestNo,
                // requestId,
                // priorityNo,
                status: selectors?.request?.requestStatusList.find((i) => { return i.value === formData?.status }),
                col: selectors?.request?.requestStatusList.find((i) => { return i.value === formData?.col }),
                informerType: selectors?.dept?.departments.find((i) => { return i.deptCode === formData?.informerType }),
                // createdBy,
                // updatedBy: selectors?.user?.authUser?.username,
                updatedBy: fromScreen == "SCAN_QR" ? formData?.updatedBy :
                    fromScreen == "REQUEST" ? (selectors?.user?.authUser?.username) :
                        fromScreen == "REQUEST_ON_BOARD" && formType == "VIEW" && formData?.status != "DONE" ? (selectors?.user?.authUser?.username) : formData?.updatedBy,

                projectCode: selectors?.project?.projects.find((i) => { return i.projectCode === formData?.projectCode }),
                areaType: selectors?.area?.areas.find((i) => { return i.areaCode === formData?.areaType }),
                // areaDescription,

                problemCode: selectors?.problem?.problems.find((i) => { return i.problemCode === formData?.problemCode }),
                // description,
                files: (formData?.files == "" || formData?.files == null) ? [] : formData?.files?.split(","),
                isChangeFile: false,
                oldFiles: (formData?.files == "" || formData?.files == null) ? [] : formData?.files?.split(","),

                departmentInCharge: formData?.departmentInCharge,
                personsInCharge: JSON.parse(formData?.personsInCharge),
                comment: JSON.parse(formData?.comment),
                planning: JSON.parse(formData?.planning)?.map((event) => {
                    return _.extend({}, event, {
                        startEventDate: event?.startEventDate ? moment(event?.startEventDate) : null,
                        endEventDate: event?.endEventDate ? moment(event?.endEventDate) : null,
                    })
                }),

                completionDate: formData?.completionDate ? moment(formData?.completionDate) : null,
                // reason,
                // result,
                resultImages: (formData?.resultImages == "" || formData?.resultImages == null) ? [] : formData?.resultImages?.split(","),
                isChangeResultImages: false,
                oldResultImages: (formData?.resultImages == "" || formData?.resultImages == null) ? [] : formData?.resultImages?.split(","),

            })}
            validationSchema={Yup.object().shape({
                status: Yup.object().required('กรุณาระบุสถานะ').nullable(),
                informerType: Yup.object().required('กรุณาเลือกแผนก/ทีม').nullable(),

                projectCode: Yup.object().required('กรุณาระบุโครงการ').nullable(),
                areaType: Yup.object().required('กรุณาเลือกประเภทของพื้นที่').nullable(),

                problemCode: Yup.object().required('กรุณาเลือกประเภทปัญหา').nullable(),
                files: formType === "CREATE" ? Yup.array().min(1, "กรุณาแนบรูปภาพ").required('กรุณาแนบรูปภาพ') : null,
            })}
            onSubmit={async (values, { resetForm }) => {
                let mapInput = _.extend({}, _.omit(values, []), {
                    status: values?.status?.value,
                    col: values?.col?.value,
                    createdBy: values?.createdBy ? values?.createdBy : values?.informerType?.deptName,
                    areaType: values?.areaType?.areaCode,
                    completionDate: values?.completionDate ? moment(values?.completionDate).format() : null,
                    personsInCharge: JSON.stringify(values?.personsInCharge.filter((i) => { return i.email && i.email !== '' })),
                    comment: JSON.stringify(values?.comment.filter((i) => { return i.message && i.message !== '' })),
                    planning: JSON.stringify(values?.planning.filter((i) => { return i.eventTitle && i.eventTitle !== '' })),
                    // file: values?.isChangeFile ? values?.file : null,
                    informerType: values?.informerType?.deptCode,
                    problemCode: values?.problemCode?.problemCode,
                    projectCode: values?.projectCode?.projectCode,
                    files: values?.isChangeFiles ? values?.files : null,
                    resultImages: values?.isChangeResultImages ? values?.resultImages : null,
                })
                console.log(values, mapInput)

                if (validate) {
                    let isValid = validate(values);
                    if (isValid) {
                        if (submit) submit(mapInput, { email: selectors?.user?.authUser?.username, password: values?.password });
                    } else {
                    }
                } else {
                    if (submit) submit(mapInput, { email: selectors?.user?.authUser?.username, password: values?.password }, areaStatus === 'ALL' ? () => {
                        resetForm();
                    } : null);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => {
                let isRender = getFieldsToRender(values);
                let isDisabled = getDisabledToRender(values);
                let language = getLanguageToRender(fromScreen);

                return <form noValidate onSubmit={handleSubmit} >
                    <Grid container spacing={gridSpacing}>


                        {isRender['requestNo'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-requestNo-form">{language['requestNo']}</InputLabel>
                                <OutlinedInput
                                    id="outlined-requestNo-form"
                                    type="text"
                                    name="requestNo"
                                    value={values.requestNo}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label={language['requestNo']}
                                    inputProps={{}}
                                    disabled={isDisabled['requestNo']}
                                />
                                {touched.requestNo && errors.requestNo && (
                                    <FormHelperText error id="standard-weight-helper-text-requestNo-login">
                                        {errors.requestNo}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['priorityNo'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="outlined-priorityNo-form">{language['priorityNo']}</InputLabel>
                                <OutlinedInput
                                    id="outlined-priorityNo-form"
                                    type="number"
                                    name="priorityNo"
                                    value={values.priorityNo}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label={language['priorityNo']}
                                    inputProps={{}}
                                    disabled={isDisabled['priorityNo']}
                                />

                                {touched.priorityNo && errors.priorityNo && (
                                    <FormHelperText error id="standard-weight-helper-text-priorityNo-login">
                                        {errors.priorityNo}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['col'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth className="autocomplete-cus">
                                <Autocomplete
                                    disablePortal
                                    id="outlined-col-form"
                                    options={selectors?.request?.requestStatusList}
                                    filterOptions={(options, params) => {
                                        let filtered = filter(options, params);
                                        filtered = filtered.filter((i) => { return i.isCol })
                                        return filtered;
                                    }}
                                    getOptionLabel={(option) => `${option.name}`}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <div>{`${option.name}`}</div>
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label={language['col']} />}
                                    value={values.col}
                                    onBlur={handleBlur}
                                    onChange={(event, newValue) => {
                                        setFieldValue('col', newValue);
                                    }}
                                    disabled={isDisabled['col']}
                                />

                                {touched.col && errors.col && (
                                    <FormHelperText error id="standard-weight-helper-text-col-login">
                                        {errors.col}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['status'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth className="autocomplete-cus">
                                <Autocomplete
                                    disablePortal
                                    id="outlined-status-form"
                                    options={selectors?.request?.requestStatusList}
                                    filterOptions={(options, params) => {
                                        let filtered = filter(options, params);
                                        return filtered;
                                    }}
                                    getOptionLabel={(option) => `${option.name}`}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <div>{`${option.name}`}</div>
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label={language['status']} />}
                                    value={values.status}
                                    onBlur={handleBlur}
                                    onChange={(event, newValue) => {
                                        setFieldValue('status', newValue);
                                    }}
                                    disabled={isDisabled['status']}
                                />

                                {touched.status && errors.status && (
                                    <FormHelperText error id="standard-weight-helper-text-status-login">
                                        {errors.status}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['requestNo'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Divider />
                        </Grid>}


                        {isRender['informerTypeLabel'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h4" >{language['informerTypeLabel']}</Typography>
                        </Grid>}
                        {isRender['informerType'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth className="autocomplete-cus">
                                <Autocomplete
                                    disablePortal
                                    id="outlined-informerType-form"
                                    options={selectors?.dept?.departments.sort((d1, d2) => d2.deptName > d1.deptName ? -1 : 1)}
                                    filterOptions={(options, params) => {
                                        let filtered = filter(options, params);
                                        filtered = filtered.filter((i) => {
                                            return i.deptType === "REQUEST"
                                        })
                                        return filtered;
                                    }}
                                    getOptionLabel={(option) => `${option.deptName}`}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <div>{`${option.deptName}`}</div>
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label={language['informerType']} />}
                                    value={values.informerType}
                                    onBlur={handleBlur}
                                    onChange={(event, newValue) => {
                                        console.log(newValue)
                                        setFieldValue('informerType', newValue);
                                        setFieldValue('problemCode', null);
                                    }}
                                    disabled={isDisabled['informerType']}
                                />

                                {touched.informerType && errors.informerType && (
                                    <FormHelperText error id="standard-weight-helper-text-informerType-login">
                                        {errors.informerType}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['createdBy'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-createdBy-form">{language['createdBy']}</InputLabel>
                                <OutlinedInput
                                    id="outlined-createdBy-form"
                                    type="text"
                                    name="createdBy"
                                    value={values.createdBy}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        let newValue = e?.target?.value
                                        setFieldValue("createdBy", newValue)
                                    }}
                                    label={language['createdBy']}
                                    inputProps={{}}
                                    disabled={isDisabled['createdBy']}
                                />
                                {touched.createdBy && errors.createdBy && (
                                    <FormHelperText error id="standard-weight-helper-text-createdBy-login">
                                        {errors.createdBy}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['informerTypeLabel'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Divider />
                        </Grid>}


                        {isRender['areaLabel'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h4" >{language['areaLabel']}</Typography>
                        </Grid>}
                        {isRender['projectCode'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            {<FormControl fullWidth className="autocomplete-cus">
                                <Autocomplete
                                    disablePortal
                                    id="outlined-projectCode-form"
                                    options={selectors?.project?.projects?.sort((d1, d2) => d2.projectCode > d1.projectCode ? -1 : 1)}
                                    filterOptions={(options, params) => {
                                        let filtered = filter(options, params);
                                        return filtered;
                                    }}
                                    getOptionLabel={(option) => `${option.projectCode} - ${option.projectName}`}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <div>{`${option.projectCode} - ${option.projectName}`}</div>
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label={language['project']} />}
                                    value={values.projectCode}
                                    onBlur={handleBlur}
                                    onChange={(event, newValue) => {
                                        setFieldValue('projectCode', newValue);
                                    }}
                                    disabled={isDisabled['projectCode']}
                                />

                                {touched.projectCode && errors.projectCode && (
                                    <FormHelperText error id="standard-weight-helper-text-projectCode-login">
                                        {errors.projectCode}
                                    </FormHelperText>
                                )}
                            </FormControl>}
                        </Grid>}
                        {isRender['areaType'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth className="autocomplete-cus">
                                <Autocomplete
                                    disablePortal
                                    id="outlined-areaType-form"
                                    options={selectors?.area?.areas.sort((d1, d2) => d2.areaCode > d1.areaCode ? -1 : 1)}
                                    filterOptions={(options, params) => {
                                        let filtered = filter(options, params);
                                        filtered = filtered.filter((i) => {
                                            return i.floorCode === "REQUEST"
                                        })
                                        return filtered;
                                    }}
                                    getOptionLabel={(option) => `${option.areaName}`}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <div>{`${option.areaName}`}</div>
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label={language['areaType']} />}
                                    value={values.areaType}
                                    onBlur={handleBlur}
                                    onChange={(event, newValue) => {
                                        setFieldValue('areaType', newValue);
                                    }}
                                    disabled={isDisabled['areaType']}
                                />

                                {touched.areaType && errors.areaType && (
                                    <FormHelperText error id="standard-weight-helper-text-areaType-login">
                                        {errors.areaType}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['areaDescription'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-areaDescription-form">{language['areaDescription']}</InputLabel>
                                <OutlinedInput
                                    id="outlined-areaDescription-form"
                                    type="text"
                                    name="areaDescription"
                                    value={values.areaDescription}
                                    rows={5}
                                    multiline
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        let newValue = e?.target?.value
                                        setFieldValue("areaDescription", newValue)
                                    }}
                                    label={language['areaDescription']}
                                    inputProps={{}}
                                    disabled={isDisabled['areaDescription']}
                                />
                                {touched.areaDescription && errors.areaDescription && (
                                    <FormHelperText error id="standard-weight-helper-text-areaDescription-login">
                                        {errors.areaDescription}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['areaLabel'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Divider />
                        </Grid>}


                        {isRender['problemLabel'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h4" >{language['problemLabel']}</Typography>
                        </Grid>}
                        {isRender['problemCode'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth className="autocomplete-cus">
                                <Autocomplete
                                    disablePortal
                                    id="outlined-problemCode-form"
                                    options={selectors?.problem?.problems.sort((d1, d2) => d2.problemCode > d1.problemCode ? -1 : 1)}
                                    filterOptions={(options, params) => {
                                        let filtered = filter(options, params);
                                        filtered = filtered.filter((i) => {
                                            return i.problemType === "REQUEST"
                                        })
                                        if (values?.informerType?.deptCode) {
                                            filtered = filtered.filter((i) => {
                                                return i.problemType === "REQUEST" && i.deptCode === values?.informerType?.deptCode;
                                            })
                                        }
                                        return filtered;
                                    }}
                                    getOptionLabel={(option) => `${option.problemName}`}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <div>{`${option.problemName}`}</div>
                                        </li>
                                    )}
                                    renderInput={(params) => <TextField {...params} label={language['problem']} />}
                                    value={values.problemCode}
                                    onBlur={handleBlur}
                                    onChange={(event, newValue) => {
                                        setFieldValue('problemCode', newValue);
                                    }}
                                    disabled={isDisabled['problemCode']}
                                />

                                {touched.problemCode && errors.problemCode && (
                                    <FormHelperText error id="standard-weight-helper-text-problemCode-login">
                                        {errors.problemCode}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['description'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-description-form">{language['problemDesc']}</InputLabel>
                                <OutlinedInput
                                    id="outlined-description-form"
                                    type="text"
                                    name="description"
                                    value={values.description}
                                    rows={5}
                                    multiline
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label={language['problemDesc']}
                                    inputProps={{}}
                                    disabled={isDisabled['description']}
                                />
                                {touched.description && errors.description && (
                                    <FormHelperText error id="standard-weight-helper-text-description-login">
                                        {errors.description}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['files'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <label htmlFor="outlined-files-form">
                                    <Input accept="image/*" id="outlined-files-form"
                                        required
                                        multiple
                                        type="file"
                                        disabled={isDisabled['files']}
                                        onChange={async (e) => {
                                            // setFieldValue("file", e.currentTarget.files[0]);
                                            // if (formType === "UPDATE") setFieldValue("isChangeFile", true);
                                            let filesArr = Array.prototype.slice.call(e.target.files);
                                            let filesArrMap = await Promise.all(
                                                _.map(filesArr, async (file) => {
                                                    let newfile = await resizeImage(file);
                                                    return newfile
                                                })
                                            );
                                            setFieldValue("files", filesArrMap);
                                            // setFieldValue("files", filesArr);
                                            setFieldValue("isChangeFiles", true);
                                        }} />
                                    <Button variant="outlined"
                                        disabled={isDisabled['files']}
                                        component="span">
                                        {language['files']}
                                    </Button>
                                </label>

                                {touched.files && errors.files && (
                                    <FormHelperText error id="standard-weight-helper-text-files-login">
                                        {errors.files}
                                    </FormHelperText>
                                )}

                                {
                                    // change files
                                    formType == "CREATE" && values?.files && <>
                                        {values?.files?.map((file, index) => {
                                            let fileId = `file_${index}_${file.name}`
                                            return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '10px', width: 'fit-content', position: 'relative' }}>
                                                <img id={fileId} src={createObjectURL2(file, fileId)}
                                                    style={{ maxWidth: '200px', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                                <IconButton color="primary" aria-label="delte file" component="span"
                                                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                                                    onClick={() => {
                                                        let mapFiles = values.files
                                                        mapFiles.splice(index, 1);
                                                        setFieldValue("files", mapFiles);
                                                        if (formType === "UPDATE") {
                                                            setFieldValue("isChangeFiles", false)
                                                        }
                                                    }}>
                                                    <CancelPresentationOutlinedIcon />
                                                </IconButton>
                                            </div>
                                        })}
                                    </>
                                }

                                {
                                    (formType == "UPDATE" || formType == "VIEW") && values?.isChangeFiles && <>
                                        {values?.files?.map((file, index) => {
                                            let fileId = `file_${index}_${file.name}`
                                            return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '10px', width: 'fit-content', position: 'relative' }}>
                                                <img id={fileId} src={createObjectURL2(file, fileId)}
                                                    style={{ maxWidth: '200px', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                            </div>
                                        })}
                                    </>
                                }

                                {
                                    (formType == "UPDATE" || formType == "VIEW") && !values?.isChangeFiles && <>
                                        {values?.oldFiles?.map((file, index) => {
                                            let fileId = `file_${index}_${file}`
                                            if (!file) return
                                            return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '10px', width: 'fit-content', position: 'relative' }}>
                                                <img id={fileId} src={createObjectURL2(getDownloadUrl(file), fileId)}
                                                    style={{ maxWidth: '200px', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                            </div>
                                        })}
                                    </>
                                }

                            </FormControl>
                        </Grid>}
                        {isRender['problemLabel'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Divider />
                        </Grid>}

                        {isRender['departmentInCharge'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h4" >{language['departmentInCharge']}</Typography>
                        </Grid>}
                        {isRender['departmentInCharge'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth className="autocomplete-cus">
                                <RadioGroup
                                    row
                                    name="row-radio-buttons-departmentInCharge-group"
                                    value={values.departmentInCharge}
                                    onChange={(event, newValue) => {
                                        setFieldValue('departmentInCharge', newValue);
                                    }}
                                >

                                    {selectors?.dept?.departments.filter((i) => {
                                        return i.deptType === "REQUEST" && (i.deptCode !== "JURISTIC" && i.deptCode !== "OTHERS")
                                    }).sort((d1, d2) => d2.deptName > d1.deptName ? -1 : 1).map((option, index) => {
                                        return <FormControlLabel key={index} value={option.deptCode} control={<Radio />} label={`${option.deptName}`} />
                                    })}

                                </RadioGroup>

                                {touched.departmentInCharge && errors.departmentInCharge && (
                                    <FormHelperText error id="standard-weight-helper-text-departmentInCharge-login">
                                        {errors.departmentInCharge}
                                    </FormHelperText>
                                )}


                            </FormControl>
                        </Grid>}

                        {isRender['personsInCharge'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h4" >{language['personsInCharge']}</Typography>
                        </Grid>}
                        {values.personsInCharge.map((person, index) => {
                            return <React.Fragment key={index}>
                                {isRender['personsInCharge'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <Typography variant="subtitle1" >{`#${index + 1}`}</Typography>
                                </Grid>}
                                {isRender['personsInCharge'] && <Grid item lg={5} md={5} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <OutlinedInput size="small"
                                            id={`outlined-personsInCharge-form-${index}`}
                                            type="text"
                                            name={`personsInCharge-${index}`}
                                            value={values.personsInCharge[index]?.email}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                let oldPersonsInCharge = _.clone(values.personsInCharge);
                                                let item = _.extend({}, oldPersonsInCharge[index], { email: event.target.value })
                                                oldPersonsInCharge[index] = item
                                                setFieldValue('personsInCharge', oldPersonsInCharge);
                                            }}
                                            placeholder={language['email']}
                                            inputProps={{}}
                                            disabled={isDisabled['personsInCharge']}
                                        />
                                        {touched.personsInCharge && errors.personsInCharge && (
                                            <FormHelperText error id="standard-weight-helper-text-personsInCharge-login">
                                                {errors.personsInCharge}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>}
                                {isRender['personsInCharge'] && <Grid item lg={2} md={2} sm={2} xs={6} style={{ textAlign: 'center' }} >
                                    <FormControlLabel control={
                                        <Checkbox
                                            id={`personsInCharge-isCC-${index}`}
                                            name={`personsInCharge-isCC-${index}`}
                                            checked={values.personsInCharge[index]?.isCC}
                                            onChange={() => {
                                                let oldPersonsInCharge = _.clone(values.personsInCharge);
                                                let item = _.extend({}, oldPersonsInCharge[index], { isCC: !oldPersonsInCharge[index].isCC })
                                                oldPersonsInCharge[index] = item
                                                setFieldValue('personsInCharge', oldPersonsInCharge);
                                            }} />
                                    } label="CC" />
                                </Grid>}
                                {isRender['personsInCharge'] && <Grid item lg={3} md={3} sm={3} xs={6} style={{ paddingTop: '15px' }}>
                                    {!values.personsInCharge[index]?.isCC && <FormControlLabel control={<Checkbox color={'success'}
                                        id={`personsInCharge-isApproved-${index}`}
                                        name={`personsInCharge-isApproved-${index}`}
                                        checked={values.personsInCharge[index]?.isApproved}
                                        onChange={() => {
                                            let oldPersonsInCharge = _.clone(values.personsInCharge);
                                            let item = _.extend({}, oldPersonsInCharge[index], { isApproved: !oldPersonsInCharge[index].isApproved })
                                            oldPersonsInCharge[index] = item
                                            setFieldValue('personsInCharge', oldPersonsInCharge);
                                        }}

                                        icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} />}
                                        label={values.personsInCharge[index]?.isApproved === true ? "รับทราบ" : "ยังไม่รับทราบ"} />}
                                </Grid>}
                                {isRender['personsInCharge'] && <Grid item lg={2} md={2} sm={2} xs={12} style={{
                                    paddingTop: '15px', display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                }}>
                                    <Button variant="outlined" color="error"
                                        onClick={() => {
                                            let oldPersonsInCharge = _.clone(values.personsInCharge);
                                            oldPersonsInCharge.splice(index, 1)
                                            setFieldValue('personsInCharge', oldPersonsInCharge.map((i) => { return i }));
                                        }}>Delete</Button>
                                </Grid>}
                            </React.Fragment>
                        })}
                        {isRender['personsInCharge'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Button variant="outlined" style={{ float: 'right', width: '100%' }}
                                onClick={() => {
                                    let oldPersonsInCharge = _.clone(values.personsInCharge);
                                    oldPersonsInCharge.push({ email: '', isCC: false, isApproved: false })
                                    setFieldValue('personsInCharge', oldPersonsInCharge);
                                }}>Add Person</Button>
                        </Grid>}
                        {isRender['departmentInCharge'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Divider />
                        </Grid>}


                        {isRender['planning'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h4" >{language['planning']}</Typography>
                        </Grid>}
                        {values.planning.map((person, index) => {
                            return <React.Fragment key={index}>
                                {isRender['planning'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <Typography variant="subtitle1" >{`#${index + 1}`}</Typography>
                                </Grid>}
                                {isRender['planning'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <OutlinedInput size="small"
                                            id={`outlined-planning-form-${index}`}
                                            type="text"
                                            name={`planning-${index}`}
                                            value={values.planning[index]?.eventTitle}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                let oldPlanning = _.clone(values.planning);
                                                let item = _.extend({}, oldPlanning[index], { eventTitle: event.target.value })
                                                oldPlanning[index] = item
                                                setFieldValue('planning', oldPlanning);
                                            }}
                                            placeholder={language['eventTitle']}
                                            inputProps={{}}
                                            disabled={isDisabled['planning']}
                                        />
                                        {touched.planning && errors.planning && (
                                            <FormHelperText error id="standard-weight-helper-text-planning-login">
                                                {errors.planning}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>}
                                {isRender['planning'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <OutlinedInput size="small"
                                            id={`outlined-planning-color-form-${index}`}
                                            type="text"
                                            name={`planning-color-${index}`}
                                            value={values.planning[index]?.eventColor}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                let oldPlanning = _.clone(values.planning);
                                                let item = _.extend({}, oldPlanning[index], { eventColor: event.target.value })
                                                oldPlanning[index] = item
                                                setFieldValue('planning', oldPlanning);
                                            }}
                                            placeholder={language['eventColor']}
                                            inputProps={{}}
                                            disabled={isDisabled['planning']}
                                        />
                                    </FormControl>
                                </Grid>}
                                {isRender['planning'] && <Grid item lg={5} md={5} sm={12} xs={12} >
                                    <FormControl fullWidth >
                                        <MobileDatePicker
                                            id={`outlined-start-form-${index}`}
                                            inputFormat={"dd/MM/yyyy"}
                                            mask={"__/__/____"}
                                            renderInput={(props) => <TextField {...props} size="small" />}
                                            label={language['startEventDate']}
                                            value={values.planning[index]?.startEventDate}
                                            onChange={(newValue) => {
                                                let oldPlanning = _.clone(values.planning);
                                                let item = _.extend({}, oldPlanning[index], { startEventDate: newValue })
                                                oldPlanning[index] = item
                                                setFieldValue('planning', oldPlanning);
                                            }}
                                            disabled={isDisabled['planning']}
                                        />

                                    </FormControl>
                                </Grid>}
                                {isRender['planning'] && <Grid item lg={5} md={5} sm={12} xs={12} >
                                    <FormControl fullWidth >
                                        <MobileDatePicker
                                            id={`outlined-end-form-${index}`}
                                            inputFormat={"dd/MM/yyyy"}
                                            mask={"__/__/____"}
                                            renderInput={(props) => <TextField {...props} size="small" />}
                                            label={language['endEventDate']}
                                            value={values.planning[index]?.endEventDate}
                                            onChange={(newValue) => {
                                                let oldPlanning = _.clone(values.planning);
                                                let item = _.extend({}, oldPlanning[index], { endEventDate: newValue })
                                                oldPlanning[index] = item
                                                setFieldValue('planning', oldPlanning);
                                            }}
                                            disabled={isDisabled['planning']}
                                        />
                                    </FormControl>
                                </Grid>}
                                {isRender['planning'] && <Grid item lg={2} md={2} sm={12} xs={12} style={{
                                    paddingTop: '15px', display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                }}>
                                    <Button variant="outlined" color="error"
                                        onClick={() => {
                                            let oldPlanning = _.clone(values.planning);
                                            oldPlanning.splice(index, 1)
                                            setFieldValue('planning', oldPlanning.map((i) => { return i }));
                                        }}>Delete</Button>
                                </Grid>}
                            </React.Fragment>
                        })}
                        {isRender['planning'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Button variant="outlined" style={{ float: 'right', width: '100%' }}
                                onClick={() => {
                                    let oldPlanning = _.clone(values.planning);
                                    oldPlanning.push({ eventId: uuidv4(), eventTitle: '', eventColor: "#0ca7b5", startEventDate: moment(), endEventDate: moment() })
                                    setFieldValue('planning', oldPlanning);
                                }}>Add Event</Button>
                        </Grid>}
                        {isRender['planning'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Divider />
                        </Grid>}


                        {isRender['completionDate'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h4" >{language['completionDate']}</Typography>
                        </Grid>}
                        {isRender['completionDate'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <MobileDatePicker
                                    id="outlined-completionDate-form"
                                    inputFormat={"dd/MM/yyyy"}
                                    mask={"__/__/____"}
                                    renderInput={(props) => <TextField {...props} />}
                                    label={language['completionDate']}
                                    value={values.completionDate}
                                    onChange={(newValue) => {
                                        setFieldValue('completionDate', newValue);
                                    }}
                                    disabled={isDisabled['completionDate']}
                                />
                                {touched.completionDate && errors.completionDate && (
                                    <FormHelperText error id="standard-weight-helper-text-completionDate-login">
                                        {errors.completionDate}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['completionDate'] && values?.completionDate && values?.planning && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px', textAlign: 'right' }}>
                            <Typography variant="subtitle2" >{getDiffTime(values?.planning?.at(-1)?.endEventDate, moment(values?.completionDate))}</Typography>
                        </Grid>}
                        {isRender['reason'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-reason-form">{language['reason']}</InputLabel>
                                <OutlinedInput
                                    id="outlined-reason-form"
                                    type="text"
                                    name="reason"
                                    value={values.reason}
                                    rows={5}
                                    multiline
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label={language['reason']}
                                    inputProps={{}}
                                    disabled={isDisabled['reason']}
                                />
                                {touched.reason && errors.reason && (
                                    <FormHelperText error id="standard-weight-helper-text-reason-login">
                                        {errors.reason}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['result'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-result-form">{language['result']}</InputLabel>
                                <OutlinedInput
                                    id="outlined-result-form"
                                    type="text"
                                    name="result"
                                    value={values.result}
                                    rows={5}
                                    multiline
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label={language['result']}
                                    inputProps={{}}
                                    disabled={isDisabled['result']}
                                />
                                {touched.result && errors.result && (
                                    <FormHelperText error id="standard-weight-helper-text-result-login">
                                        {errors.result}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['resultImages'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <label htmlFor="outlined-resultImages-form">
                                    <Input accept="image/*" id="outlined-resultImages-form"
                                        multiple
                                        type="file"
                                        disabled={isDisabled['resultImages']}
                                        onChange={async (e) => {
                                            // setFieldValue("resultImage", e.currentTarget.files[0]);
                                            // if (formType === "UPDATE") setFieldValue("isChangeResultImage", true);
                                            let filesArr = Array.prototype.slice.call(e.target.files);
                                            let filesArrMap = await Promise.all(
                                                _.map(filesArr, async (file) => {
                                                    let newfile = await resizeImage(file)
                                                    return newfile
                                                })
                                            );
                                            setFieldValue("resultImages", filesArrMap);
                                            // setFieldValue("resultImages", filesArr);
                                            setFieldValue("isChangeResultImages", true);
                                        }} />
                                    <Button variant="outlined"
                                        disabled={isDisabled['resultImages']}
                                        component="span">
                                        {language['resultImages']}
                                    </Button>
                                </label>

                                {touched.resultImages && errors.resultImages && (
                                    <FormHelperText error id="standard-weight-helper-text-resultImages-login">
                                        {errors.resultImages}
                                    </FormHelperText>
                                )}

                                {
                                    // change files
                                    formType == "CREATE" && values?.resultImages && <>
                                        {values?.resultImages?.map((file, index) => {
                                            let fileId = `file_${index}_${file.name}`
                                            return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '10px', width: 'fit-content', position: 'relative' }}>
                                                <img id={fileId} src={createObjectURL2(file, fileId)}
                                                    style={{ maxWidth: '200px', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                                <IconButton color="primary" aria-label="delte file" component="span"
                                                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                                                    onClick={() => {
                                                        let mapFiles = values.resultImages
                                                        mapFiles.splice(index, 1);
                                                        setFieldValue("resultImages", mapFiles);
                                                        if (formType === "UPDATE") {
                                                            setFieldValue("isChangeResultImages", false)
                                                        }
                                                    }}>
                                                    <CancelPresentationOutlinedIcon />
                                                </IconButton>
                                            </div>
                                        })}
                                    </>
                                }

                                {
                                    (formType == "UPDATE" || formType == "VIEW") && values?.isChangeResultImages && <>
                                        {values?.resultImages?.map((file, index) => {
                                            let fileId = `file_${index}_${file.name}`
                                            return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '10px', width: 'fit-content', position: 'relative' }}>
                                                <img id={fileId} src={createObjectURL2(file, fileId)}
                                                    style={{ maxWidth: '200px', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                            </div>
                                        })}
                                    </>
                                }

                                {
                                    (formType == "UPDATE" || formType == "VIEW") && !values?.isChangeResultImages && <>
                                        {values?.oldResultImages?.map((file, index) => {
                                            let fileId = `file_${index}_${file}`
                                            if (!file) return
                                            return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '10px', width: 'fit-content', position: 'relative' }}>
                                                <img id={fileId} src={createObjectURL2(getDownloadUrl(file), fileId)}
                                                    style={{ maxWidth: '200px', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                            </div>
                                        })}
                                    </>
                                }
                            </FormControl>
                        </Grid>}
                        {isRender['completionDate'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Divider />
                        </Grid>}


                        {isRender['comment'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Typography variant="h4" >{language['comment']}</Typography>
                        </Grid>}
                        {values.comment.map((cm, index) => {
                            return <React.Fragment key={index}>
                                {isRender['comment'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                                    <Typography variant="subtitle1" >{`#${index + 1}`}</Typography>
                                </Grid>}
                                {isRender['comment'] && <Grid item lg={10} md={10} sm={9} xs={9} style={{ paddingTop: '15px' }}>
                                    <FormControl fullWidth >
                                        <OutlinedInput size="small"
                                            id={`outlined-comment-form-${index}`}
                                            type="text"
                                            name={`comment-${index}`}
                                            rows={3}
                                            multiline
                                            value={values.comment[index]?.message}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                let oldComment = _.clone(values.comment);
                                                let item = _.extend({}, oldComment[index], { message: event.target.value })
                                                oldComment[index] = item
                                                setFieldValue('comment', oldComment);
                                            }}
                                            placeholder={language['comment']}
                                            inputProps={{}}
                                            disabled={isDisabled['comment']}
                                        />
                                        {touched.comment && errors.comment && (
                                            <FormHelperText error id="standard-weight-helper-text-comment-login">
                                                {errors.comment}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>}
                                {isRender['comment'] && <Grid item lg={2} md={2} sm={3} xs={3} style={{
                                    paddingTop: '15px', display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'start',
                                }}>
                                    <Button variant="outlined" color="error"
                                        onClick={() => {
                                            let oldComment = _.clone(values.comment);
                                            oldComment.splice(index, 1)
                                            setFieldValue('comment', oldComment.map((i) => { return i }));
                                        }}>Delete</Button>
                                </Grid>}
                            </React.Fragment>
                        })}
                        {isRender['comment'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Button variant="outlined" style={{ float: 'right', width: '100%' }}
                                onClick={() => {
                                    let oldComment = _.clone(values.comment);
                                    oldComment.push({ messageId: uuidv4(), message: '' })
                                    setFieldValue('comment', oldComment);
                                }}>Add Comment</Button>
                        </Grid>}
                        {isRender['comment'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Divider />
                        </Grid>}


                        {isRender['updatedBy'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-updatedBy-form">{language['updatedBy']}</InputLabel>
                                <OutlinedInput
                                    id="outlined-updatedBy-form"
                                    type="text"
                                    name="updatedBy"
                                    value={values.updatedBy}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        let newValue = e?.target?.value
                                        setFieldValue("updatedBy", newValue)
                                    }}
                                    label={language['updatedBy']}
                                    inputProps={{}}
                                    disabled={isDisabled['updatedBy']}
                                />
                                {touched.updatedBy && errors.updatedBy && (
                                    <FormHelperText error id="standard-weight-helper-text-updatedBy-login">
                                        {errors.updatedBy}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender["password"] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-password-form">{language["password"]}</InputLabel>
                                <OutlinedInput
                                    id="outlined-password-form"
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label={language["password"]}
                                    inputProps={{}}

                                />
                                {touched.password && errors.password && (
                                    <FormHelperText error id="standard-weight-helper-text-password-login">
                                        {errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>}
                        {isRender['updatedBy'] && <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                            <Divider />
                        </Grid>}



                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}


                        {<Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '15px', textAlign: 'right' }} >
                            <Box sx={{ mt: 2 }} style={{ textAlign: 'right' }}>
                                {isRender['cancel'] && <Button
                                    size="medium"
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginRight: '10px', marginLeft: '10px' }}
                                    onClick={cancel}
                                    disabled={isDisabled['cancel']}
                                >
                                    {language['cancel']}
                                </Button>}
                                {isRender['submit'] && <Button
                                    disabled={isSubmitting || isDisabled['submit']}
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {language['submit']}
                                </Button>}
                            </Box>
                        </Grid>}


                    </Grid>
                </form>
            }}
        </Formik>
    }
    const renderForm2 = () => {
        let { fromScreen, formType, formData, setOnTab } = props;

        let startPlans = getPlans(JSON.parse(formData?.planning), 'startEventDate');
        let endPlans = getPlans(JSON.parse(formData?.planning), 'endEventDate');

        let firstPlan = startPlans.length > 0 ? moment(startPlans[0].startEventDate).format('DD-MM-YYYY') : '-';
        let lastPlan = endPlans.length > 0 ? moment(endPlans[endPlans.length - 1].endEventDate).format('DD-MM-YYYY') : '-';

        // console.log(plans, firstPlan, lastPlan)
        console.log('renderForm2', formData)
        return <Formik
            initialValues={formType === "CREATE" ? {
                requestId: "",
                updatedBy: selectors?.user?.authUser?.username || "",
                password: "",
                status: selectors?.request?.requestStatusList.find((i) => { return i.value === 'OPEN' }),
                col: selectors?.request?.requestStatusList.find((i) => { return i.value === 'OPEN' }),
                departmentInCharge: null,
                personsInCharge: [],
                comment: [],
                planning: [],
                completionDate: null,
                reason: "",
                result: "",
                resultImages: [],
                submit: null,

            } : _.extend({}, formData, {
                requestId: formData?.requestId,
                updatedBy: selectors?.user?.authUser?.username || "",
                // updatedBy: fromScreen == "SCAN_QR" ? formData?.updatedBy :
                // fromScreen == "REQUEST" ? (selectors?.user?.authUser?.username) :
                //     fromScreen == "REQUEST_ON_BOARD" && formData?.status?.value != "DONE" ? (selectors?.user?.authUser?.username) : formData?.updatedBy,

                password: "",
                status: formData?.status?.value,
                col: formData?.col?.value,
                departmentInCharge: formData?.departmentInCharge && formData?.departmentInCharge !== "" ? formData?.departmentInCharge : null,
                personsInCharge: JSON.parse(formData?.personsInCharge),
                comment: JSON.parse(formData?.comment),
                planning: JSON.parse(formData?.planning)?.map((event) => {
                    return _.extend({}, event, {
                        startEventDate: event?.startEventDate ? moment(event?.startEventDate) : null,
                        endEventDate: event?.endEventDate ? moment(event?.endEventDate) : null,
                    })
                }),
                completionDate: formData?.completionDate && formData?.completionDate !== "" ? moment(formData?.completionDate) : null,
                reason: formData?.reason || "",
                result: formData?.result || "",
                resultImages: (formData?.resultImages == "" || formData?.resultImages == null) ? [] : formData?.resultImages?.split(","),
                isChangeResultImages: false,
                oldResultImages: (formData?.resultImages == "" || formData?.resultImages == null) ? [] : formData?.resultImages?.split(","),
                submit: null,
            })}
            re
            validationSchema={Yup.object().shape({
                //     status: Yup.object().required('กรุณาระบุสถานะ').nullable(),
                //     informerType: Yup.object().required('กรุณาเลือกแผนก/ทีม').nullable(),

                //     projectCode: Yup.object().required('กรุณาระบุโครงการ').nullable(),
                //     areaType: Yup.object().required('กรุณาเลือกประเภทของพื้นที่').nullable(),

                //     problemCode: Yup.object().required('กรุณาเลือกประเภทปัญหา').nullable(),
                //     resultImages: formType === "UPDATE"? Yup.array().min(1, "กรุณาแนบรูปภาพ").required('กรุณาแนบรูปภาพ'): null,
            })}
            onSubmit={async (values) => {

            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => {
                let isRender = getFieldsToRender(values);
                let isDisabled = getDisabledToRender(values);
                let language = getLanguageToRender(fromScreen);
                let lastPIC = getLastApprover(JSON.parse(formData?.personsInCharge))

                return <form noValidate onSubmit={handleSubmit} >

                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`สถานะ : `}</span>
                            <Status colors={formData?.status?.colors} name={formData?.status?.name} size={12} ></Status>
                        </div>}

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`Open aging : `}</span>
                            <span>{`${formData?.createdAt ? moment(formData?.createdAt).fromNow() : '-'}`}</span>
                        </div>}

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`Approved aging : `}</span>
                            <span>{`${lastPIC ? moment(lastPIC?.approvedAt).fromNow() : '-'}`}</span>
                        </div>}

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`โครงการ : `}</span>
                            <span>{`${formData?.project?.projectName}`}</span>
                        </div>}

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`พื้นที่ : `}</span>
                            <span>{`${formData?.area?.areaName}`}</span>
                        </div>}

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`รายละเอียดพื้นที่/บริเวณ : `}</span>
                            <span>{`${formData?.areaDescription ? `${formData?.areaDescription}` : `-`}`}</span>
                        </div>}

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`ผู้แจ้ง : `}</span>
                            <span>{`${formData?.informerDepartment?.deptName} ${formData?.createdBy ? `(${formData?.createdBy})` : ``}`}</span>
                        </div>}

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`ประเภทปัญหา : `}</span>
                            <span>{`${formData?.problem?.problemName}`}</span>
                        </div>}

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`รายละเอียด : `}</span>
                            <span>{`${formData?.description}`}</span>
                        </div>}

                        {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`รูปภาพปัญหา : `}</span>
                            {formData?.files?.split(',').map((file, index) => {
                                let fileId = `file_${index}_${file}`
                                return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '5px', width: 'fit-content', position: 'relative' }}>
                                    <img id={fileId} src={createObjectURL2(getDownloadUrl(file), fileId)}
                                        style={{ width: '100%', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                </div>
                            })}
                            {!formData?.files && '-'}
                        </div>}

                        {<div style={{ padding: '10px 0px' }}>
                            <Divider />
                        </div>}


                        {isRender['departmentInCharge'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`${language['departmentInCharge']} :`}</span>
                            <div>
                                {selectors?.dept?.departments.filter((i) => {
                                    return i.deptType === "REQUEST" && (i.deptCode !== "JURISTIC" && i.deptCode !== "OTHERS")
                                }).sort((d1, d2) => d2.deptName > d1.deptName ? -1 : 1).map((option, index) => {
                                    // return <FormControlLabel key={index} value={option.deptCode} control={<Radio />} label={`${option.deptName}`} />
                                    return <Chip key={index} size="small" label={option.deptName}
                                        color={values?.departmentInCharge === option.deptCode ? 'primary' : 'default'}
                                        style={{ fontSize: '12px', marginRight: '5px', marginTop: '5px', marginBottom: '5px' }}
                                        onClick={!isDisabled['departmentInCharge'] ? () => { setFieldValue('departmentInCharge', option.deptCode) } : null} />
                                })}
                            </div>
                        </div>}
                        {isRender['personsInCharge'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`${language['personsInCharge']} : `}</span>
                            {values.personsInCharge.map((person, index) => {
                                let persons = JSON.parse(formData?.personsInCharge);
                                return <React.Fragment key={index}>
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                        {isRender['personsInCharge'] && <div style={{ padding: '5px', paddingRight: '10px' }}>
                                            {`#${index + 1}`}
                                        </div>}
                                        {isRender['personsInCharge'] && <div style={{ paddingRight: '5px', flex: '1' }}>
                                            <FormControl fullWidth >
                                                <OutlinedInput size="small" className="xsmall"
                                                    id={`outlined-personsInCharge-form-${formData?.requestId}-${index}`}
                                                    type="text"
                                                    name={`personsInCharge-${index}`}
                                                    value={values.personsInCharge[index]?.email}
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        let oldPersonsInCharge = _.clone(values.personsInCharge);
                                                        let item = _.extend({}, oldPersonsInCharge[index], { email: event.target.value })
                                                        oldPersonsInCharge[index] = item
                                                        setFieldValue('personsInCharge', oldPersonsInCharge);
                                                    }}
                                                    placeholder={`${language['email']}`}
                                                    inputProps={{}}
                                                    disabled={isDisabled['personsInCharge']}
                                                />
                                                {touched.personsInCharge && errors.personsInCharge && (
                                                    <FormHelperText error id="standard-weight-helper-text-personsInCharge-login">
                                                        {errors.personsInCharge}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>


                                        </div>}
                                        {isRender['personsInCharge'] && !isDisabled['personsInCharge'] && <div style={{}}>
                                            <IconButton size="small" disabled={isDisabled['personsInCharge']}
                                                onClick={() => {
                                                    let oldPersonsInCharge = _.clone(values.personsInCharge);
                                                    oldPersonsInCharge.splice(index, 1)
                                                    setFieldValue('personsInCharge', oldPersonsInCharge.map((i) => { return i }));
                                                }}><DeleteForever style={{ fontSize: '16px' }} /></IconButton>
                                        </div>}
                                    </div>
                                    <div>
                                        {isRender['personsInCharge'] && <div style={{ display: 'inline-flex', paddingBottom: '0px', paddingRight: '10px', paddingLeft: '35px' }} >
                                            <FormControlLabel className="xsmall-checkbox" control={
                                                <Checkbox size="small" disabled={isDisabled['personsInCharge']}
                                                    id={`personsInCharge-isCC-${formData?.requestId}-${index}`}
                                                    name={`personsInCharge-isCC-${formData?.requestId}-${index}`}
                                                    checked={values.personsInCharge[index]?.isCC}
                                                    onChange={() => {
                                                        let oldPersonsInCharge = _.clone(values.personsInCharge);
                                                        let item = _.extend({}, oldPersonsInCharge[index], { isCC: !oldPersonsInCharge[index].isCC })
                                                        oldPersonsInCharge[index] = item
                                                        setFieldValue('personsInCharge', oldPersonsInCharge);
                                                    }} />
                                            } label="CC" />
                                        </div>}
                                        {isRender['personsInCharge'] && <div style={{ display: 'inline-flex', paddingTop: '0px' }}>
                                            {!values.personsInCharge[index]?.isCC && <FormControlLabel className="xsmall-checkbox" style={{ fontSize: '10px' }}
                                                control={
                                                    <Checkbox color={'success'}
                                                        style={{ fontSize: '10px' }}
                                                        id={`personsInCharge-isApproved-${formData?.requestId}-${index}`}
                                                        name={`personsInCharge-isApproved-${formData?.requestId}-${index}`}
                                                        // checked={values.personsInCharge[index]?.isApproved}
                                                        checked={persons[index]?.isApproved}
                                                        // onChange={!isDisabled['personsInCharge'] ? (event, newValue) => {
                                                        //     let oldPersonsInCharge = _.clone(values.personsInCharge);
                                                        //     let item = _.extend({}, oldPersonsInCharge[index], { isApproved: !oldPersonsInCharge[index].isApproved })
                                                        //     oldPersonsInCharge[index] = item
                                                        //     setFieldValue('personsInCharge', oldPersonsInCharge);
                                                        // } : null}
                                                        onChange={null}

                                                        icon={<CheckCircleOutlineIcon style={{ fontSize: '16px' }} />}
                                                        checkedIcon={<CheckCircleIcon style={{ fontSize: '16px' }} />} />
                                                }
                                                // label={values.personsInCharge[index]?.isApproved === true ? "รับทราบ" : "ยังไม่รับทราบ"} />}
                                                label={persons[index]?.isApproved === true ? "รับทราบ" : "ยังไม่รับทราบ"} />}
                                        </div>}
                                    </div>
                                </React.Fragment>
                            })}
                            {!isDisabled['personsInCharge'] && <div>
                                <Button size="small" variant="outlined" style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px' }}
                                    onClick={() => {
                                        let oldPersonsInCharge = _.clone(values.personsInCharge);
                                        oldPersonsInCharge.push({ email: '', isCC: false, isApproved: false })
                                        setFieldValue('personsInCharge', oldPersonsInCharge);
                                    }}>Add Person</Button>
                            </div>}
                        </div>}
                        {isRender['departmentInCharge'] && <div style={{ padding: '10px 0px', paddingTop: !isDisabled['personsInCharge'] ? '50px' : '10px' }}>
                            <Divider />
                        </div>}


                        {isRender['planning'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`${language['planning']} : `}</span>
                            {false && values.planning.map((person, index) => {
                                return <React.Fragment key={index}>
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                        {isRender['planning'] && <div style={{ padding: '5px', paddingRight: '10px' }}>
                                            {`#${index + 1}`}
                                        </div>}
                                        {isRender['planning'] && <div style={{ flex: 1 }}>
                                            <FormControl fullWidth >
                                                <OutlinedInput size="small" className="xsmall"
                                                    id={`outlined-planning-form-${formData?.requestId}-${index}`}
                                                    type="text"
                                                    name={`planning-${formData?.requestId}-${index}`}
                                                    value={values.planning[index]?.eventTitle}
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        let oldPlanning = _.clone(values.planning);
                                                        let item = _.extend({}, oldPlanning[index], { eventTitle: event.target.value })
                                                        oldPlanning[index] = item
                                                        setFieldValue('planning', oldPlanning);
                                                    }}
                                                    placeholder={`${language['eventTitle']}`}
                                                    inputProps={{}}
                                                    disabled={isDisabled['planning']}
                                                />
                                                {touched.planning && errors.planning && (
                                                    <FormHelperText error id="standard-weight-helper-text-planning-login">
                                                        {errors.planning}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>}
                                        {isRender['planning'] && !isDisabled['planning'] && <div style={{ paddingLeft: '5px' }}>
                                            <IconButton size="small" disabled={isDisabled['planning']}
                                                onClick={() => {
                                                    let oldPlanning = _.clone(values.planning);
                                                    oldPlanning.splice(index, 1)
                                                    setFieldValue('planning', oldPlanning.map((i) => { return i }));
                                                }}><DeleteForever style={{ fontSize: '16px' }} /></IconButton>
                                        </div>}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                        {isRender['planning'] && <div style={{ flex: 1 }}>
                                            <FormControl fullWidth >
                                                <OutlinedInput size="small" className="xsmall"
                                                    id={`outlined-planning-eventcolor-form-${formData?.requestId}-${index}`}
                                                    type="text"
                                                    name={`planning-eventcolor-${formData?.requestId}-${index}`}
                                                    value={values.planning[index]?.eventColor}
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        let oldPlanning = _.clone(values.planning);
                                                        let item = _.extend({}, oldPlanning[index], { eventColor: event.target.value })
                                                        oldPlanning[index] = item
                                                        setFieldValue('planning', oldPlanning);
                                                    }}
                                                    placeholder={`${language['eventColor']}`}
                                                    inputProps={{}}
                                                    disabled={isDisabled['planning']}
                                                />
                                            </FormControl>
                                        </div>}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                        {isRender['planning'] && <div style={{ flex: 1, display: 'flex', fontSize: '10px', alignItems: 'baseline' }} >
                                            <div style={{ flex: 1 }}>{language['startEventDate']}</div>
                                            <FormControl  >
                                                <MobileDatePicker size="small" className="xsmall"
                                                    id={`outlined-start-form-${formData?.requestId}-${index}`}
                                                    inputFormat={"dd/MM/yyyy"}
                                                    mask={"__/__/____"}
                                                    renderInput={(props) => <TextField {...props} size="small" className="xsmall" />}
                                                    placeholder={language['startEventDate']}
                                                    value={values.planning[index]?.startEventDate}
                                                    onChange={(newValue) => {
                                                        let oldPlanning = _.clone(values.planning);
                                                        let item = _.extend({}, oldPlanning[index], { startEventDate: newValue })
                                                        oldPlanning[index] = item
                                                        setFieldValue('planning', oldPlanning);
                                                    }}
                                                    disabled={isDisabled['planning']}
                                                />
                                            </FormControl>
                                        </div>}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                        {isRender['planning'] && <div style={{ flex: 1, display: 'flex', fontSize: '10px', alignItems: 'baseline' }}>
                                            <div style={{ flex: 1 }}>{language['endEventDate']}</div>
                                            <FormControl  >
                                                <MobileDatePicker size="small" className="xsmall"
                                                    id={`outlined-end-form-${formData?.requestId}-${index}`}
                                                    inputFormat={"dd/MM/yyyy"}
                                                    mask={"__/__/____"}
                                                    renderInput={(props) => <TextField {...props} size="small" className="xsmall" />}
                                                    placeholder={language['endEventDate']}
                                                    value={values.planning[index]?.endEventDate}
                                                    onChange={(newValue) => {
                                                        let oldPlanning = _.clone(values.planning);
                                                        let item = _.extend({}, oldPlanning[index], { endEventDate: newValue })
                                                        oldPlanning[index] = item
                                                        setFieldValue('planning', oldPlanning);
                                                    }}
                                                    disabled={isDisabled['planning']}
                                                />
                                            </FormControl>
                                        </div>}
                                    </div>
                                </React.Fragment>
                            })}
                            {false && !isDisabled['planning'] && <div>
                                <Button size="small" variant="outlined" style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px' }}
                                    onClick={() => {
                                        let oldPlanning = _.clone(values.planning);
                                        oldPlanning.push({ eventId: uuidv4(), eventTitle: '', eventColor: '#0ca7b5', startEventDate: moment(), endEventDate: moment() })
                                        setFieldValue('planning', oldPlanning);
                                    }}>Add Event</Button>
                            </div>}
                            {isRender['planning'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                                <span style={{ fontWeight: 'bold' }}>{`เริ่มงาน : `}</span>
                                <span>{`${firstPlan}`}</span>
                            </div>}
                            {isRender['planning'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                                <span style={{ fontWeight: 'bold' }}>{`สิ้นสุด : `}</span>
                                <span>{`${lastPlan}`}</span>
                            </div>}
                            {!isDisabled['planning'] && <div>
                                <Button size="small" variant="outlined" style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px' }}
                                    onClick={() => {
                                        if (setOnTab) setOnTab(1, formData);
                                    }}>Plan</Button>
                            </div>}
                        </div>}
                        {isRender['planning'] && <div style={{ padding: '10px 0px', paddingTop: !isDisabled['planning'] ? '50px' : '10px' }}>
                            <Divider />
                        </div>}


                        {isRender['completionDate'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`${language['completionDate']} : `}</span>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                <FormControl fullWidth>
                                    <MobileDatePicker size="small" className="xsmall"
                                        id="outlined-completionDate-form"
                                        inputFormat={"dd/MM/yyyy"}
                                        mask={"__/__/____"}
                                        renderInput={(props) => <TextField {...props} size="small" className="xsmall" />}
                                        placeholder={language['completionDate']}
                                        value={values.completionDate}
                                        onChange={(newValue) => {
                                            setFieldValue('completionDate', newValue);
                                        }}
                                        disabled={isDisabled['completionDate']}
                                    />
                                </FormControl>
                            </div>
                        </div>}
                        {isRender['completionDate'] && values?.completionDate && values?.planning && <div style={{ fontSize: '12px', padding: '5px 0px', textAlign: 'right' }}>
                            <Typography variant="subtitle2" >{getDiffTime(values?.planning?.at(-1)?.endEventDate, moment(values?.completionDate))}</Typography>
                        </div>}
                        {isRender['reason'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`${language['reason']} : `}</span>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                <FormControl fullWidth >
                                    <OutlinedInput size="small" className="xsmall"
                                        id="outlined-reason-form"
                                        type="text"
                                        name="reason"
                                        value={values.reason}
                                        rows={5}
                                        multiline
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={language['reason']}
                                        inputProps={{}}
                                        disabled={isDisabled['reason']}
                                    />
                                </FormControl>
                            </div>
                        </div>}
                        {isRender['result'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`${language['result']} : `}</span>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                <FormControl fullWidth >
                                    <OutlinedInput size="small" className="xsmall"
                                        id={`outlined-result-form`}
                                        type="text"
                                        name="result"
                                        value={values.result}
                                        rows={5}
                                        multiline
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={language['result']}
                                        inputProps={{}}
                                        disabled={isDisabled['result']}
                                    />
                                </FormControl>
                            </div>
                        </div>}
                        {isRender['resultImages'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`${language['resultImages']} : `}</span>
                            <div>
                                {!isDisabled['resultImages'] && <label htmlFor="outlined-resultImages-form">
                                    <Input accept="image/*" id="outlined-resultImages-form"
                                        multiple
                                        type="file"
                                        disabled={isDisabled['resultImages']}
                                        onChange={async (e) => {
                                            // setFieldValue("resultImage", e.currentTarget.files[0]);
                                            // if (formType === "UPDATE") setFieldValue("isChangeResultImage", true);
                                            let filesArr = Array.prototype.slice.call(e.target.files);
                                            let filesArrMap = await Promise.all(
                                                _.map(filesArr, async (file) => {
                                                    let newfile = await resizeImage(file)
                                                    return newfile
                                                })
                                            );
                                            setFieldValue("resultImages", filesArrMap);
                                            // setFieldValue("resultImages", filesArr);
                                            setFieldValue("isChangeResultImages", true);
                                        }} />
                                    <Button size="small" variant="outlined"
                                        disabled={isDisabled['resultImages']}
                                        style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px', marginBottom: '15px', zIndex: '100' }}
                                        component="span"
                                    >{language['resultImages']}</Button>
                                </label>}

                                {
                                    formType == "CREATE" && values?.resultImages && <>
                                        {values?.resultImages?.map((file, index) => {
                                            let fileId = `file_${index}_${file.name}`
                                            return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '5px', width: 'fit-content', position: 'relative' }}>
                                                <img id={fileId} src={createObjectURL2(file, fileId)}
                                                    style={{ width: '100%', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                                <IconButton color="primary" aria-label="delte file" component="span"
                                                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                                                    onClick={() => {
                                                        let mapFiles = values.resultImages
                                                        mapFiles.splice(index, 1);
                                                        setFieldValue("resultImages", mapFiles);
                                                        if (formType === "UPDATE") {
                                                            setFieldValue("isChangeResultImages", false)
                                                        }
                                                    }}>
                                                    <CancelPresentationOutlinedIcon />
                                                </IconButton>
                                            </div>
                                        })}
                                    </>
                                }
                                {
                                    (formType == "UPDATE" || formType == "VIEW") && values?.isChangeResultImages && <>
                                        {values?.resultImages?.map((file, index) => {
                                            let fileId = `file_${index}_${file.name}`
                                            return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '5px', width: 'fit-content', position: 'relative' }}>
                                                <img id={fileId} src={createObjectURL2(file, fileId)}
                                                    style={{ width: '100%', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                            </div>
                                        })}
                                    </>
                                }
                                {
                                    (formType == "UPDATE" || formType == "VIEW") && !values?.isChangeResultImages && <>
                                        {values?.oldResultImages?.map((file, index) => {
                                            let fileId = `file_${index}_${file}`
                                            if (!file) return
                                            return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '5px', width: 'fit-content', position: 'relative' }}>
                                                <img id={fileId} src={createObjectURL2(getDownloadUrl(file), fileId)}
                                                    style={{ width: '100%', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                            </div>
                                        })}
                                    </>
                                }

                            </div>
                        </div>}
                        {isRender['completionDate'] && <div style={{ padding: '10px 0px' }}>
                            <Divider />
                        </div>}


                        {isRender['comment'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                            <span style={{ fontWeight: 'bold' }}>{`${language['comment']} : `}</span>
                            {values.comment.map((cm, index) => {
                                return <React.Fragment key={index}>
                                    <div key={index} style={{ display: 'flex', alignItems: 'start', padding: '5px' }}>
                                        {isRender['comment'] && <div style={{ padding: '5px', paddingRight: '10px' }}>
                                            {`#${index + 1}`}
                                        </div>}
                                        {isRender['comment'] && <div style={{ flex: 1 }}>
                                            <FormControl fullWidth >
                                                <OutlinedInput size="small" className="xsmall"
                                                    id={`outlined-comment-form-${formData?.requestId}-${index}`}
                                                    type="text"
                                                    name={`comment-${formData?.requestId}-${index}`}
                                                    rows={3}
                                                    multiline
                                                    value={values.comment[index]?.message}
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        let oldComment = _.clone(values.comment);
                                                        let item = _.extend({}, oldComment[index], { message: event.target.value })
                                                        oldComment[index] = item
                                                        setFieldValue('comment', oldComment);
                                                    }}
                                                    placeholder={`${language['comment']}`}
                                                    inputProps={{}}
                                                    disabled={isDisabled['comment']}
                                                />
                                                {touched.comment && errors.comment && (
                                                    <FormHelperText error id="standard-weight-helper-text-comment-login">
                                                        {errors.comment}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </div>}
                                        {isRender['comment'] && !isDisabled['comment'] && <div style={{ paddingLeft: '5px' }}>
                                            <IconButton size="small" disabled={isDisabled['comment']}
                                                onClick={() => {
                                                    let oldComment = _.clone(values.comment);
                                                    oldComment.splice(index, 1)
                                                    setFieldValue('comment', oldComment.map((i) => { return i }));
                                                }}><DeleteForever style={{ fontSize: '16px' }} /></IconButton>
                                        </div>}
                                    </div>
                                </React.Fragment>
                            })}
                            {!isDisabled['comment'] && <div>
                                <Button size="small" variant="outlined" style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px' }}
                                    onClick={() => {
                                        let oldComment = _.clone(values.comment);
                                        oldComment.push({ messageId: uuidv4(), message: '' })
                                        setFieldValue('comment', oldComment);
                                    }}>Add Comment</Button>
                            </div>}
                        </div>}

                    </div>

                    {isRender['comment'] && <div style={{ padding: '10px 0px', paddingTop: !isDisabled['comment'] ? '10px' : '10px' }}>
                        <Divider />
                    </div>}



                    {isRender['password'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                        <span style={{ fontWeight: 'bold' }}>{`${language['password']} : `}</span>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                            <FormControl fullWidth >
                                <OutlinedInput size="small" className="xsmall"
                                    id="outlined-password-form"
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={language['password']}
                                    inputProps={{}}
                                    disabled={isDisabled['password']}
                                />
                            </FormControl>
                        </div>
                    </div>}
                    {isRender['password'] && <div style={{ padding: '10px 0px' }}>
                        <Divider />
                    </div>}


                    {isRender['buttons'] && <div style={{ fontSize: '12px', float: 'right', padding: '5px 0px' }}>
                        {isRender['delete'] && <Button style={{ marginLeft: '5px', fontSize: '12px' }}
                            disabled={isDisabled['delete']}
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => {
                                let mapInput = getInput(formData, values)
                                handle(mapInput, "DELETE")
                            }}
                        >
                            {language['delete']}
                        </Button>}

                        {isRender['reject'] && <Button style={{ marginLeft: '5px', fontSize: '12px' }}
                            disabled={isDisabled['reject']}
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => {
                                let mapInput = getInput(formData, values)
                                handle(mapInput, "REJECT");
                            }}
                        >
                            {language['reject']}
                        </Button>}

                        {isRender['save'] && <Button style={{ marginLeft: '5px', fontSize: '12px' }}
                            disabled={isDisabled['save']}
                            size="small"
                            // type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                let mapInput = getInput(formData, values)
                                handle(mapInput, "SAVE")
                            }}
                        >
                            {language['save']}
                        </Button>}

                        {isRender['submit'] && <Button style={{ marginLeft: '5px', fontSize: '12px' }}
                            disabled={isSubmitting || isDisabled['submit']}
                            size="small"
                            // type="submit"
                            variant="contained"
                            color="success"
                            onClick={() => {
                                let mapInput = getInput(formData, values)
                                handle(mapInput, "SUBMIT")
                            }}
                        >
                            {language['submit']}
                        </Button>}
                    </div>}

                </form>
            }}
        </Formik>
    }
    const renderFormOnBoard = () => {
        if (!formData) return null;
        
        let { setOnTab } = props;

        let startPlans = getPlans(JSON.parse(formData?.planning), 'startEventDate');
        let endPlans = getPlans(JSON.parse(formData?.planning), 'endEventDate');

        let firstPlan = startPlans.length > 0 ? moment(startPlans[0].startEventDate).format('DD-MM-YYYY') : '-';
        let lastPlan = endPlans.length > 0 ? moment(endPlans[endPlans.length - 1].endEventDate).format('DD-MM-YYYY') : '-';

        let isRender = getFieldsToRender(formik.values);
        let isDisabled = getDisabledToRender(formik.values);
        let language = getLanguageToRender(fromScreen);
        let lastPIC = getLastApprover(JSON.parse(formData?.personsInCharge))

        return <form noValidate onSubmit={formik.handleSubmit} >

            <div style={{ maxHeight: '300px', overflow: 'auto' }}>

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`สถานะ : `}</span>
                    <Status colors={formData?.status?.colors} name={formData?.status?.name} size={12} ></Status>
                </div>}

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`Open aging : `}</span>
                    <span>{`${formData?.createdAt ? moment(formData?.createdAt).fromNow() : '-'}`}</span>
                </div>}

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`Approved aging : `}</span>
                    <span>{`${lastPIC ? moment(lastPIC?.approvedAt).fromNow() : '-'}`}</span>
                </div>}

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`โครงการ : `}</span>
                    <span>{`${formData?.project?.projectName}`}</span>
                </div>}

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`พื้นที่ : `}</span>
                    <span>{`${formData?.area?.areaName}`}</span>
                </div>}

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`รายละเอียดพื้นที่/บริเวณ : `}</span>
                    <span>{`${formData?.areaDescription ? `${formData?.areaDescription}` : `-`}`}</span>
                </div>}

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`ผู้แจ้ง : `}</span>
                    <span>{`${formData?.informerDepartment?.deptName} ${formData?.createdBy ? `(${formData?.createdBy})` : ``}`}</span>
                </div>}

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`ประเภทปัญหา : `}</span>
                    <span>{`${formData?.problem?.problemName}`}</span>
                </div>}

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`รายละเอียด : `}</span>
                    <span>{`${formData?.description}`}</span>
                </div>}

                {<div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`รูปภาพปัญหา : `}</span>
                    {formData?.files?.split(',').map((file, index) => {
                        let fileId = `file_${index}_${file}`
                        return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '5px', width: 'fit-content', position: 'relative' }}>
                            <img id={fileId} src={createObjectURL2(getDownloadUrl(file), fileId)}
                                style={{ width: '100%', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                        </div>
                    })}
                    {!formData?.files && '-'}
                </div>}

                {<div style={{ padding: '10px 0px' }}>
                    <Divider />
                </div>}


                {isRender['departmentInCharge'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`${language['departmentInCharge']} :`}</span>
                    <div>
                        {selectors?.dept?.departments.filter((i) => {
                            return i.deptType === "REQUEST" && (i.deptCode !== "JURISTIC" && i.deptCode !== "OTHERS")
                        }).sort((d1, d2) => d2.deptName > d1.deptName ? -1 : 1).map((option, index) => {
                            // return <FormControlLabel key={index} value={option.deptCode} control={<Radio />} label={`${option.deptName}`} />
                            return <Chip key={index} size="small" label={option.deptName}
                                color={formik.values?.departmentInCharge === option.deptCode ? 'primary' : 'default'}
                                style={{ fontSize: '12px', marginRight: '5px', marginTop: '5px', marginBottom: '5px' }}
                                onClick={!isDisabled['departmentInCharge'] ? () => { formik.setFieldValue('departmentInCharge', option.deptCode) } : null} />
                        })}
                    </div>
                </div>}
                {isRender['personsInCharge'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`${language['personsInCharge']} : `}</span>
                    {formik.values?.personsInCharge.map((person, index) => {
                        let persons = JSON.parse(formData?.personsInCharge);
                        return <React.Fragment key={index}>
                            <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                {isRender['personsInCharge'] && <div style={{ padding: '5px', paddingRight: '10px' }}>
                                    {`#${index + 1}`}
                                </div>}
                                {isRender['personsInCharge'] && <div style={{ paddingRight: '5px', flex: '1' }}>
                                    <FormControl fullWidth >
                                        <OutlinedInput size="small" className="xsmall"
                                            id={`outlined-personsInCharge-form-${formData?.requestId}-${index}`}
                                            type="text"
                                            name={`personsInCharge-${index}`}
                                            value={formik.values.personsInCharge[index]?.email}
                                            onBlur={formik.handleBlur}
                                            onChange={(event) => {
                                                let oldPersonsInCharge = _.clone(formik.values.personsInCharge);
                                                let item = _.extend({}, oldPersonsInCharge[index], { email: event.target.value })
                                                oldPersonsInCharge[index] = item
                                                formik.setFieldValue('personsInCharge', oldPersonsInCharge);
                                            }}
                                            placeholder={`${language['email']}`}
                                            inputProps={{}}
                                            disabled={isDisabled['personsInCharge']}
                                        />
                                        {formik?.touched?.personsInCharge && formik?.errors?.personsInCharge && (
                                            <FormHelperText error id="standard-weight-helper-text-personsInCharge-login">
                                                {formik?.errors?.personsInCharge}
                                            </FormHelperText>
                                        )}
                                    </FormControl>


                                </div>}
                                {isRender['personsInCharge'] && !isDisabled['personsInCharge'] && <div style={{}}>
                                    <IconButton size="small" disabled={isDisabled['personsInCharge']}
                                        onClick={() => {
                                            let oldPersonsInCharge = _.clone(formik.values.personsInCharge);
                                            oldPersonsInCharge.splice(index, 1)
                                            formik.setFieldValue('personsInCharge', oldPersonsInCharge.map((i) => { return i }));
                                        }}><DeleteForever style={{ fontSize: '16px' }} /></IconButton>
                                </div>}
                            </div>
                            <div>
                                {isRender['personsInCharge'] && <div style={{ display: 'inline-flex', paddingBottom: '0px', paddingRight: '10px', paddingLeft: '35px' }} >
                                    <FormControlLabel className="xsmall-checkbox" control={
                                        <Checkbox size="small" disabled={isDisabled['personsInCharge']}
                                            id={`personsInCharge-isCC-${formData?.requestId}-${index}`}
                                            name={`personsInCharge-isCC-${formData?.requestId}-${index}`}
                                            checked={formik.values.personsInCharge[index]?.isCC}
                                            onChange={() => {
                                                let oldPersonsInCharge = _.clone(formik.values.personsInCharge);
                                                let item = _.extend({}, oldPersonsInCharge[index], { isCC: !oldPersonsInCharge[index].isCC })
                                                oldPersonsInCharge[index] = item
                                                formik.setFieldValue('personsInCharge', oldPersonsInCharge);
                                            }} />
                                    } label="CC" />
                                </div>}
                                {isRender['personsInCharge'] && <div style={{ display: 'inline-flex', paddingTop: '0px' }}>
                                    {!formik.values.personsInCharge[index]?.isCC && <FormControlLabel className="xsmall-checkbox" style={{ fontSize: '10px' }}
                                        control={
                                            <Checkbox color={'success'}
                                                style={{ fontSize: '10px' }}
                                                id={`personsInCharge-isApproved-${formData?.requestId}-${index}`}
                                                name={`personsInCharge-isApproved-${formData?.requestId}-${index}`}
                                                // checked={formik.values.personsInCharge[index]?.isApproved}
                                                checked={persons[index]?.isApproved}
                                                // onChange={!isDisabled['personsInCharge'] ? (event, newValue) => {
                                                //     let oldPersonsInCharge = _.clone(formik.values.personsInCharge);
                                                //     let item = _.extend({}, oldPersonsInCharge[index], { isApproved: !oldPersonsInCharge[index].isApproved })
                                                //     oldPersonsInCharge[index] = item
                                                //     formik.setFieldValue('personsInCharge', oldPersonsInCharge);
                                                // } : null}
                                                onChange={null}

                                                icon={<CheckCircleOutlineIcon style={{ fontSize: '16px' }} />}
                                                checkedIcon={<CheckCircleIcon style={{ fontSize: '16px' }} />} />
                                        }
                                        // label={formik.values.personsInCharge[index]?.isApproved === true ? "รับทราบ" : "ยังไม่รับทราบ"} />}
                                        label={persons[index]?.isApproved === true ? "รับทราบ" : "ยังไม่รับทราบ"} />}
                                </div>}
                            </div>
                        </React.Fragment>
                    })}
                    {!isDisabled['personsInCharge'] && <div>
                        <Button size="small" variant="outlined" style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px' }}
                            onClick={() => {
                                let oldPersonsInCharge = _.clone(formik.values.personsInCharge);
                                oldPersonsInCharge.push({ email: '', isCC: false, isApproved: false })
                                formik.setFieldValue('personsInCharge', oldPersonsInCharge);
                            }}>Add Person</Button>
                    </div>}
                </div>}
                {isRender['departmentInCharge'] && <div style={{ padding: '10px 0px', paddingTop: !isDisabled['personsInCharge'] ? '50px' : '10px' }}>
                    <Divider />
                </div>}


                {isRender['planning'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`${language['planning']} : `}</span>
                    {false && formik.values.planning.map((person, index) => {
                        return <React.Fragment key={index}>
                            <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                {isRender['planning'] && <div style={{ padding: '5px', paddingRight: '10px' }}>
                                    {`#${index + 1}`}
                                </div>}
                                {isRender['planning'] && <div style={{ flex: 1 }}>
                                    <FormControl fullWidth >
                                        <OutlinedInput size="small" className="xsmall"
                                            id={`outlined-planning-form-${formData?.requestId}-${index}`}
                                            type="text"
                                            name={`planning-${formData?.requestId}-${index}`}
                                            value={formik.values.planning[index]?.eventTitle}
                                            onBlur={formik.handleBlur}
                                            onChange={(event) => {
                                                let oldPlanning = _.clone(formik.values.planning);
                                                let item = _.extend({}, oldPlanning[index], { eventTitle: event.target.value })
                                                oldPlanning[index] = item
                                                formik.setFieldValue('planning', oldPlanning);
                                            }}
                                            placeholder={`${language['eventTitle']}`}
                                            inputProps={{}}
                                            disabled={isDisabled['planning']}
                                        />
                                        {formik?.touched?.planning && formik?.errors?.planning && (
                                            <FormHelperText error id="standard-weight-helper-text-planning-login">
                                                {formik?.errors?.planning}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>}
                                {isRender['planning'] && !isDisabled['planning'] && <div style={{ paddingLeft: '5px' }}>
                                    <IconButton size="small" disabled={isDisabled['planning']}
                                        onClick={() => {
                                            let oldPlanning = _.clone(formik.values.planning);
                                            oldPlanning.splice(index, 1)
                                            formik.setFieldValue('planning', oldPlanning.map((i) => { return i }));
                                        }}><DeleteForever style={{ fontSize: '16px' }} /></IconButton>
                                </div>}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                {isRender['planning'] && <div style={{ flex: 1 }}>
                                    <FormControl fullWidth >
                                        <OutlinedInput size="small" className="xsmall"
                                            id={`outlined-planning-eventcolor-form-${formData?.requestId}-${index}`}
                                            type="text"
                                            name={`planning-eventcolor-${formData?.requestId}-${index}`}
                                            value={formik.values.planning[index]?.eventColor}
                                            onBlur={formik.handleBlur}
                                            onChange={(event) => {
                                                let oldPlanning = _.clone(formik.values.planning);
                                                let item = _.extend({}, oldPlanning[index], { eventColor: event.target.value })
                                                oldPlanning[index] = item
                                                formik.setFieldValue('planning', oldPlanning);
                                            }}
                                            placeholder={`${language['eventColor']}`}
                                            inputProps={{}}
                                            disabled={isDisabled['planning']}
                                        />
                                    </FormControl>
                                </div>}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                {isRender['planning'] && <div style={{ flex: 1, display: 'flex', fontSize: '10px', alignItems: 'baseline' }} >
                                    <div style={{ flex: 1 }}>{language['startEventDate']}</div>
                                    <FormControl  >
                                        <MobileDatePicker size="small" className="xsmall"
                                            id={`outlined-start-form-${formData?.requestId}-${index}`}
                                            inputFormat={"dd/MM/yyyy"}
                                            mask={"__/__/____"}
                                            renderInput={(props) => <TextField {...props} size="small" className="xsmall" />}
                                            placeholder={language['startEventDate']}
                                            value={formik.values.planning[index]?.startEventDate}
                                            onChange={(newValue) => {
                                                let oldPlanning = _.clone(formik.values.planning);
                                                let item = _.extend({}, oldPlanning[index], { startEventDate: newValue })
                                                oldPlanning[index] = item
                                                formik.setFieldValue('planning', oldPlanning);
                                            }}
                                            disabled={isDisabled['planning']}
                                        />
                                    </FormControl>
                                </div>}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                                {isRender['planning'] && <div style={{ flex: 1, display: 'flex', fontSize: '10px', alignItems: 'baseline' }}>
                                    <div style={{ flex: 1 }}>{language['endEventDate']}</div>
                                    <FormControl  >
                                        <MobileDatePicker size="small" className="xsmall"
                                            id={`outlined-end-form-${formData?.requestId}-${index}`}
                                            inputFormat={"dd/MM/yyyy"}
                                            mask={"__/__/____"}
                                            renderInput={(props) => <TextField {...props} size="small" className="xsmall" />}
                                            placeholder={language['endEventDate']}
                                            value={formik.values.planning[index]?.endEventDate}
                                            onChange={(newValue) => {
                                                let oldPlanning = _.clone(formik.values.planning);
                                                let item = _.extend({}, oldPlanning[index], { endEventDate: newValue })
                                                oldPlanning[index] = item
                                                formik.setFieldValue('planning', oldPlanning);
                                            }}
                                            disabled={isDisabled['planning']}
                                        />
                                    </FormControl>
                                </div>}
                            </div>
                        </React.Fragment>
                    })}
                    {false && !isDisabled['planning'] && <div>
                        <Button size="small" variant="outlined" style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px' }}
                            onClick={() => {
                                let oldPlanning = _.clone(formik.values.planning);
                                oldPlanning.push({ eventId: uuidv4(), eventTitle: '', eventColor: '#0ca7b5', startEventDate: moment(), endEventDate: moment() })
                                formik.setFieldValue('planning', oldPlanning);
                            }}>Add Event</Button>
                    </div>}
                    {isRender['planning'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                        <span style={{ fontWeight: 'bold' }}>{`เริ่มงาน : `}</span>
                        <span>{`${firstPlan}`}</span>
                    </div>}
                    {isRender['planning'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                        <span style={{ fontWeight: 'bold' }}>{`สิ้นสุด : `}</span>
                        <span>{`${lastPlan}`}</span>
                    </div>}
                    {!isDisabled['planning'] && <div>
                        <Button size="small" variant="outlined" style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px' }}
                            onClick={() => {
                                if (setOnTab) setOnTab(1, formData);
                            }}>Plan</Button>
                    </div>}
                </div>}
                {isRender['planning'] && <div style={{ padding: '10px 0px', paddingTop: !isDisabled['planning'] ? '50px' : '10px' }}>
                    <Divider />
                </div>}


                {isRender['completionDate'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`${language['completionDate']} : `}</span>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                        <FormControl fullWidth>
                            <MobileDatePicker size="small" className="xsmall"
                                id="outlined-completionDate-form"
                                inputFormat={"dd/MM/yyyy"}
                                mask={"__/__/____"}
                                renderInput={(props) => <TextField {...props} size="small" className="xsmall" />}
                                placeholder={language['completionDate']}
                                value={formik.values.completionDate}
                                onChange={(newValue) => {
                                    formik.setFieldValue('completionDate', newValue);
                                }}
                                disabled={isDisabled['completionDate']}
                            />
                        </FormControl>
                    </div>
                </div>}
                {isRender['completionDate'] && formik.values?.completionDate && formik.values?.planning && <div style={{ fontSize: '12px', padding: '5px 0px', textAlign: 'right' }}>
                    <Typography variant="subtitle2" >{getDiffTime(formik.values?.planning?.at(-1)?.endEventDate, moment(formik.values?.completionDate))}</Typography>
                </div>}
                {isRender['reason'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`${language['reason']} : `}</span>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                        <FormControl fullWidth >
                            <OutlinedInput size="small" className="xsmall"
                                id="outlined-reason-form"
                                type="text"
                                name="reason"
                                value={formik.values.reason}
                                rows={5}
                                multiline
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={language['reason']}
                                inputProps={{}}
                                disabled={isDisabled['reason']}
                            />
                        </FormControl>
                    </div>
                </div>}
                {isRender['result'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`${language['result']} : `}</span>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                        <FormControl fullWidth >
                            <OutlinedInput size="small" className="xsmall"
                                id={`outlined-result-form`}
                                type="text"
                                name="result"
                                value={formik.values.result}
                                rows={5}
                                multiline
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={language['result']}
                                inputProps={{}}
                                disabled={isDisabled['result']}
                            />
                        </FormControl>
                    </div>
                </div>}
                {isRender['resultImages'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`${language['resultImages']} : `}</span>
                    <div>
                        {!isDisabled['resultImages'] && <label htmlFor="outlined-resultImages-form">
                            <Input accept="image/*" id="outlined-resultImages-form"
                                multiple
                                type="file"
                                disabled={isDisabled['resultImages']}
                                onChange={async (e) => {
                                    // formik.setFieldValue("resultImage", e.currentTarget.files[0]);
                                    // if (formType === "UPDATE") formik.setFieldValue("isChangeResultImage", true);
                                    let filesArr = Array.prototype.slice.call(e.target.files);
                                    let filesArrMap = await Promise.all(
                                        _.map(filesArr, async (file) => {
                                            let newfile = await resizeImage(file)
                                            return newfile
                                        })
                                    );
                                    formik.setFieldValue("resultImages", filesArrMap);
                                    // formik.setFieldValue("resultImages", filesArr);
                                    formik.setFieldValue("isChangeResultImages", true);
                                }} />
                            <Button size="small" variant="outlined"
                                disabled={isDisabled['resultImages']}
                                style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px', marginBottom: '15px', zIndex: '100' }}
                                component="span"
                            >{language['resultImages']}</Button>
                        </label>}

                        {
                            formType == "CREATE" && formik.values?.resultImages && <>
                                {formik.values?.resultImages?.map((file, index) => {
                                    let fileId = `file_${index}_${file.name}`
                                    return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '5px', width: 'fit-content', position: 'relative' }}>
                                        <img id={fileId} src={createObjectURL2(file, fileId)}
                                            style={{ width: '100%', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                        <IconButton color="primary" aria-label="delte file" component="span"
                                            style={{ position: 'absolute', top: '10px', right: '10px' }}
                                            onClick={() => {
                                                let mapFiles = formik.values.resultImages
                                                mapFiles.splice(index, 1);
                                                formik.setFieldValue("resultImages", mapFiles);
                                                if (formType === "UPDATE") {
                                                    formik.setFieldValue("isChangeResultImages", false)
                                                }
                                            }}>
                                            <CancelPresentationOutlinedIcon />
                                        </IconButton>
                                    </div>
                                })}
                            </>
                        }
                        {
                            (formType == "UPDATE" || formType == "VIEW") && formik.values?.isChangeResultImages && <>
                                {formik.values?.resultImages?.map((file, index) => {
                                    let fileId = `file_${index}_${file.name}`
                                    return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '5px', width: 'fit-content', position: 'relative' }}>
                                        <img id={fileId} src={createObjectURL2(file, fileId)}
                                            style={{ width: '100%', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                    </div>
                                })}
                            </>
                        }
                        {
                            (formType == "UPDATE" || formType == "VIEW") && !formik.values?.isChangeResultImages && <>
                                {formik.values?.oldResultImages?.map((file, index) => {
                                    let fileId = `file_${index}_${file}`
                                    if (!file) return
                                    return <div key={`${index}-${file.name}`} id={file.name} style={{ padding: '5px', width: 'fit-content', position: 'relative' }}>
                                        <img id={fileId} src={createObjectURL2(getDownloadUrl(file), fileId)}
                                            style={{ width: '100%', border: "1px solid #dfdfdf", borderRadius: '10px' }} />
                                    </div>
                                })}
                            </>
                        }

                    </div>
                </div>}
                {isRender['completionDate'] && <div style={{ padding: '10px 0px' }}>
                    <Divider />
                </div>}


                {isRender['comment'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                    <span style={{ fontWeight: 'bold' }}>{`${language['comment']} : `}</span>
                    {formik.values.comment.map((cm, index) => {
                        return <React.Fragment key={index}>
                            <div key={index} style={{ display: 'flex', alignItems: 'start', padding: '5px' }}>
                                {isRender['comment'] && <div style={{ padding: '5px', paddingRight: '10px' }}>
                                    {`#${index + 1}`}
                                </div>}
                                {isRender['comment'] && <div style={{ flex: 1 }}>
                                    <FormControl fullWidth >
                                        <OutlinedInput size="small" className="xsmall"
                                            id={`outlined-comment-form-${formData?.requestId}-${index}`}
                                            type="text"
                                            name={`comment-${formData?.requestId}-${index}`}
                                            rows={3}
                                            multiline
                                            value={formik.values.comment[index]?.message}
                                            onBlur={formik.handleBlur}
                                            onChange={(event) => {
                                                let oldComment = _.clone(formik.values.comment);
                                                let item = _.extend({}, oldComment[index], { message: event.target.value })
                                                oldComment[index] = item
                                                formik.setFieldValue('comment', oldComment);
                                            }}
                                            placeholder={`${language['comment']}`}
                                            inputProps={{}}
                                            disabled={isDisabled['comment']}
                                        />
                                        {formik?.touched?.comment && formik?.errors?.comment && (
                                            <FormHelperText error id="standard-weight-helper-text-comment-login">
                                                {formik.errors.comment}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>}
                                {isRender['comment'] && !isDisabled['comment'] && <div style={{ paddingLeft: '5px' }}>
                                    <IconButton size="small" disabled={isDisabled['comment']}
                                        onClick={() => {
                                            let oldComment = _.clone(formik.values.comment);
                                            oldComment.splice(index, 1)
                                            formik.setFieldValue('comment', oldComment.map((i) => { return i }));
                                        }}><DeleteForever style={{ fontSize: '16px' }} /></IconButton>
                                </div>}
                            </div>
                        </React.Fragment>
                    })}
                    {!isDisabled['comment'] && <div>
                        <Button size="small" variant="outlined" style={{ float: 'right', width: '100%', fontSize: '12px', margin: '5px 0px' }}
                            onClick={() => {
                                let oldComment = _.clone(formik.values.comment);
                                oldComment.push({ messageId: uuidv4(), message: '' })
                                formik.setFieldValue('comment', oldComment);
                            }}>Add Comment</Button>
                    </div>}
                </div>}

            </div>

            {isRender['comment'] && <div style={{ padding: '10px 0px', paddingTop: !isDisabled['comment'] ? '10px' : '10px' }}>
                <Divider />
            </div>}


            {isRender['password'] && <div style={{ fontSize: '12px', padding: '5px 0px' }}>
                <span style={{ fontWeight: 'bold' }}>{`${language['password']} : `}</span>
                <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                    <FormControl fullWidth >
                        <OutlinedInput size="small" className="xsmall"
                            id="outlined-password-form"
                            type="password"
                            name="password"
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={language['password']}
                            inputProps={{}}
                            disabled={isDisabled['password']}
                        />
                    </FormControl>
                </div>
            </div>}
            {isRender['password'] && <div style={{ padding: '10px 0px' }}>
                <Divider />
            </div>}


            {isRender['buttons'] && <div style={{ fontSize: '12px', float: 'right', padding: '5px 0px' }}>
                {isRender['delete'] && <Button style={{ marginLeft: '5px', fontSize: '12px' }}
                    disabled={isDisabled['delete']}
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                        let mapInput = getInput(formData, formik.values)
                        handle(mapInput, "DELETE")
                    }}
                >
                    {language['delete']}
                </Button>}

                {isRender['reject'] && <Button style={{ marginLeft: '5px', fontSize: '12px' }}
                    disabled={isDisabled['reject']}
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                        let mapInput = getInput(formData, formik.values)
                        handle(mapInput, "REJECT");
                    }}
                >
                    {language['reject']}
                </Button>}

                {isRender['save'] && <Button style={{ marginLeft: '5px', fontSize: '12px' }}
                    disabled={isDisabled['save']}
                    size="small"
                    // type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        let mapInput = getInput(formData, formik.values)
                        handle(mapInput, "SAVE")
                    }}
                >
                    {language['save']}
                </Button>}

                {isRender['submit'] && <Button style={{ marginLeft: '5px', fontSize: '12px' }}
                    disabled={formik.isSubmitting || isDisabled['submit']}
                    size="small"
                    // type="submit"
                    variant="contained"
                    color="success"
                    onClick={() => {
                        let mapInput = getInput(formData, formik.values)
                        handle(mapInput, "SUBMIT")
                    }}
                >
                    {language['submit']}
                </Button>}
            </div>}

        </form>
    }

    return props.fromScreen === "REQUEST_ON_BOARD" ? renderFormOnBoard() : renderForm();

}

export default RequestForm;

