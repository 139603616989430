import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { SET_SNACKBAR } from 'store/actions';
import _ from 'lodash';

// ==============================|| APP ||============================== //

const cache = createCache({
    key: 'css',
    prepend: true,
});

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const { message, open, vertical, horizontal, severity } = app?.snackbar;
    
    useEffect(() => {
        console.log('App')
    }, []);    


    const handleClose = () => {
        dispatch({
            type: SET_SNACKBAR,
            payload: _.extend({}, app?.snackbar, { open: false })
        });
    }

    return (
        <CacheProvider value={cache}>
            {/* <StyledEngineProvider injectFirst> */}
            <ThemeProvider theme={themes(customization)}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {/* <CssBaseline /> */}
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                    <Snackbar autoHideDuration={6000}
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        onClose={handleClose}
                        key={vertical + horizontal}
                    >
                        <MuiAlert elevation={6} variant="filled" onClose={() => { handleClose() }} severity={severity} sx={{ width: '100%' }} >
                            {message}
                        </MuiAlert>
                    </Snackbar>
                </LocalizationProvider>

            </ThemeProvider>
            {/* </StyledEngineProvider> */}
        </CacheProvider>
    );
};

export default App;
