// material-ui
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from '@mui/material/ButtonGroup';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import MainCard from 'ui-component/cards/MainCard';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

import { useTheme } from '@mui/material/styles';
import {
    Button,
    DialogActions,
    DialogContentText,
    IconButton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import _ from 'lodash';
import RequestForm from 'ui-component/request-form/RequestForm';
import * as reducers from 'store/reducers/requestReducer';
import { getDownloadUrl } from 'store/reducers/fileReducer';
import * as projectReducer from 'store/reducers/projectReducer';
import * as problemReducer from 'store/reducers/problemReducer';
import * as areaReducer from 'store/reducers/areaReducer';
import * as deptReducer from 'store/reducers/deptReducer';
import moment from 'moment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { SET_SNACKBAR } from 'store/actions';
import CachedIcon from '@mui/icons-material/Cached';
import { addDays, format, subMonths } from 'date-fns';
import { SearchOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
// ==============================|| SAMPLE PAGE ||============================== //

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const Input = styled('input')({
    display: 'none',
});

const Request = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectors = useSelector((state) => {
        return state
    });
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [deleteItems, setDeleteItems] = useState(null);
    const [lastCode, setLastCode] = useState("");
    const [formType, setFormType] = useState("CREATE");
    const [formData, setFormData] = useState(null);
    const [selected, setSelected] = useState([]);

    // filters
    const today = new Date();
    const [from, setFrom] = useState(subMonths(today, 1));
    const [to, setTo] = useState(today);

    const [items, setItems] = useState([]);
    const [item, setItem] = useState(null);

    const tableName = "Request";
    const primaryKey = "requestId";
    const fileKey = "files";
    const fieldsForUpdates = [
        "requestId",
        "priorityNo",
        "status",
        "col",
        "projectCode",
        "areaType",
        "areaDescription",

        "problemCode",
        "description",
        "files",

        "departmentInCharge",
        "personsInCharge",
        "comment",
        "planning",

        "completionDate",
        "reason",
        "result",
        "resultImages",

        // "createdBy",
        // "informerType",
        "updatedBy",
    ];

    useEffect(() => {
        console.log(`>>>>> ${tableName} Screen loaded`, selectors);
        dispatch(projectReducer.getItems({}));
        // dispatch(getFloors());
        dispatch(problemReducer.getItems({}));
        dispatch(areaReducer.getItems({}));
        // dispatch(getAccounts());
        dispatch(deptReducer.getItems({}));
        // dispatch(getUsers());
        refreshItems();
    }, []);

    const handleClickOpenForm = (type, item) => {
        if (type === "CREATE") {
            // dispatch(reducers.getLastItems((item, lastCode) => {
            // setLastCode(lastCode);
            setFormType("CREATE");
            setOpenForm(true);
            // }))
        }
        if (type === "UPDATE" || type === "VIEW_DETAILS") {
            setFormType(type);
            setFormData(item);
            setOpenForm(true);
        }
    };
    const handleClickCloseForm = () => {
        setOpenForm(false);
    };
    const submit = (item, authUser) => {
        if (formType === "CREATE") { createItem(item); }
        if (formType === "UPDATE") {
            // dispatch(authen(authUser, (res) => {
            updateItem(item);
            // }));
        }
    }
    const cancel = () => {
        handleClickCloseForm();
    }
    const refreshItems = () => {
        let input = {}
        if (from && to) {
            input = _.extend({}, input, {
                from: format(from, 'yyyy-MM-dd'),
                to: format(addDays(to, 1), 'yyyy-MM-dd'),
            });
        }
        dispatch(reducers.getItems(input, (res) => {
            setItems(res)
        }));
    }
    const createItem = async (item) => {
        let input = item
        console.log("createItem", input)

        if (item?.length) {
            let nsuccess = 0;
            let oldItems = _.clone(items);
            let newItems = _.clone(oldItems);

            for (let index = 0; index < item?.length; index++) {
                let input = item[index];
                await dispatch(reducers.createItem(input, (res) => {
                    nsuccess = index;
                    if (res) {
                        let item = res;
                        let isExist = oldItems?.find((i) => { return i[primaryKey] === item[primaryKey] });
                        if (isExist) return
                        newItems = [item, ...newItems]
                        setItems(newItems)
                    }
                }));

                if (nsuccess === item?.length - 1) {
                    handleClickCloseForm();
                }
            }

        } else {
            dispatch(reducers.createItem(input, (res, err) => {
                if (res) {
                    handleClickCloseForm();
                    let oldItems = _.clone(items);
                    let item = res;
                    let isExist = oldItems?.find((i) => { return i[primaryKey] === item[primaryKey] });
                    if (isExist) return
                    let newItems = _.clone(oldItems);
                    newItems.unshift(item);
                    setItems(newItems)
                }
            }))
        }
    }
    const updateItem = (item) => {
        if (!item?.isChangeFile) item = _.omit(item, ['file'])
        if (!item?.isChangeResultImage) item = _.omit(item, ['resultImage'])
        let input = _.pick(item, fieldsForUpdates)
        console.log("updateItem", input)
        dispatch(reducers.updateItem(input, (res, err) => {
            if (res) {
                handleClickCloseForm();
                let oldItems = _.clone(items);
                let item = res;
                let isExist = oldItems?.find((i) => { return i[primaryKey] === item[primaryKey] });
                if (!isExist) return
                let index = _.findIndex(oldItems, (i) => { return i[primaryKey] === item[primaryKey] });
                let newItems = _.clone(oldItems);
                newItems.splice(index, 1, item);
                setItems(newItems)
                setSelected([]);
            }
        }))
    }
    const deleteItem = (item) => {
        let input = _.extend({}, _.pick(item, [primaryKey]), { updatedBy: selectors?.user?.authUser?.username })
        console.log("deleteItem", input)
        dispatch(reducers.deleteItem(input, (res, err) => {
            if (res) {
                handleClickCloseForm();
                let oldItems = _.clone(items);
                let item = res;
                let isExist = oldItems?.find((i) => { return i[primaryKey] === item[primaryKey] });
                if (!isExist) return
                let index = _.findIndex(oldItems, (i) => { return i[primaryKey] === item[primaryKey] });
                let newItems = _.clone(oldItems);
                newItems.splice(index, 1);
                setItems(newItems)
                setSelected([]);
            }
        }))
    }
    const deletes = async (itemList) => {
        const input = {
            [primaryKey]: _.map(itemList, (i) => {
                return i[primaryKey]
            }),
            updatedBy: selectors?.user?.authUser?.username
        }
        // console.log(input, itemList);
        await dispatch(reducers.deleteItems(input, (res, err) => {
            if (res) {
                let oldItems = _.clone(items);
                for (let index = 0; index < res.length; index++) {
                    let item = res[index];
                    let isExist = _.findIndex(oldItems, (i) => { return i[primaryKey] === item[primaryKey] });
                    if (isExist > -1) {
                        oldItems.splice(isExist, 1);
                    }
                }
                setItems(oldItems);
                setSelected([]);
            }
        }))
    }


    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        { field: 'requestNo', headerName: 'Request No', width: 150 },
        { field: 'requestId', headerName: 'Request ID', width: 300, hide: true },
        { field: 'priorityNo', headerName: 'Priority No', width: 70, hide: true },
        { field: 'status', headerName: 'Status', width: 100 },
        { field: 'col', headerName: 'Column', width: 100, hide: true },

        { field: 'projectCode', headerName: 'Project Code', width: 150 },
        { field: 'areaType', headerName: 'Area Type', width: 150 },
        { field: 'areaDescription', headerName: 'Area Description', width: 150 },

        { field: 'problemCode', headerName: 'Problem Code', width: 150 },
        { field: 'description', headerName: 'Description', width: 250, hide: true },
        { field: 'files', headerName: 'Files', width: 250, hide: true },
        {
            field: 'fileLinks', headerName: 'File Link', width: 300, //hide: true,
            renderCell: (params) => {
                let fileLinks = params.row.fileLinks ? params.row.fileLinks.split('|') : []
                return fileLinks ? fileLinks.map((i, index) => {
                    return <a key={index} href={i} style={{ paddingRight: '10px', color: '#0fa6b5' }}>File#{index + 1}</a>
                }) : ''
            }
        },

        { field: 'departmentInCharge', headerName: 'Department in Charge', width: 150, hide: true },
        { field: 'personsInCharge', headerName: 'Persons in Charge', width: 150, hide: true },
        { field: 'planning', headerName: 'Planning', width: 150, hide: true },

        // {
        //     field: 'completionDate', headerName: 'Completion Date', width: 200, renderCell: (params) => {
        //         return moment(params.row.completionDate).format('DD/MM/YYYY hh:mm a')
        //     }
        // },
        { field: 'reason', headerName: 'Reason', width: 250, hide: true },
        { field: 'result', headerName: 'Result', width: 250, hide: true },
        { field: 'resultImages', headerName: 'Result Images', width: 250, hide: true },
        {
            field: 'resultImageLinks', headerName: 'Result Image Links', width: 300, //hide: true,
            renderCell: (params) => {
                let resultImageLinks = params.row.resultImageLinks ? params.row.resultImageLinks.split('|') : []
                return resultImageLinks ? resultImageLinks.map((i, index) => {
                    return <a key={index} href={i} style={{ paddingRight: '10px', color: '#0fa6b5' }}>File#{index + 1}</a>
                }) : ''
            }
        },

        { field: 'informerType', headerName: 'Informer Type', width: 150, hide: true },
        { field: 'comment', headerName: 'Comment', width: 150, hide: true },
        { field: 'createdBy', headerName: 'Created By', width: 150, hide: true },
        {
            field: 'createdAt', headerName: 'Created At', width: 200, hide: true,
            renderCell: (params) => {
                return moment(params.row.createdAt).format('DD/MM/YYYY hh:mm a')
            }
        },
        { field: 'updatedBy', headerName: 'Updated By', width: 150, hide: true },
        {
            field: 'updatedAt', headerName: 'Updated At', width: 200, hide: true,
            renderCell: (params) => {
                return moment(params.row.updatedAt).format('DD/MM/YYYY hh:mm a')
            }
        },
        {
            field: 'actions', headerName: 'Actions', pinnable: true,
            renderCell: (params) => {

                return <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        let type = "VIEW_DETAILS";
                        if ((selectors?.user?.authUser?.level && (selectors?.user?.authUser?.level === "ADMIN")) ||
                            (selectors?.account?.account?.position && selectors?.account?.account?.position === "MANAGER") ||
                            (selectors?.account?.account?.position && selectors?.account?.account?.position === "GENERAL_MANAGER")
                        ) { type = "UPDATE" }
                        handleClickOpenForm(type, params.row);
                    }}><EditIcon /></Button>
                    {selectors?.user?.authUser?.level && selectors?.user?.authUser?.level === "ADMIN" && <Button onClick={() => {
                        setDeleteItems(params.row)
                        setOpenDeleteAlert(true);
                    }}><DeleteForeverOutlinedIcon /></Button>}
                </ButtonGroup>
            }
        },
    ];
    const CustomToolbar = () => {
        let who = selectors?.account?.account;
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {who?.position && (who?.position == "ADMIN" || who?.position == "MANAGER" || who?.position == "GENERAL_MANAGER") && <GridToolbarExport csvOptions={{ fileName: `${tableName}_${moment().format('DD-MM-YYYY')}` }} />}
            </GridToolbarContainer>
        );
    }
    const renderTable = () => {
        let mapItems = _.map(items, (item) => {
            let fileLinks = item.files ? item.files.split(',')?.map((i) => {
                return getDownloadUrl(i)
            }) : []
            let resultImageLinks = item.resultImages ? item.resultImages.split(',')?.map((i) => {
                return getDownloadUrl(i)
            }) : []
            return _.extend({}, item, {
                fileLinks: fileLinks.join('|'),
                resultImageLinks: resultImageLinks.join('|'),
            })
        })
        return <DataGrid size="small"
            getRowId={(row) => row.requestId}
            rows={mapItems}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection={true}
            selectionModel={selected}
            onSelectionModelChange={(selectionModel, details) => {
                setSelected(selectionModel);
            }}
            components={{ Toolbar: CustomToolbar }}
        />
    }
    const renderForm = () => {
        return <BootstrapDialog
            onClose={handleClickCloseForm}
            aria-labelledby="customized-dialog-title"
            open={openForm}

        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickCloseForm}>
                <span style={{ fontSize: '15px' }}>{`${formType} by ${selectors?.user?.authUser?.username}`}</span>
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <RequestForm fromScreen={"REQUEST"}
                    // lastCode={lastCode}
                    formType={formType}
                    formData={formData}
                    cancel={handleClickCloseForm}
                    submit={(values, authUser) => {
                        submit(values?.length ? values : _.omit(values, ['submit']), authUser);
                    }}
                />
            </DialogContent>
        </BootstrapDialog>
    }
    const renderDeleteAlert = () => {
        return (
            <div>
                <Dialog
                    open={openDeleteAlert}
                    onClose={() => { setOpenDeleteAlert(false); }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/* <DialogTitle id="alert-dialog-title">
                        {"Delete this item ?"}
                    </DialogTitle> */}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'คุณต้องการลบข้อมูลใช่หรือไม่ ?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            if (deleteItems.length) {
                                deletes(deleteItems);
                            } else {
                                deleteItem(deleteItems);
                            }
                            setOpenDeleteAlert(false);
                            setDeleteItems(null);
                        }}>{'ใช่'}</Button>
                        <Button onClick={() => {
                            setOpenDeleteAlert(false);
                            setDeleteItems(null);
                        }}>{'ไม่ใช่'}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    return <MainCard
        classes={{
            cardHeader: { padding: '20px' },
            cardContent: { padding: '20px' }
        }}
        title={
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}
                sx={{
                    width: 'max-content',
                    '@media (min-width: 780px)': {
                        width: 'auto',
                    }
                }}>
                <Stack><Typography sx={{ fontSize: '20px' }}>{tableName}</Typography></Stack>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <DatePicker
                        id="from-filter"
                        inputFormat={"dd/MM/yyyy"}
                        mask={"__/__/____"}
                        renderInput={(props) => <TextField {...props} size="small" />}
                        placeholder={'Select start date'}
                        label={'From'}
                        value={from || null}
                        onChange={(newValue) => {
                            setFrom(newValue);
                        }}
                        componentsProps={{
                            actionBar: {
                                actions: ['clear'],
                            },
                        }}
                    />
                    <DatePicker
                        id="to-filter"
                        inputFormat={"dd/MM/yyyy"}
                        mask={"__/__/____"}
                        renderInput={(props) => <TextField {...props} size="small" />}
                        placeholder={'Select end date'}
                        label={'To'}
                        value={to || null}
                        onChange={(newValue) => {
                            setTo(newValue);
                        }}
                        componentsProps={{
                            actionBar: {
                                actions: ['clear'],
                            },
                        }}
                    />
                    <Fab size="small" color="primary" aria-label="add" style={{ color: 'white' }}
                        onClick={() => { refreshItems() }}>
                        <SearchOutlined />
                    </Fab>
                    {selectors?.user?.authUser?.level && selectors?.user?.authUser?.level === "ADMIN" &&
                        <Fab size="small" color="primary" aria-label="add" style={{ color: 'white' }}
                            onClick={() => { handleClickOpenForm("CREATE") }}>
                            <AddIcon />
                        </Fab>
                    }
                    {selectors?.user?.authUser?.level && selectors?.user?.authUser?.level === "ADMIN" &&
                        <Fab size="small" color="primary" aria-label="add" style={{ color: 'white' }}
                            onClick={() => {
                                if (selected?.length == 0) {
                                    dispatch({
                                        type: SET_SNACKBAR,
                                        payload: {
                                            open: true,
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                            severity: 'error',
                                            message: `กรุณาเลือกรายการที่ต้องการลบ`,
                                        }
                                    });
                                } else {
                                    let selecteds = _.map(selected, (requestId) => {
                                        return _.find(items, (i) => {
                                            return i.requestId === requestId;
                                        })
                                    });
                                    setDeleteItems(selecteds)
                                    setOpenDeleteAlert(true);
                                }
                            }}>
                            <DeleteForeverIcon />
                        </Fab>}
                    {<Fab size="small" color="primary" aria-label="add" style={{ color: 'white' }}
                        onClick={() => { refreshItems() }}>
                        <CachedIcon />
                    </Fab>}
                </Stack>
            </Stack>
        }
        style={{ height: '100%' }}>
        <div style={{ height: '65vh', width: '100%', position: 'relative' }}>
            {renderTable()}
            {renderForm()}
            {renderDeleteAlert()}
        </div>

    </MainCard>
};


export default Request;
